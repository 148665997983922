import {DestroyRef, inject, Pipe, PipeTransform} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {
    hoursToMilliseconds,
    minutesToMilliseconds, secondsToMilliseconds
} from 'date-fns';

@Pipe({name: 'past_date'})
export class PastDatePipe implements PipeTransform {
    private destroyRef: DestroyRef = inject(DestroyRef);
    private timer: any;

    private subject = new ReplaySubject<boolean>(1);

    public transform(date?: Date): ReplaySubject<boolean> {
        this.destroyRef.onDestroy(() => {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.subject.complete();
        });

        this.setPastDateTimer(date);

        return this.subject;

    }

    private setPastDateTimer(date?: Date): void {
        // Return true when date is in the past
        const now = Date.now();
        const pastDate = date ? date.getTime() < now : false;
        this.subject.next(pastDate);
        const nextTimerStep = this.getNextTimerStep(date, now);

        if (nextTimerStep) {
            this.timer = setTimeout(() => this.setPastDateTimer(date), nextTimerStep);
        }
    }

    private getNextTimerStep(date: Date, now: number): number {
        const diff = (date?.getTime() || 0) - now;
        if (diff < 0) return; // Already past now

        if (diff > hoursToMilliseconds(1) * 24) {
            return hoursToMilliseconds(1);
        } else if (diff > hoursToMilliseconds(1)) {
            return minutesToMilliseconds(1);
        } else {
            return secondsToMilliseconds(1);
        }
    }
}
