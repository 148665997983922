import {autoserialize, autoserializeAs} from 'cerialize';
import {LayoutNoteModel} from './layout-note.model';
import {AssetModel} from './asset.model';
import {CampaignItemModel} from './campaign-item.model';
import {AppConstants} from '../../app.constants';
import {ISpreadContentTypeBody} from '../interfaces/spread-content-type-body.interface';

export class ContentAreaModel {
    @autoserialize public _id: string;
    @autoserialize public startRow: number;
    @autoserialize public startColumn: number;
    @autoserialize public rowSpan: number;
    @autoserialize public columnSpan: number;

    constructor(_id: string, startRow: number, startColumn: number, rowSpan: number, columnSpan: number) {
        this._id = _id;
        this.startRow = startRow;
        this.startColumn = startColumn;
        this.rowSpan = rowSpan;
        this.columnSpan = columnSpan;
    }

    isEqual(contentArea: ContentAreaModel): boolean{
        return (this._id === contentArea._id &&
                this.startRow === contentArea.startRow &&
                this.startColumn === contentArea.startColumn &&
                this.rowSpan === contentArea.rowSpan &&
                this.columnSpan === contentArea.columnSpan);
    }
}

export class PublicationItemContentModel {
    @autoserialize public _id: string;
    @autoserialize public contentType: string;
    @autoserializeAs(ContentAreaModel) public area: ContentAreaModel;
    @autoserializeAs(CampaignItemModel) public campaignItem: CampaignItemModel;
    @autoserializeAs(LayoutNoteModel) public layoutNote: LayoutNoteModel;
    @autoserializeAs(AssetModel) public asset: AssetModel;

    public static OnSerialized(instance : PublicationItemContentModel, json : any) : void {
        // Patch call expects asset and campaign item ids
        if (json.asset) {
            json.asset = json.asset._id;
        }
        if (json.campaignItem) {
            json.campaignItem = json.campaignItem._id;
        }
        if (json.layoutNote) {
            json.layoutNote = json.layoutNote._id;
        }
    }

    constructor(contentModel: ISpreadContentTypeBody, area: ContentAreaModel) {
        if (contentModel instanceof CampaignItemModel) {
            this.contentType = AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM;
            this.campaignItem = contentModel;
        }
        if (contentModel instanceof AssetModel) {
            this.contentType = AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.ASSET;
            this.asset = contentModel;
        }
        if (contentModel instanceof LayoutNoteModel) {
            this.contentType = AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.LAYOUT_NOTE;
            this.layoutNote = contentModel;
        }
        this.area = area;
    }

    /**
     * Convenience method to get the correct body from the PublicationItemContentModel
     * @returns {ISpreadContentTypeBody}
     */
    public getContent(): ISpreadContentTypeBody {
        if (this.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM) {
            return this.campaignItem;
        }
        if (this.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.LAYOUT_NOTE) {
            return this.layoutNote;
        }
        if (this.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.ASSET) {
            return this.asset;
        }
    }
}
