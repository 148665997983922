<ng-container *ngIf="publicationItems?.length > 1">
    <nuc-tab-bar [tabs]="tabBarItems"
                 [disabledTabs]="disabledTabBarItems"
                 [(activeTab)]="selectedTab">
    </nuc-tab-bar>
    <hr class="full-width-tab-hr"/>
</ng-container>

<div class="detail-container" *ngIf="!loading && selectedPublicationItem; else loadingIndicator"
     [ngClass]="{'with-tab-bar': publicationItems?.length > 1}">
    <p class="title body-strong black">Publication item</p>
    <div class="detail">
        <p class="body-strong text-ellipsis">Identifier:</p>
        <p>{{selectedPublicationItem.publicationItemId}}</p>
    </div>

    <div class="detail">
        <p class="body-strong text-ellipsis">Template:</p>
        <p>{{selectedPublicationItem.template?.name | nullUndefinedFormatter}}</p>
    </div>

    <ng-container *ngIf="selectedPublicationItem.template?.templateType">
        <div class="detail">
            <p class="body-strong text-ellipsis">Template type:</p>
            <p>{{selectedPublicationItem.template.templateType.name}}</p>
        </div>
    </ng-container>

    <ng-container *ngIf="!isForm && selectedPublicationItem.getVariantLink(activeVariant?._id)?.url as url">
        <div class="detail">
            <p class="body-strong text-ellipsis">Delivery link:</p>
            <div class="icon">
                <p class="text-ellipsis">{{url}}</p>
                <nuc-icon-button icon="nucicon_duplicate"
                                 nucTooltipPosition="left"
                                 (click)="copyToClipboard()"
                                 nucTooltip="Copy"></nuc-icon-button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="selectedPublicationItem.tags?.length">
        <div class="detail">
            <p class="body-strong text-ellipsis">Tags</p>
            <div class="tags">
                <nuc-tag-card *ngFor="let tag of selectedPublicationItem.tags" [tag]="tag" [deletable]="false"
                              [hoverEffect]="false"></nuc-tag-card>
            </div>
        </div>
    </ng-container>

    <div class="detail detail-grid">
        <div>
            <p class="body-strong text-ellipsis">Date created</p>
            <p>{{selectedPublicationItem.createdAt | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
        </div>

        <div>
            <p class="body-strong text-ellipsis">Date modified</p>
            <p>{{selectedPublicationItem.updatedAt | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
        </div>
    </div>

    <nuc-button-bar *ngIf="!isForm && (componentActions?.uploadItemFilesAction || downloadAction)" class="detail">
        <nuc-button-secondary *ngIf="componentActions?.uploadItemFilesAction"
                              icon="nucicon_upload"
                              nucTooltip="Upload files"
                              nucTooltipPosition="left"
                              (click)="uploadPublicationItemFiles()">
        </nuc-button-secondary>

        <nuc-button-secondary *ngIf="downloadAction"
                              icon="nucicon_download"
                              nucTooltip="Download files"
                              (click)="downloadPublicationItemFiles()"
                              nucTooltipPosition="left"></nuc-button-secondary>
    </nuc-button-bar>

    <ng-container *ngIf="selectedFilesRevision?.files">
        <hr>
        <div class="content">
            <p class="title body-strong black">Publication item file</p>
            <div class="detail detail-grid"
                 *ngIf="selectedFilesRevision.files.source || selectedFilesRevision.files.export">
                <div>
                    <p class="body-strong text-ellipsis">Source file</p>
                    <div *ngIf="selectedFilesRevision.files.source">
                        <download-button
                            [type]="selectedPublicationItem.template | getDownloadIcon: DOWNLOAD_TYPE.SOURCE"
                            [matTooltip]="selectedFilesRevision.files | getFileName: DOWNLOAD_TYPE.SOURCE"
                            (click)="onDownloadFile(DOWNLOAD_TYPE.SOURCE)" matTooltipPosition="above"></download-button>
                    </div>
                </div>

                <div *ngIf="selectedFilesRevision.files.export">
                    <p class="body-strong text-ellipsis">Export file</p>
                    <div>
                        <download-button
                            [type]="selectedPublicationItem.template | getDownloadIcon: DOWNLOAD_TYPE.EXPORT"
                            [matTooltip]="selectedFilesRevision.files | getFileName: DOWNLOAD_TYPE.EXPORT"
                            (click)="onDownloadFile(DOWNLOAD_TYPE.EXPORT)" matTooltipPosition="above"></download-button>
                    </div>
                </div>
            </div>
            <div class="detail">
                <p class="body-strong text-ellipsis">Version</p>
                <p>{{selectedFilesRevision?.getTitle()}}</p>
            </div>
            <div class="detail">
                <p class="body-strong text-ellipsis">Date created</p>
                <p>{{selectedFilesRevision.files.sourceCreatedAt | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
            </div>
        </div>
    </ng-container>

    <div *ngIf="publication.deadline" class="deadline-data">
        <hr>
        <div class="content">
            <div class="deadline-header">
                <p class="title body-strong black">Publication item Deadline</p>
                <i *ngIf="selectedPublicationItem.deadline | past_date | async"
                   class="nucicon_clock_line overdue-indicator"></i>
            </div>
            <div>
                <p class="body-strong">Final Publication item deadline</p>
                <p>{{publication.deadline | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
            </div>
            <ng-container *ngIf="selectedPublicationItem.deadline">
                <div>
                    <p class="body-strong text-ellipsis">Publication item deadline in this step</p>
                    <p>{{selectedPublicationItem.deadline | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
                </div>
                <ng-container *ngIf="(selectedPublicationItem.deadline | past_date | async); else futureDeadline">
                    <div>
                        <p class="body-strong text-ellipsis">Overdue in current step</p>
                        <p>{{selectedPublicationItem.deadline | duration_words | async}}</p>
                    </div>
                </ng-container>
                <ng-template #futureDeadline>
                    <div>
                        <p class="body-strong text-ellipsis">Time left in current step</p>
                        <p>{{selectedPublicationItem.deadline | duration_words | async}}</p>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </div>

    <div *ngIf="selectedPublicationItem.attachments.length" class="item-files">
        <hr>
        <p class="title body-strong black">Publication item files</p>
        <div class="content grid-container">
            <div *ngFor="let attachment of selectedPublicationItem.attachments"
                 nucTooltip="{{attachment.fileName}} - {{attachment.size}}">
                <div>
                    <img *ngIf="attachment.files.thumbnail" class="image-card" src="{{attachment.files.thumbnail | getFilePathPipe:attachment.files.thumbnail}}">
                    <img *ngIf="!attachment.files.thumbnail" class="image-card" src="{{attachment.files.source | getFilePathPipe:attachment.files.source}}">
                </div>
                <p class="file-name">{{attachment.fileName}}</p>
            </div>
        </div>
    </div>

    <div *ngIf="linkedBriefingItem && showLinkedBriefingAction" class="briefing-data">
        <hr>
        <div class="content">
            <p class="title body-strong black">Linked briefing item</p>
            <div class="briefing-entry" *ngFor="let property of briefingInfo">
                <p class="body-strong">{{property.label}}</p>
                <p class="description">{{property.value | nullUndefinedFormatter}}</p>
            </div>
            <div class="button-bar" *ngIf="editContentBriefingItemAction">
                <nuc-button-secondary [icon]="editContentBriefingItemAction.icon || 'nucicon_edit'"
                                      [nucTooltip]="TOOLTIPS.EDIT"
                                      (click)="goToLinkedBriefingItem()"
                                      nucTooltipPosition="left"></nuc-button-secondary>
            </div>
        </div>
    </div>

    <div *ngIf="signOffConfigured" class="approved-by">
        <hr>

        <div class="detail">
            <p class="body-strong">Approved by</p>
        </div>

        <div class="detail" *ngIf="selectedPublicationItem.signOff?.signOffUsers?.length > 0; else noApprovals">
            <div class="icon" *ngFor="let signOffUser of selectedPublicationItem.signOff.signOffUsers">
                <i *ngIf="signOffUser.status === ESignOffStatus.APPROVED"
                   class="nucicon_check_round_fill icon_image"></i>
                <div class="content">
                    <p class="body-strong text-ellipsis">{{signOffUser.user?.fullName | nullUndefinedFormatter:DELETED_USER}}</p>
                    <p>{{signOffUser.date | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
                </div>
            </div>
        </div>

        <ng-template #noApprovals>
            <nuc-empty-state-component imageUrl="assets/images/empty_states/approvals.svg"
                                       title="No approvals"
                                       [small]="true">
            </nuc-empty-state-component>
        </ng-template>
    </div>
</div>

<ng-template #loadingIndicator>
    <rl-loading-indicator *ngIf="loading"></rl-loading-indicator>
</ng-template>
