import {autoserialize, autoserializeAs} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {DataFieldDataTypeModel} from './data-field-data-type.model';
import {VariantModel} from './variant.model';
import {EDataFieldTypes} from '../../app.enums';

export enum ERulesetPropertyType {
    ENTITY = 'entity',
    FIELD = 'field'
}

export type FieldPropertyType = RulePropertyModel | number | VariantModel;

export class RulePropertyModel implements IDropdownItem {
    @autoserialize public name: string;
    @autoserialize public key: string;
    @autoserialize public type: ERulesetPropertyType;
    @autoserialize public isArray: boolean;
    @autoserializeAs(RulePropertyModel) public properties: RulePropertyModel[];
    @autoserializeAs(DataFieldDataTypeModel) public dataType: DataFieldDataTypeModel;
    @autoserialize public enableVariants: boolean = false;

    public getTitle() {
        return this.name;
    }

    public getValue() {
        return this.key;
    }

    public get icon() {
        return this.enableVariants ? 'nucicon_variant' : '';
    }

    public getDataType() {
        return this.dataType?.type;
    }

    public static convertRuleProperties(properties: RulePropertyModel[], ignoreIndex = false, start: RulePropertyModel[] = [],
                                        previous: RulePropertyModel = null): RulePropertyModel[] {
        return properties.reduce((acc, property) => {
            const ruleSetProperty = new RulePropertyModel();
            ruleSetProperty.name = property.name;
            ruleSetProperty.key = property.key;
            const ruleProperties = property.properties;
            ruleSetProperty.type = property.type;
            ruleSetProperty.isArray = property.isArray;
            ruleSetProperty.dataType = property.dataType;
            if (typeof property.enableVariants === 'boolean') {
                ruleSetProperty.enableVariants = property.enableVariants;
            }

            if (previous) {
                ruleSetProperty.name = `${previous.name}.${ruleSetProperty.name}`;
                // For array entities, we only look at the first entry for conditions
                ruleSetProperty.key = `${previous.key}${previous.type === ERulesetPropertyType.ENTITY && previous.isArray &&
                !ignoreIndex ? '.0' : ''}.${ruleSetProperty.key}`;
            }
            if (ruleProperties?.length) {
                // When this entity has a dataType, it needs its own entry in the list
                if (ruleSetProperty.type === ERulesetPropertyType.ENTITY && ruleSetProperty.dataType?.type) {
                    acc.push(ruleSetProperty);
                }
                acc = RulePropertyModel.convertRuleProperties(ruleProperties, ignoreIndex, acc, ruleSetProperty);
            } else {
                acc.push(ruleSetProperty);
            }
            return acc;
        }, start);
    }

    public static fromRuleProperty(property: RulePropertyModel): RulePropertyModel {
        const newProperty = new RulePropertyModel();
        if (property.name) newProperty.name = property.name;
        if ('key' in property) newProperty.key = property.key;
        if (property.type) newProperty.type = property.type;
        newProperty.isArray = !!property.isArray;
        if (property.properties) newProperty.properties = Array.from(property.properties || []);
        if (property.dataType) newProperty.dataType = property.dataType;
        newProperty.enableVariants = !!property.enableVariants;

        return newProperty;
    }

    public static filterPropertiesOnDataType(dataType: EDataFieldTypes, ruleProperties: RulePropertyModel[]): RulePropertyModel[] {
        const filterDataTypes = [];
        if (dataType) filterDataTypes.push(dataType);

        if ([EDataFieldTypes.ENUM, EDataFieldTypes.LIST].includes(dataType)) {
            filterDataTypes.push(EDataFieldTypes.STRING);
        }

        const filter = (value: RulePropertyModel) => (!dataType || filterDataTypes.includes(value.getDataType()) &&
            value.type === ERulesetPropertyType.FIELD) || value.type === ERulesetPropertyType.ENTITY;

        return ruleProperties.reduce((properties, currentValue) => {
            if (filter(currentValue)) {
                // We need a copy, because we manipulate the properties array reference
                const property = RulePropertyModel.fromRuleProperty(currentValue);
                if (property.properties) {
                    property.properties = RulePropertyModel.filterPropertiesOnDataType(dataType, Array.from(property.properties));
                }

                if (!dataType || property.properties?.length || property.type === ERulesetPropertyType.FIELD) {
                    properties.push(property);
                }
            }

            return properties;
        }, []);
    }
}
