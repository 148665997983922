import {Component, Inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentActionModel,
    NUC_DIALOG_CUSTOM_CONTENT_DATA,
    NucDialogCustomContentService
} from '@relayter/rubber-duck';
import {LayoutNoteModel} from '../../../../../../../../../models/api/layout-note.model';
import Color from 'color';

export interface ILayoutNotePreviewData {
    layoutNote: LayoutNoteModel;
}

@Component({
    selector: 'layout-note-preview-component',
    templateUrl: './layout-note-preview.component.html',
    styleUrls: ['./layout-note-preview.component.scss']
})
export class LayoutNotePreviewComponent implements OnInit {
    public readonly layoutNote: LayoutNoteModel;
    public transformedColor: string;

    constructor(private dialogCustomContentService: NucDialogCustomContentService,
                @Inject(NUC_DIALOG_CUSTOM_CONTENT_DATA) private dialogData: ILayoutNotePreviewData) {
        this.layoutNote = this.dialogData.layoutNote;
    }

    public ngOnInit(): void {
        this.transformedColor = Color(this.layoutNote.color).alpha(0.1).toString();
        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Close');
        const cancel = new DialogCustomContentActionModel(cancelButton);
        cancel.observable.subscribe(() => this.dialogCustomContentService.close());
        this.dialogCustomContentService.setDialogActions([cancel]);
    }
}
