import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UserIsAllowedToPipe} from '../../../../../../../../pipes/user-is-allowed-to.pipe';
import {FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {PublicationItemModel} from '../../../../../../../../models/api/publication-item.model';
import {IEventData, UploadItemFilesFormComponent} from '../upload-item-files-form.component';

export interface IWorkflowUploadItemFilesModalData {
    publicationItemId: string;
    publicationItems: PublicationItemModel[];
}

@Component({
    selector: 'upload-item-files-modal-form-component',
    templateUrl: '../upload-item-files-form.component.html',
    styleUrls: ['../upload-item-files-form.component.scss']
})
export class UploadItemFilesModalFormComponent extends UploadItemFilesFormComponent implements OnInit {
    constructor(private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                @Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowUploadItemFilesModalData) {
        super();

        this.publicationItemId = this.modalData.publicationItemId;
        this.publicationItems = this.modalData.publicationItems;
    }

    protected initButtons(): void {
        super.initButtons();

        this.actions = [this.secondaryAction, this.confirmAction];
        this.fullModalService.setModalActions(this.actions);
    }

    protected handleUploadData(result?: IEventData, confirmClose?: boolean) {
        this.fullModalService.close(result, confirmClose);
    }
}
