<div class="header">
    <div class="header-grid">
        <div class="left"></div>
        <div class="center"><h1 class="title">{{title}}</h1></div>
        <div class="right">
            <nuc-button-secondary icon="nucicon_close" class="close-button" (click)="closeForm()">
            </nuc-button-secondary>
        </div>
    </div>
    <nuc-tab-bar *ngIf="tabBarItems?.length > 0"
                 [tabs]="tabBarItems"
                 [(activeTab)]="selectedTab">
    </nuc-tab-bar>
</div>
<div class="content">
    <form [formGroup]="form" *ngIf="!firstLoad && !isLoading; else loadingIndicator">
        <ng-container [ngSwitch]="selectedTab.index">
            <data-fields-information-form
                *ngSwitchCase="TAB_INFORMATION"
                [form]="form"
                [formConfig]="formConfig"
                [dataFields]="dataFields"
                [variants]="variants"
                [readonly]="readonly">
            </data-fields-information-form>
            <campaign-item-products-form
                *ngSwitchCase="TAB_PRODUCTS"
                [form]="form"
                [(selectedProducts)]="selectedProducts"
                [readonly]="readonly">
            </campaign-item-products-form>
            <campaign-item-assets-form
                *ngSwitchCase="TAB_ASSETS"
                [selectedAssets]="campaignItem?.assets"
                [selectedProducts]="selectedProducts"
                [form]="form"
                [readonly]="readonly">
            </campaign-item-assets-form>
            <campaign-item-changelog
                *ngSwitchCase="TAB_CHANGES"
                [campaignItem]="campaignItem"
                [showTimeLine]="true">
            </campaign-item-changelog>
        </ng-container>
    </form>
    <ng-template #loadingIndicator>
        <rl-loading-indicator></rl-loading-indicator>
    </ng-template>
</div>
<div class="footer">
    <div class="buttons">
        <ng-container *ngFor="let action of actions" [ngSwitch]="action.button.buttonType">
            <nuc-button-primary *ngSwitchCase="buttonTypes.PRIMARY"
                                [loading]="action.button.loading"
                                [icon]="action.button.icon"
                                [text]="action.button.text"
                                [disabled]="action.button.disabled"
                                [nucTooltip]="action.button.tooltip?.text"
                                [nucTooltipPosition]="action.button.tooltip?.position"
                                (click)="buttonClicked(action.observable)">
            </nuc-button-primary>
            <nuc-button-secondary *ngSwitchCase="buttonTypes.SECONDARY"
                                  [loading]="action.button.loading"
                                  [icon]="action.button.icon"
                                  [text]="action.button.text"
                                  [disabled]="action.button.disabled"
                                  [nucTooltip]="action.button.tooltip?.text"
                                  [nucTooltipPosition]="action.button.tooltip?.position"
                                  (click)="buttonClicked(action.observable)"></nuc-button-secondary>
            <nuc-button-destructive *ngSwitchCase="buttonTypes.DESTRUCTIVE"
                                    [loading]="action.button.loading"
                                    [icon]="action.button.icon"
                                    [text]="action.button.text"
                                    [disabled]="action.button.disabled"
                                    [nucTooltip]="action.button.tooltip?.text"
                                    [nucTooltipPosition]="action.button.tooltip?.position"
                                    (click)="buttonClicked(action.observable)"></nuc-button-destructive>
        </ng-container>
    </div>
</div>
