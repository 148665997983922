import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {
    DesignLibraryDetailModel,
    DesignLibraryModel,
    DesignLibraryPatchModel
} from '../../modules/static-content-rulesets/models/api/design-library.model';

import {QueryParams} from '../../classes/query-params';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {SortDirection} from '@angular/material/sort';
import {MonitoredJobsService} from './monitored-jobs.service';
import {ItemUsedModel} from '../../models/api/item-used.model';
import {EEngineType} from '../../models/api/template.model';

export enum ELibraryJobTypes {
    CREATE_INDESIGN_LIBRARY = 'CREATE_INDESIGN_LIBRARY',
    UPDATE_INDESIGN_LIBRARY_JOB = 'UPDATE_INDESIGN_LIBRARY_JOB',
    CREATE_SVG_LIBRARY = 'CREATE_SVG_LIBRARY',
    UPDATE_SVG_LIBRARY = 'UPDATE_SVG_LIBRARY',
}

export interface IUpdateLibraryJobData {
    libraryId: string;
    s3Key: string;
    name?: string;
    fonts?: string[];
}

export interface ICreateLibraryJobData {
    name: string;
    fonts?: string[];
    s3Key: string;
}

export type ILibraryJobData = ICreateLibraryJobData | IUpdateLibraryJobData;

@Injectable({
    providedIn: 'root'
})
export class DesignLibraryService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    public postJob(jobType: string, jobData: ILibraryJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_DESIGN_LIBRARIES,
            ApiConstants.API_METHOD_JOBS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }

    public getDesignLibraries(limit?: number,
                              offset?: number,
                              sortProperty?: string,
                              sortOrder?: SortDirection,
                              search?: string,
                              libraryIds?: string[],
                              engineTypes?: EEngineType[]): Observable<ARPagedResponseDataModel<DesignLibraryModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setSearchParams(search);

        if (libraryIds) {
            queryParams.addParam('designLibraryIds', libraryIds);
        }

        queryParams.addParam('engineTypes', engineTypes || [EEngineType.INDESIGN, EEngineType.SVG]);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_DESIGN_LIBRARIES], queryParams.getParams()
        );
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, DesignLibraryModel);
        });
    }

    public getDesignLibrary(designLibraryId: string): Observable<DesignLibraryDetailModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_DESIGN_LIBRARIES, designLibraryId]
        );
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, DesignLibraryDetailModel);
        });
    }

    public deleteDesignLibrary(designLibraryId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_DESIGN_LIBRARIES, designLibraryId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    public getDesignLibraryInUse(designLibraryId: string): Observable<ItemUsedModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_DESIGN_LIBRARIES, designLibraryId, ApiConstants.API_METHOD_USAGE]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, ItemUsedModel);
        });
    }

    public patchLibrary(_id: string, body: DesignLibraryPatchModel): Observable<DesignLibraryModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_DESIGN_LIBRARIES,
            _id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = body;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        });
    }
}
