import {ApiRequest} from './api.request';
import {IResponseModel} from '../../models/interfaces/response-model.interface';

export class ApiGetRequest extends ApiRequest {
    protected get urlComponents() {
        return [
            ...super.urlComponents,
        ];
    };

    constructor(public pathComponents: string[] = [],
                public model: IResponseModel) {
        super(pathComponents, model);
    }
}
