import {TemplateMarginsModel} from '../../../../../models/api/template-margins.model';

export class TemplateSizeModel {
    public readonly width: number;
    public readonly height: number;
    public readonly margins: TemplateMarginsModel;

    constructor(width: number, height: number, margins: TemplateMarginsModel) {
        this.width = width;
        this.height = height;
        this.margins = margins;
    }
}
