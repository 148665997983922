import {Component, computed, Input, OnChanges, signal, SimpleChanges} from '@angular/core';

@Component({
    selector: 'static-counter',
    templateUrl: './static-counter.component.html',
    styleUrl: './static-counter.component.scss'
})
export class StaticCounterComponent implements OnChanges {
    @Input() count = 0;

    public digits = signal(0);
    public extraWidth = computed(() => 6 * this.digits() - 12);
    public width = computed(() => 24 + this.extraWidth());
    public outerCircleWidth = computed(() => 22.29 + this.extraWidth());
    public outerPathLength = computed(() => 69.574851989746 + this.extraWidth() * 2);

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.count) {
            // set digits
            this.digits.set(Math.max(String(this.count).length, 2));
        }
    }
}
