import {Pipe, PipeTransform} from '@angular/core';
import {IPropertyField} from '../components/property-settings/property-settings.model';
import {NullUndefinedPipe} from './null-undefined.pipe';
import {DataFieldsComponentUtil} from '../classes/data-fields-component.util';
import {GetPropertyPipe} from '@relayter/rubber-duck';

@Pipe({name: 'propertySettingDisplay'})
export class PropertySettingDisplayPipe implements PipeTransform {
    private static privatePipe = new PropertySettingDisplayPipe();

    public transform(value: any, property: IPropertyField, variantKey?: string, defaultValue = '-'): string {
        let formatter = DataFieldsComponentUtil.getTypeFormatter(property.getDataType());
        // Check if property field is a data field
        if (property.getPropertyField().split('.')[0] === 'dataFields') {
            formatter = DataFieldsComponentUtil.getDataFieldFormatter(formatter, variantKey);
        }

        return NullUndefinedPipe.transform(GetPropertyPipe.transform(value, property.getPropertyField(), formatter), defaultValue);
    }

    public static transform(value: any, property: IPropertyField, variantKey?: string, defaultValue = '-'): string {
        return PropertySettingDisplayPipe.privatePipe.transform(value, property, variantKey, defaultValue);
    }

}
