import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {
    ConnectionPositionPair,
    OriginConnectionPosition,
    Overlay,
    OverlayConfig,
    OverlayConnectionPosition,
    OverlayRef, ScrollStrategyOptions
} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {ARLogger} from '@relayter/core';
import {filter, take} from 'rxjs/operators';
import {AppConstants} from '../../app.constants';

@Component({
    selector: 'overlay-button',
    templateUrl: './overlay-button.component.html',
    styleUrls: ['./overlay-button.component.scss']
})
export class OverlayButtonComponent implements OnInit {
    private originPos: OriginConnectionPosition = {
        originX: 'end',
        originY: 'top'
    };
    private overlayPos: OverlayConnectionPosition = {
        overlayX: 'start',
        overlayY: 'top'
    };

    @Input() public icon: string;
    @Input() public content: TemplateRef<any>;
    @Input() public popoutPosition = new ConnectionPositionPair(this.originPos, this.overlayPos, AppConstants.POPOUT_Y_OFFSET, 0);
    @Input() public showIndicator: boolean;

    @ViewChild('origin', {read: ElementRef}) public origin: ElementRef;
    @ViewChild('origin', {static: true, read: ViewContainerRef}) public container: ViewContainerRef;

    private overlayRef: OverlayRef;
    private templatePortal: TemplatePortal;

    constructor(private _overlay: Overlay,
                private scrollStrategyOptions: ScrollStrategyOptions) {}

    public ngOnInit(): void {
        this.templatePortal = new TemplatePortal(this.content, this.container);
    }

    public attachOverlay(): void {
        if (!this.overlayRef) {
            this.overlayRef = this._overlay.create(this.buildConfig());
        }

        if (!this.overlayRef.hasAttached()) {
            this.overlayRef.attach(this.templatePortal);
        } else {
            ARLogger.error('OverlyButtonComponent.attachOverlay(): Overlay already attached');
        }

        this.overlayRef.backdropClick()
            .pipe(take(1)).subscribe(() => this.detachOverlay());

        this.overlayRef.keydownEvents()
            .pipe(
                filter(keyBoardEvent => keyBoardEvent.key === 'Escape'),
                take(1)
            )
            .subscribe(() => this.detachOverlay());
    }

    private buildConfig(): OverlayConfig {
        return new OverlayConfig({
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this.origin)
                .withFlexibleDimensions(true)
                .withPositions([this.popoutPosition]),
            scrollStrategy: this.scrollStrategyOptions.reposition(),
            hasBackdrop: true,
            backdropClass: 'filters-backdrop',
            panelClass: 'filters-overlay-panel',
            maxHeight: '650px'
        });
    }

    public detachOverlay(): void {
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
    }
}
