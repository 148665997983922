import {Observable, Subject} from 'rxjs';
import {ISocketMessageBody} from './socket.service';

export class SocketRoom {
    public roomId: string;
    public obs: Observable<ISocketMessageBody>;
    public obsDestroy: Subject<void>;

    constructor(roomId: string) {
        this.roomId = roomId;
        this.obsDestroy = new Subject<void>();
    }

    public destroyRoom(): void {
        this.obsDestroy.next();
        this.obsDestroy.complete();
    }
}
