import {inject, Injectable} from '@angular/core';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {CampaignItemsApiService} from '../../api/services/campaign-items.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../classes/toaster.class';
import {finalize} from 'rxjs/operators';
import {RelayterTableDataProvider} from '../../classes/relayter-table-data-provider.service';
import {TableSortOptions} from '../../api/table-sort-options';

@Injectable()
export class BriefingDataProvider extends RelayterTableDataProvider<CampaignItemModel> {
    private campaignItemsApiService = inject(CampaignItemsApiService);
    phraseValue: string;
    public campaignId: string;
    public publicationId: string;
    public assignableItems: boolean;

    constructor(public sortOptions: TableSortOptions) {
        super(sortOptions);
    }

    public retrieveData(): void {
        this.loading = true;

        this.campaignItemsApiService.getCampaignItems(this.campaignId, this.publicationId,
            this.pageSize, 0, this.phraseValue, this.sortOptions, this.filterValues, this.apiCursor.getCursor(this.pageIndex),
            this.assignableItems)
            .pipe(
                finalize(() => this.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    this.data = result.items;
                    this.disableNextPage = !result.hasNext;
                    if (this.data.length > 0) {
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }
}
