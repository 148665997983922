import {autoserialize} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {StaticContentRulesetItemModel} from './static-content-ruleset-item.model';
import {StaticContentRulesetAssetItemModel} from './static-content-ruleset-asset-item.model';
import {StaticContentRulesetItemGroupModel} from './static-content-ruleset-item-group.model';
import {EEngineType} from '../../../../models/api/template.model';

export class StaticContentRulesetModel implements IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public libraries: string[] = [];
    @autoserialize public items: StaticContentRulesetItemModel[] = [];
    @autoserialize public assetItems: StaticContentRulesetAssetItemModel[] = [];
    @autoserialize public itemGroups: StaticContentRulesetItemGroupModel[] = [];
    @autoserialize public engineTypes: EEngineType[];
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class StaticContentRulesetPatchModel {
    @autoserialize public name: string;
    @autoserialize public libraries: string[];
    @autoserialize public engineTypes: EEngineType[];

    constructor(name?: string, libraries?: string[]) {
        this.name = name;
        this.libraries = libraries;
    }
}
