import {ESortOrder, ITableColumn, ITableSortOptions, EColumnDataType, ISortOptionEvent} from '@relayter/rubber-duck';
import {SortDirection} from '@angular/material/sort';

export class TableSortOptions implements ITableSortOptions {
    get hasDuplicates(): boolean {
        // Only check the first column: When the first column has no duplicates, all values are unique for this column
        return this.columns[0]?.sortDuplicates === true;
    }

    get dataTypes(): EColumnDataType[] {
        return this.columns.map(item => item.dataType);
    }

    get sortProperties(): string[] {
        return this.columns.map(item => item.sortProperty);
    }

    get sortPropertiesAsString(): string {
        return this.columns.map(item => item.sortProperty).join('|');
    }

    get hasColumns() {
        return this.columns.length > 0;
    }

    constructor(public columns: ITableColumn[] = [], public sortOrder: SortDirection = 'asc') {
    }

    public reset(columns: ITableColumn[] = []): void {
        this.columns = columns;
        this.sortOrder = 'asc';
    }

    public fromRoute(params: Record<string, any>, columns: ITableColumn[]): void {
        const sortProperties = params['sortProperty'] ? params['sortProperty'].split('|') : [];

        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ? params['sortOrder'] : 'asc';
        this.columns = columns.filter(column => sortProperties.includes(column.sortProperty));
    }

    public updateWithSortOptionEvent(sortEvent: ISortOptionEvent): void {
        if (sortEvent.column?.sortProperty) {
            this.columns = [sortEvent.column];
            this.sortOrder = sortEvent.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.reset();
        }
    }
}
