import {autoserializeAs} from 'cerialize';
import {StaticContentRulesetModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {WorkflowConfigurationModel} from './workflow-configuration.model';
import {PackageSetupModel} from './package-setup.model';
import {WorkflowAutomationModel} from './workflow-automation.model';
import {ConnectionModel} from './connection.model';

export class DataFieldUsageModel {
    @autoserializeAs(StaticContentRulesetModel) public ruleSets: StaticContentRulesetModel[];
    @autoserializeAs(WorkflowConfigurationModel) public workflows: WorkflowConfigurationModel[];
    @autoserializeAs(PackageSetupModel) public packageSetups: PackageSetupModel[];
    @autoserializeAs(WorkflowAutomationModel) public workflowAutomations: WorkflowAutomationModel[];
    @autoserializeAs(WorkflowAutomationModel) public connections: ConnectionModel[];
}
