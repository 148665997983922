import {Component, OnInit} from '@angular/core';
import {
    DesignLibraryDetailModel
} from '../../../../modules/static-content-rulesets/models/api/design-library.model';
import {DesignLibraryService} from '../../../../api/services/design-library.service';
import {ActivatedRoute} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {Subscription} from 'rxjs';

@Component({
    selector: 'rl-library-detail',
    templateUrl: 'library-detail.component.html',
    styleUrls: ['library-detail.component.scss']
})

export class LibraryDetailComponent implements OnInit {

    public librarySubscription: Subscription;
    public library: DesignLibraryDetailModel;

    constructor(private indesignLibraryService: DesignLibraryService,
                private route: ActivatedRoute) {}

    public ngOnInit(): void {
        const libraryId = this.route.snapshot.params['libraryId'];
        this.librarySubscription = this.indesignLibraryService.getDesignLibrary(libraryId).subscribe(
            (library) => this.library = library,
            (error) => Toaster.handleApiError(error));
    }
}
