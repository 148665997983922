import {Pipe, PipeTransform} from '@angular/core';
import {FileTypeUtil} from '../classes/file-type.util';
import {EAssetType} from '../models/api/asset.model';

@Pipe({
    name: 'assetFileType'
})
export class AssetFileTypePipe implements PipeTransform {

    private static privatePipe = new AssetFileTypePipe();

    public static format(value?: string): string {
        return AssetFileTypePipe.privatePipe.transform(value);
    }

    transform(assetExtension: string): string {
        const val = FileTypeUtil.getFileCategoryByType(assetExtension);
        switch (val) {
            case 'PDF':
            case 'IMAGE':
                return EAssetType.IMAGE;
            case 'VIDEO':
                return EAssetType.VIDEO;
            default:
                return EAssetType.OTHER;
        }
    }

}
