import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {PublicationModel} from '../../models/api/publication.model';
import {PublicationItemModel} from '../../models/api/publication-item.model';
import {LayoutNoteModel} from '../../models/api/layout-note.model';
import {ApiPostRequest} from '../requests/api-post.request';
import {ApiPatchRequest} from '../requests/api-patch.request';
import {ApiDeleteOneRequest} from '../requests/api-delete-one.request';

@Injectable({
    providedIn: 'root'
})
export class PublicationItemsApiService extends ApiService<PublicationModel> {
    constructor() {
        super([ApiConstants.API_GROUP_PUBLICATIONS], PublicationModel);
    }

    /**
     * Create new layout note in publication item
     *
     * @param {string} publicationId
     * @param {string} publicationItemId
     * @param {object} layoutNote
     */
    public postLayoutNote(publicationId: string, publicationItemId: string, layoutNote: LayoutNoteModel): Observable<LayoutNoteModel> {
        const request = new ApiPostRequest([
            ...this.groups,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId,
            ApiConstants.API_METHOD_LAYOUT_NOTES
        ], LayoutNoteModel, layoutNote)

        return this.apiRequestService.post(request);
    }

    /**
     * Update layout note in publication item
     *
     * @param {string} publicationId
     * @param {string} publicationItemId
     * @param {object} layoutNote
     * @returns {Observable<boolean>}
     */
    public updateLayoutNote(publicationId: string, publicationItemId: string, layoutNote: LayoutNoteModel): Observable<PublicationItemModel> {
        const request = new ApiPatchRequest([
            ...this.groups,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId,
            ApiConstants.API_METHOD_LAYOUT_NOTES
        ], PublicationItemModel, layoutNote._id, layoutNote)

        return this.apiRequestService.patch(request);
    }

    /**
     * Delete layout note in publication item
     *
     * @param {string} publicationId
     * @param {string} publicationItemId
     * @param {string} layoutNoteId
     * @returns {Observable<boolean>}
     */
    public deleteLayoutNote(publicationId: string, publicationItemId: string, layoutNoteId: string): Observable<boolean> {
        const request = new ApiDeleteOneRequest([
            ...this.groups,
            publicationId,
            ApiConstants.API_METHOD_ITEMS,
            publicationItemId,
            ApiConstants.API_METHOD_LAYOUT_NOTES
            ], layoutNoteId
        )

        return this.apiRequestService.delete(request);
    }
}
