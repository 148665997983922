import {CustomWorkflowTransitionModel} from '../../../models/api/custom-workflow-transition.model';

export enum ETransitionType {
    FORWARD = 'FORWARD',
    BACKWARD = 'BACKWARD',
    SELF = 'SELF'
}

export class IndicatorTransitionsModel {
    public transitionType: ETransitionType;
    public stepId: string;
    public transitions: CustomWorkflowTransitionModel[] = [];
    public count: number = 0;
}
