<div class="loading-counter-container" [ngStyle]="{'height.px': rx * 2 + 2, 'width.px': width() + 2}">
    <svg xmlns="http://www.w3.org/2000/svg">
        <rect x="1"
              y="1"
              attr.width="{{ width() }}"
              height="22"
              attr.rx="{{ rx }}"
              stroke="#D9D9D9"
              stroke-width="2"
              stroke-dashoffset="0"
              stroke-linecap="round"></rect>
        <rect x="1"
              y="1"
              attr.width="{{ width() }}"
              attr.height="{{ rx * 2 }}"
              attr.rx="{{ rx }}"
              stroke="#3FBB87"
              fill="white"
              stroke-width="2"
              attr.stroke-dasharray="{{ loadedPath() }} {{ remainingPath() }}"
              stroke-dashoffset="0"
              stroke-linecap="round">
            <animate attributeName="stroke-dashoffset"
                     repeatCount="indefinite"
                     dur="5s"
                     attr.values="{{ pathLength() }}; 0"></animate>
        </rect>

        <text fill="#777777"
              xml:space="preserve"
              style="white-space: pre"
              font-family="Source Sans Pro"
              font-size="12"
              font-weight="400"
              letter-spacing="0px">
        <tspan attr.x="{{ width() / 2 + 1 }}" text-anchor="middle" y="16.266">
          {{ count }}
        </tspan>
      </text>
    </svg>
</div>
