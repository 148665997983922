import {autoserialize, autoserializeAs} from 'cerialize';
import {UserModel} from './user.model';
import {ITableItem} from '@relayter/rubber-duck';
import {FileModel} from './file.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class FontStyle {
    @autoserialize name: string;
    @autoserializeAs(FileModel) preview: FileModel;
}

export class FontFile {
    @autoserialize public name: string;
    @autoserializeAs(FileModel) public source: FileModel;
    @autoserializeAs(FontStyle) public styles: FontStyle[];
}

export class FontModel implements ITableItem, IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public family: string;
    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserializeAs(UserModel) public createdBy: UserModel;
    @autoserializeAs(FontFile) public files: FontFile[];

    public getTitle(): string {
        return this.family;
    }

    public getValue(): string {
        return this._id;
    }
}
