import { millisecondsInHour } from "./constants.js";

/**
 * @name hoursToMilliseconds
 * @category  Conversion Helpers
 * @summary Convert hours to milliseconds.
 *
 * @description
 * Convert a number of hours to a full number of milliseconds.
 *
 * @param hours - number of hours to be converted
 *
 * @returns The number of hours converted to milliseconds
 *
 * @example
 * // Convert 2 hours to milliseconds:
 * const result = hoursToMilliseconds(2)
 * //=> 7200000
 */
export function hoursToMilliseconds(hours) {
  return Math.trunc(hours * millisecondsInHour);
}

// Fallback for modularized imports:
export default hoursToMilliseconds;