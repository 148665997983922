import {autoserialize, autoserializeAs} from 'cerialize';
import {AssetModel} from './asset.model';
import {ICollectionViewItem} from '../interfaces/collection-view-item.interface';
import {IDataFieldsModel} from '../interfaces/datafields-model.interface';
import {ICardMediaType, ITableItem} from '@relayter/rubber-duck';
import {ISearchIndexScore} from '../interfaces/search-index-score.interface';

export class ProductAssetExportSortItemModel {
    @autoserialize public sortItem: string;
    @autoserialize public asset: string;
}

export class ProductAssetExportModel {
    @autoserialize public productAssetExportSetup: string;
    @autoserializeAs(ProductAssetExportSortItemModel) public items: ProductAssetExportSortItemModel[];
}

export class ProductModel implements IDataFieldsModel, ICollectionViewItem, ITableItem, ISearchIndexScore {
    @autoserialize public _id: string;
    @autoserialize public tags: string[] = [];
    @autoserializeAs(AssetModel) public assets: AssetModel[] = [];
    @autoserializeAs(Object) public dataFields: Record<string, any>;
    @autoserialize public updatedAt: string;
    @autoserialize public createdAt: string;
    @autoserialize public searchIndexScore: number;
    @autoserializeAs(ProductAssetExportModel) public assetExports: ProductAssetExportModel[];

    public get thumb(): string {
        return this.getThumbnailMedia()?.url;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getThumbnailURL(): string {
        return this.assets[0]?.getThumbnailURL();
    }

    /**
     * Interface function
     * @return {string}
     */
    public getThumbnailMedia(): ICardMediaType {
        return this.assets[0]?.getThumbnailMedia();
    }

    /**
     * Interface function
     * @return {AssetModel}
     */
    public getThumbnailAsset(): AssetModel {
        return this.assets[0];
    }

    /**
     * Interface function
     * @return {string}
     */
    public getTitle(): string {
        return null;
    }

    /**
     * Interface function
     * @return {string}
     */
    public getSubtitle(): string {
        return null;
    }
}

export interface IProductApiModel {
    dataFields: Record<string, any>;
    assets?: string[];
    productAssetExports?: ProductAssetExportModel[];
}
