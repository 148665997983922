import {autoserialize, autoserializeAs, inheritSerialization} from 'cerialize';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {ITableItem} from '@relayter/rubber-duck';
import {RuleConditionModel} from './rule-condition.model';
import {LayoutModel} from './layout.model';
import {EDataFieldFormatter} from '../../app.enums';

export class PackageRuleModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(RuleConditionModel) public conditions: RuleConditionModel[] = [];
    @autoserialize public dataFields: Record<string, any> = {};
}

export class PackageSetupDataTypeModel {
    @autoserialize public type: string;
    public formatter: EDataFieldFormatter;
}

export class PackageSetupDataFieldModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public fieldName: string;
    @autoserializeAs(PackageSetupDataTypeModel) public dataType: PackageSetupDataTypeModel;
}

@inheritSerialization(PackageSetupDataFieldModel)
export class PackageSetupCampaignDataFieldModel extends PackageSetupDataFieldModel {
    @autoserialize public property: string;
}

export class PackageExportSettings {
    @autoserializeAs(LayoutModel) public layout: LayoutModel | string;
    @autoserialize public sortBy: string[];
    @autoserialize public multiplyItemsByField: string;
    @autoserialize public addInsertsFor: string[];
    @autoserialize public addTitlePage: boolean;
}

export class PackageSetupModel implements IDropdownItem, ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public description: string;
    @autoserializeAs(PackageSetupDataFieldModel) public materialDataFields: PackageSetupDataFieldModel[] = [];
    @autoserializeAs(PackageSetupCampaignDataFieldModel) public campaignDataFields: PackageSetupCampaignDataFieldModel[] = [];
    @autoserializeAs(PackageRuleModel) public rules: PackageRuleModel[] = [];
    @autoserializeAs(PackageExportSettings) public exportSettings: PackageExportSettings | Record<string, never>;

    public getTitle(): string {
        return this.name;
    }

    public getValue(): string {
        return this._id;
    }
}

export class PackageSetupPatchModel {
    @autoserialize public name: string;
    @autoserialize public description: string;
    @autoserializeAs(PackageSetupDataFieldModel) public materialDataFields: PackageSetupDataFieldModel[];
    @autoserializeAs(PackageSetupCampaignDataFieldModel) public campaignDataFields: PackageSetupCampaignDataFieldModel[] = [];
    @autoserializeAs(PackageExportSettings) public exportSettings: PackageExportSettings | Record<string, never>;

    constructor(packageSetup?: PackageSetupModel) {
        if (packageSetup) {
            this.name = packageSetup.name;
            this.description = packageSetup.description;
            this.materialDataFields = packageSetup.materialDataFields;
            this.campaignDataFields = packageSetup.campaignDataFields;
            this.exportSettings = packageSetup.exportSettings;
        }
    }
}
