<div class="items-container" [class.no-tab-bar]="noTabBar">
    <div class="header-container">
        <p class="section-header">
            Selected {{selectionTitle}}
            <ng-container *ngIf="tableDataProvider.hasValue">
                ({{tableDataProvider.selected.length}})
            </ng-container>
        </p>
        <nuc-button-secondary
            *ngIf="tableDataProvider.hasValue"
            (click)="handleClearSelection()"
            text="Clear selected items">
        </nuc-button-secondary>
    </div>
    <nuc-table *ngIf="tableDataProvider.hasValue; else noSelection"
               [useButtonBar]="false"
               [items]="tableDataProvider.selected"
               [columns]="selectedColumns"
               (actionClicked)="handleTableAction($event)"
               [tableId]="tableId"
               (columnsChanged)="onColumnsChanged(true)"
               [prefixStorageKey]="storageKey"
               [actions]="tableActions">
    </nuc-table>

    <ng-template #noSelection>
        <nuc-empty-state-component [title]="noSelectedItemsTitle || 'You have not selected any item'"
                                   [small]="true"
                                   imageUrl="assets/images/general_empty_state.svg">
        </nuc-empty-state-component>
    </ng-template>

    <p class="section-header">Available {{selectionTitle}}</p>
    <nuc-table [emptyStateTitle]="noResultsTitle"
               [tableDataProvider]="tableDataProvider"
               [columns]="columns"
               (columnsChanged)="onColumnsChanged()"
               [selectionMode]="selectionMode"
               [tableSortOptions]="tableDataProvider.sortOptions"
               (tableSortOptionsChange)="tableSortOptionsChange.emit()"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [disableSort]="disableSort">
        <ng-container button-bar-left>
            <ng-content select="[button-bar-left]"></ng-content>
        </ng-container>
        <ng-container button-bar-left-after>
            <ng-content select="[button-bar-left-after]"></ng-content>
        </ng-container>
        <ng-container button-bar-right>
            <ng-content select="[button-bar-right]"></ng-content>
            <paginator [viewId]="viewId"
                       [loading]="tableDataProvider.loading"
                       [disableNextPage]="tableDataProvider.disableNextPage"
                       [disablePageSizeOptions]="tableDataProvider.searching">
            </paginator>
        </ng-container>
    </nuc-table>
</div>
