import {autoserialize} from 'cerialize';
import {AssetFileTypePipe} from '../../pipes/asset-file-type.pipe';
import {ICardMediaType} from '@relayter/rubber-duck';

export class FileModel {
    @autoserialize public url: string;
    @autoserialize public size: number
    @autoserialize public extension: string;

    get media(): ICardMediaType {
        if (this.url){
            return {url: this.url, type: AssetFileTypePipe.format(this.extension)};
        }
        return null;
    }
}
