<ng-container *ngIf="workflowConfiguration">
    <ng-container *ngFor="let step of workflowConfiguration.steps; let first = first">

        <rl-transition *ngIf="!first"
                       [step]="step"
                       [hasNext]="hasPreviousStep(step)"
                       [indicatorTransitions]="indicatorTransitionsSubject | async"
                       [backwardsTransitionName]="getNameForBackwardsTransitionFromStep(step)"
                       [hasBackwardsTransition]="hasBackwardsTransition(step) || hasFlyByTransition(step)">
        </rl-transition>

        <rl-step (click)="setActiveStep(step, $event)"
                 [step]="step"
                 [active]="activeStep?._id === step?._id"
                 [hasPrevious]="hasPreviousStep(step)"
                 [hasNext]="hasNextStep(step)"
                 [class.disabled]="!(step.permissions | userIsAllowedTo)"
                 [hasBackwardsTransition]="hasBackwardsTransition(step)"
                 [hasIncomingBackwardsTransition]="hasIncomingBackwardsTransition(step)"
                 [hasFlyByTransition]="hasFlyByTransition(step)"
                 [itemCount]="getItemCountForStep(step)"
                 [indicatorTransitions]="indicatorTransitionsSubject | async">
        </rl-step>

    </ng-container>
</ng-container>

<ng-container *ngIf="!workflowConfiguration">
    <rl-loading-indicator></rl-loading-indicator>
</ng-container>
