import {autoserializeAs} from 'cerialize';
import {MasterPageModel} from './master-page.model';
import {StaticContentRulesetModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {DesignLibraryModel} from '../../modules/static-content-rulesets/models/api/design-library.model';
import {VariantModel} from './variant.model';

export class TemplateVariantPresetModel {
    @autoserializeAs(VariantModel) public variant: VariantModel;
    @autoserializeAs(MasterPageModel) public masterPage: MasterPageModel;
    @autoserializeAs(DesignLibraryModel) public designLibrary: DesignLibraryModel;
    @autoserializeAs(StaticContentRulesetModel) public ruleset: StaticContentRulesetModel;
}
