import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {ApplicationOptions} from 'pixi.js';
import {Subject, Subscription} from 'rxjs';
import {AreaClickEvent} from './area/template-area.pixi';
import {ItemPixi, MoveEvent, ResizeEvent} from './item/item.pixi';
import {ItemStagePixi} from './stages/item-stage.pixi';
import {PlaceEvent, TemplateStagePixi} from './stages/template-stage.pixi';
import {DialogCustomContentConfig, NucDialogCustomContentService} from '@relayter/rubber-duck';
import {ILayoutNoteFormData, LayoutNoteFormComponent} from './note/layout-note-form/layout-note-form.component';
import {MasterPagesService} from '../../../../../../../api/services/master-pages.service';
import {BackgroundStagePixi} from './stages/background-stage.pixi';
import {
    ContentAreaModel,
    PublicationItemContentModel
} from '../../../../../../../models/api/publication-item-content.model';
import {
    EPropertySettingsContext,
    PropertySettingsService
} from '../../../../../../../components/property-settings/property-settings.service';
import {takeUntil} from 'rxjs/operators';
import {PropertySettingsModel} from '../../../../../../../components/property-settings/property-settings.model';
import {AppConstants} from '../../../../../../../app.constants';
import {CampaignItemModel} from '../../../../../../../models/api/campaign-item.model';
import {LayoutNoteModel} from '../../../../../../../models/api/layout-note.model';
import {CampaignModel} from '../../../../../../../models/api/campaign.model';
import {CustomWorkflowLayoutService, LayoutDragDropItem} from '../custom-workflow-layout.service';
import {LayoutNotePreviewComponent} from './note/layout-note-preview/layout-note-preview.component';
import {TemplateModel} from '../../../../../../../models/api/template.model';
import {VariantModel} from '../../../../../../../models/api/variant.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {PublicationItemsApiService} from '../../../../../../../api/services/publication-items-api.service';
import {EKeyCodes} from '../../../../../../../app.enums';

export interface IEditorOptions {
    editEnabled: boolean;
    editBriefingItem: boolean;
    showLayoutNotes: boolean;
}

@Component({
    selector: 'rl-spread-editor-component',
    templateUrl: './spread-editor.component.html',
    styleUrls: ['./spread-editor.component.scss']
})
export class SpreadEditorComponent implements AfterViewInit, AfterViewChecked, OnChanges, OnDestroy {
    // TODO: Fix common pitfalls (Memory issues / cleanup textures): https://github.com/pixijs/pixi.js/wiki/v4-Tips,-Tricks,-and-Pitfalls
    private renderer = inject(Renderer2);
    private snackBar: MatSnackBar = inject(MatSnackBar);
    private snackbarShownOnce = false;

    private masterPageSubscription: Subscription;

    @Input() public editorOptions: IEditorOptions;
    @Input() public content: PublicationItemContentModel[];
    @Input() public template: TemplateModel;
    @Input() public campaign: CampaignModel;
    @Input() public publication: PublicationModel;
    @Input() public publicationItem: PublicationItemModel;
    @Input() public activeVariant: VariantModel;

    @Output() public editCampaignItem: EventEmitter<CampaignItemModel> = new EventEmitter<CampaignItemModel>();

    @ViewChild('editor', {static: true}) public editor: ElementRef;
    @ViewChild('container', {static: true}) public container: ElementRef;
    @ViewChild('canvasContainer', {static: true}) public canvasContainer: ElementRef;

    private oldContainerWidth: number;
    private oldContainerHeight: number;
    private pixiApp: PIXI.Application;
    private scalingStage: PIXI.Container;
    private backgroundStage: BackgroundStagePixi;
    private templateStage: TemplateStagePixi;
    private itemStage: ItemStagePixi;
    private propertySettings: PropertySettingsModel;

    public initialMousePosition: PIXI.Point;
    public initialStagePosition: PIXI.Point;
    public initialScaleRatio: number;
    public scaleRatio: number;
    public minScale = 1; // Minimum zoom level
    public maxScale = 3; // Maximum zoom level
    public zoomFactor = .25; // Zoom factor per button click

    private onDestroySubject = new Subject<void>();

    private readonly boundOnMouseUp = () => this.onMouseUp();
    private readonly boundOnSpaceDown = (event: KeyboardEvent) => this.keyEventDown(event);
    private readonly boundOnSpaceUp = (event: KeyboardEvent) => this.keyEventUp(event);
    private readonly boundOnMouseUpOutside = () => this.onMouseUp();
    private readonly boundOnMouseDown = (event: PIXI.interaction.InteractionEvent) => this.onMouseDown(event);
    private readonly boundOnMouseMove = (event: PIXI.interaction.InteractionEvent) => this.onMouseMove(event);

    constructor(private dialogCustomContentService: NucDialogCustomContentService,
                private propertySettingsService: PropertySettingsService,
                private masterPageService: MasterPagesService,
                private customWorkflowLayoutService: CustomWorkflowLayoutService,
                private publicationItemsApiService: PublicationItemsApiService) {}

    public ngAfterViewChecked() {
        if (this.oldContainerWidth === this.canvasContainer.nativeElement.offsetWidth &&
            this.oldContainerHeight === this.canvasContainer.nativeElement.offsetHeight) {
            return;
        } else {
            this.oldContainerWidth = this.canvasContainer.nativeElement.offsetWidth;
            this.oldContainerHeight = this.canvasContainer.nativeElement.offsetHeight;
            // on resizing of screen, fit container to re-calculated ratio and update initial scale ratio with new value
            this.fitToContainer();
            this.initialScaleRatio = this.scaleRatio;
        }
    }

    public ngAfterViewInit(): void {
        this.initializePixi();

        this.container.nativeElement.addEventListener('mouseenter', () => {
            this.container.nativeElement.focus();

            document.addEventListener('keydown', this.boundOnSpaceDown);
            document.addEventListener('keyup', this.boundOnSpaceUp);
        });

        this.container.nativeElement.addEventListener('mouseleave', () => {
            document.removeEventListener('keydown', this.boundOnSpaceDown);
            document.removeEventListener('keyup', this.boundOnSpaceUp);
        });
    }

    public ngOnChanges(): void {
        // Will only fire, if one of the input references changing (not on property change)
        this.refreshLayout();
    }

    public ngOnDestroy(): void {
        this.snackBar.ngOnDestroy();
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
        this.pixiApp?.destroy(true);
    }

    public refreshLayout(): void {
        this.update();
    }

    private initializePixi(): void {
        const editorOptions: ApplicationOptions = {
            backgroundColor: 0xffffff,
            transparent: false,
            resolution: window.devicePixelRatio,
            view: this.editor.nativeElement,
            autoResize: true
        };

        this.getScaleRatio();
        this.initialScaleRatio = this.scaleRatio;

        this.pixiApp = new PIXI.Application(0, 0, editorOptions);

        this.propertySettingsService.getSettings(EPropertySettingsContext.BRIEFING).pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((propertySettings) => {
            this.propertySettings = propertySettings;
            this.refreshLayout();
        });

        this.customWorkflowLayoutService.addToLayout$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((dragDropItem: LayoutDragDropItem) => this.addToLayout(dragDropItem));
    }

    private update(): void {
        if (!this.pixiApp) {
            return;
        }

        const oldScalingStagePosition = this.scalingStage?.position;

        this.pixiApp.renderer.resize(this.canvasContainer.nativeElement.offsetWidth, this.canvasContainer.nativeElement.offsetHeight);

        this.initializeStages();

        this.drawBackground();
        this.drawTemplate();
        this.drawContent();

        if (oldScalingStagePosition) {
            // use old position (the position the user dragged the container to)
            this.scalingStage.position.set(oldScalingStagePosition.x, oldScalingStagePosition.y);
        } else {
            this.centerScalingStage();
        }

        if ((this.initialScaleRatio !== this.scaleRatio) && !this.snackbarShownOnce) {
            this.snackbarShownOnce = true;
            this.snackBar.openFromComponent(SnackbarComponent)
        }

        this.scalingStage.scale.set(this.scaleRatio);
    }

    private getScaleRatio(): void {
        const template = this.template;
        const maxWidth = this.canvasContainer.nativeElement.offsetWidth - 5;
        const maxHeight = this.canvasContainer.nativeElement.offsetHeight - 5;

        const width = template.pageSize.width * template.numberOfPages;

        this.scaleRatio = Math.min(maxWidth / width, maxHeight / template.pageSize.height)
    }

    public onMouseUp(): void {
        this.renderer.removeStyle(document.body, '-webkit-user-select');
        this.renderer.removeStyle(document.body, 'user-select');

        this.scalingStage.buttonMode = true;
        this.scalingStage.off('mousemove', this.boundOnMouseMove)
    }

    public onMouseDown(mouseDownEvent: PIXI.interaction.InteractionEvent): void {
        this.renderer.setStyle(document.body, '-webkit-user-select', 'none');
        this.renderer.setStyle(document.body, 'user-select', 'none');

        this.scalingStage.cursor = 'grabbing';
        this.initialMousePosition = mouseDownEvent.data.getLocalPosition(this.scalingStage.parent);
        this.initialStagePosition = new PIXI.Point(this.scalingStage.position.x, this.scalingStage.position.y);

        this.scalingStage.on('mousemove', this.boundOnMouseMove);
        this.scalingStage.on('mouseup', this.boundOnMouseUp);
        this.scalingStage.on('mouseupoutside', this.boundOnMouseUpOutside);
    }

    public onMouseMove(mouseMoveEvent: PIXI.interaction.InteractionEvent): void {
        const newMousePosition = mouseMoveEvent.data.getLocalPosition(this.scalingStage.parent);

        const newPositionX = this.initialStagePosition.x + (newMousePosition.x - this.initialMousePosition.x)
        const newPositionY = this.initialStagePosition.y + (newMousePosition.y - this.initialMousePosition.y);

        this.scalingStage.position.set(newPositionX, newPositionY);
    }

    keyEventDown(keyBoardEvent: KeyboardEvent): void {
        if (keyBoardEvent.code !== EKeyCodes.SPACE || !this.interactiveChildrenState) return;

        this.snackBar.dismiss();
        this.interactiveChildren = false;
        this.interactiveDragging = true;
        this.scalingStage.on('mousedown', this.boundOnMouseDown);
    }

    keyEventUp(keyBoardEvent: KeyboardEvent): void {
        if (keyBoardEvent.code !== EKeyCodes.SPACE) return;

        this.renderer.removeStyle(document.body, '-webkit-user-select');
        this.renderer.removeStyle(document.body, 'user-select');

        this.interactiveChildren = true;
        this.interactiveDragging = false;

        this.scalingStage.off('mouseup', this.boundOnMouseUp);
        this.scalingStage.off('mouseupoutside', this.boundOnMouseUpOutside);
        this.scalingStage.off('mousedown', this.boundOnMouseDown);
        this.scalingStage.off('mousemove', this.boundOnMouseMove);
    }

    private get interactiveChildrenState(): boolean {
        return this.templateStage.interactiveChildren;
    }

    private set interactiveChildren(state: boolean) {
        this.templateStage.interactiveChildren = state;
        this.itemStage.interactiveChildren = state;
    }

    private set interactiveDragging(state: boolean) {
        this.scalingStage.interactive = state;
        this.scalingStage.buttonMode = state;
    }

    private initializeStages(): void {
        if (!this.pixiApp) {
            return;
        }
        // Cleanup old stages if they exist
        if (this.scalingStage) {
            this.scalingStage.destroy({children: true});
        }
        if (this.templateStage) {
            this.templateStage.destroy({children: true});
        }
        if (this.backgroundStage) {
            this.backgroundStage.destroy({children: true});
        }
        if (this.itemStage) {
            this.itemStage.destroy({children: true});
        }

        this.scalingStage = new PIXI.Container();
        this.backgroundStage = new BackgroundStagePixi(this.editorOptions);
        this.templateStage = new TemplateStagePixi(this.editorOptions, this.scaleRatio);
        this.itemStage = new ItemStagePixi();

        this.scalingStage.addChild(this.backgroundStage);
        this.scalingStage.addChild(this.templateStage);
        this.scalingStage.addChild(this.itemStage);
        this.pixiApp.stage.addChild(this.scalingStage);

        this.itemStage.onItemMoving$.subscribe((event) => this.onItemMoving(event));
        this.itemStage.onItemMoved$.subscribe(() => this.onItemMoved());
        this.itemStage.onItemMoveCancelled$.subscribe(() => this.onItemMoveCancelled());

        this.itemStage.onItemEdit$.subscribe((itemToEdit) => this.editContent(itemToEdit));
        this.itemStage.onItemRemoved$.subscribe((removedItem) => this.onItemRemoved(removedItem));
        this.itemStage.onItemResized$.subscribe(() => this.onItemResized());
        this.itemStage.onItemResizing$.subscribe((resizeEvent) => this.onItemResizing(resizeEvent));
        this.itemStage.onItemClicked$.subscribe((itemClicked) => this.viewNote(itemClicked)); // only when editEnabled is false

        this.templateStage.onAreaClicked$.subscribe((areaClickEvent) => this.createNote(areaClickEvent));
    }

    private drawBackground(): void {
        if (this.masterPageSubscription) {
            this.masterPageSubscription.unsubscribe();
        }

        this.backgroundStage.reset();
        if (this.template.masterPage) {
            // TODO: Update detail call tp populate master page there so we don't need a api call here (/publication/{id}/items/{itemId})
            this.masterPageSubscription = this.masterPageService.getMasterPage(this.template.masterPage)
                .subscribe((masterPage) => this.backgroundStage.setMasterPage(masterPage));
        } else {
            this.backgroundStage.setDefaultBackground(this.template);
        }
    }

    private drawTemplate(): void {
        this.templateStage.setTemplate(this.template);
    }

    private drawContent(): void {
        // TODO: Move this into itemStage
        this.content.forEach((contentItem, index) => {
            const templateArea = this.template.areas.find((area) => contentItem.area._id === area._id);

            const item = new ItemPixi(
                contentItem.getContent(),
                this.template,
                templateArea,
                contentItem.area.startRow,
                contentItem.area.startColumn,
                contentItem.area.rowSpan,
                contentItem.area.columnSpan,
                index,
                this.activeVariant,
                this.editorOptions,
                this.scaleRatio,
                contentItem.contentType === AppConstants.PUBLICATION_ITEM_CONTENT_TYPES.CAMPAIGN_ITEM ? this.propertySettings : undefined);

            this.itemStage.addItem(item);
        });
    }

    private onItemMoving(event: MoveEvent): void {
        this.templateStage.highlight(event);
    }

    private onItemMoved(): void {
        this.templateStage.resetHighlight();
        this.notifyLayoutUpdate();
    }

    private onItemMoveCancelled(): void {
        this.templateStage.resetHighlight();
    }

    private onItemResizing(resizeEvent: ResizeEvent): void {
        this.templateStage.highlight(resizeEvent);
    }

    private onItemResized(): void {
        this.templateStage.resetHighlight();
        this.notifyLayoutUpdate();
    }

    private onItemRemoved(removedItem: ItemPixi): void {
        removedItem.destroy();
        this.notifyLayoutUpdate();
    }

    private notifyLayoutUpdate(): void {
        const content = this.itemStage.getItems().map((item) => {
            const contentArea = new ContentAreaModel(item.templateArea._id, item.size.row, item.size.column, item.size.rowSpan, item.size.columnSpan);
            return new PublicationItemContentModel(item.content, contentArea);
        });

        this.customWorkflowLayoutService.publicationItemContentUpdated(content);
    }

    private editContent(itemToEdit: ItemPixi): void {
        if (itemToEdit.content instanceof LayoutNoteModel) {
            const layoutNote = itemToEdit.content;
            const dialogConfig = new DialogCustomContentConfig(
                'Edit note', 'Specify the color and enter a description of your note.', {layoutNote} as ILayoutNoteFormData);
            this.dialogCustomContentService.open(LayoutNoteFormComponent, dialogConfig).afterClosed().subscribe((result) => {
                if (result) {
                    // After resizing, pixi objects are removed from the canvas and replaced by new ones
                    // So, find the pixi object with the same content index
                    const item = this.itemStage.getItems().find(item => item.contentIndex === itemToEdit.contentIndex);
                    if (item) {
                        this.publicationItemsApiService.updateLayoutNote(this.publication._id, this.publicationItem._id, result)
                            .subscribe(() => {
                                item.updateContent(result);
                                this.notifyLayoutUpdate();
                            });
                    }
                }
            });
        } else if (itemToEdit.content instanceof CampaignItemModel) {
            this.editCampaignItem.emit(itemToEdit.content);
        }
    }

    private createNote(areaClickEvent: AreaClickEvent): void {
        const dialogConfig = new DialogCustomContentConfig(
            'Add note', 'Specify the color and enter a description of your note.', {});
        this.dialogCustomContentService.open(LayoutNoteFormComponent, dialogConfig).afterClosed().subscribe((result) => {
            if (result) {
                this.publicationItemsApiService.postLayoutNote(this.publication._id, this.publicationItem._id, result).subscribe((newNote) => {
                    const newItem = new ItemPixi(newNote,
                        this.template,
                        areaClickEvent.area.templateArea,
                        areaClickEvent.row, areaClickEvent.column,
                        1,
                        1,
                        -1,
                        this.activeVariant,
                        this.editorOptions,
                        this.scaleRatio);

                    this.itemStage.addItem(newItem);
                    this.notifyLayoutUpdate();
                })
            }
        });
    }

    private viewNote(item: ItemPixi): void {
        if (item.content instanceof LayoutNoteModel) {
            const dialogConfig = new DialogCustomContentConfig('View note', '', {layoutNote: item.content});
            this.dialogCustomContentService.open(LayoutNotePreviewComponent, dialogConfig);
        }

    }

    // Handles the dragging in campaign items
    private addToLayout(dropEvent: LayoutDragDropItem): void {
        if (!this.editorOptions.editEnabled) {
            return;
        }

        const x = dropEvent.mouseEvent.offsetX;
        const y = dropEvent.mouseEvent.offsetY;

        this.templateStage.resetHighlight();
        this.itemStage.resetBlocking();

        const placeEvent = this.templateStage.getPlaceEventForGlobalPosition(x, y);
        if (!placeEvent) {
            return;
        }

        const isItemBlocking = this.itemStage.getItems().some(item => item.blocks(placeEvent, dropEvent.dragData));
        if (isItemBlocking) {
            return;
        }

        const isLayoutNote = dropEvent.dragData instanceof LayoutNoteModel;
        if (isLayoutNote) {
            const foundLayoutNote = this.itemStage.getItems()
                .find((item) =>
                     (item.content as LayoutNoteModel)._id === dropEvent.dragData._id
                );
            if (foundLayoutNote) {
                // if there is already a layout note in the itemStage, update its position
                this.updateNoteItemInStage(foundLayoutNote, placeEvent);
                return;
            }
        }

        this.createAndAddItemToStage(dropEvent, placeEvent);
    }

    private createAndAddItemToStage(dropEvent: LayoutDragDropItem, placeEvent: PlaceEvent) {
        const isCampaignItem = dropEvent.dragData instanceof CampaignItemModel;
        const newItem = new ItemPixi(dropEvent.dragData,
            this.template,
            placeEvent.templateArea,
            placeEvent.row, placeEvent.column, placeEvent.rowSpan, placeEvent.columnSpan,
            -1,
            this.activeVariant,
            this.editorOptions,
            this.scaleRatio,
            isCampaignItem ? this.propertySettings : undefined);

        this.itemStage.addItem(newItem);
        this.notifyLayoutUpdate();
    }

    public updateNoteItemInStage(layoutNoteItem: ItemPixi, placeEvent: PlaceEvent) {
        layoutNoteItem.templateArea = placeEvent.templateArea;
        layoutNoteItem.size = {
            row: placeEvent.row,
            column: placeEvent.column,
            rowSpan: placeEvent.rowSpan,
            columnSpan: placeEvent.columnSpan
        };
        this.notifyLayoutUpdate();
    }

    public zoomIn(): void {
        this.scaleRatio = Math.min(this.scaleRatio + this.zoomFactor * this.initialScaleRatio, this.maxScale * this.initialScaleRatio);
        this.adjustZoom();
        this.refreshLayout();
    }

    public zoomOut(): void {
        this.scaleRatio = Math.max(this.scaleRatio - this.zoomFactor * this.initialScaleRatio, this.minScale * this.initialScaleRatio);
        this.adjustZoom();
        this.refreshLayout();
    }

    public adjustZoom(): void {
        const newPositionX = this.scalingStage.position.x - (((this.pixiApp.screen.width / 2) - this.scalingStage.position.x) *
            (( this.scaleRatio / this.scalingStage.scale.x) - 1));
        const newPositionY = this.scalingStage.position.y - (((this.pixiApp.screen.height / 2) - this.scalingStage.position.y) *
            (( this.scaleRatio / this.scalingStage.scale.x) - 1));

        this.scalingStage.position.set(newPositionX, newPositionY)
        this.scalingStage.scale.set(this.scaleRatio);
    }

    public fitToContainer(): void {
        this.getScaleRatio();
        this.centerScalingStage();
        this.refreshLayout();
    }

    public centerScalingStage(): void {
        const centerPointX = (this.canvasContainer.nativeElement.offsetWidth / 2) -
            (this.template.pageSize.width * this.template.numberOfPages / 2 * this.scaleRatio);
        const centerPointY = (this.canvasContainer.nativeElement.offsetHeight / 2) -
            (this.template.pageSize.height / 2 * this.scaleRatio);

        this.scalingStage.position.set(centerPointX, centerPointY);
        this.scalingStage.scale.set(this.scaleRatio / this.initialScaleRatio);
    }

    public valueChanged(sliderValue: number): void {
        this.scaleRatio = (sliderValue / 100) * this.initialScaleRatio;
        this.adjustZoom();
    }

    public getZoomValueForSlider(): number {
        return (100 / this.initialScaleRatio) * this.scaleRatio;
    }

    public getZoomValueStringForSlider(): string {
        return `${Math.round(this.getZoomValueForSlider())}%`;
    }
}
