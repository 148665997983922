import {DestroyRef, inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';

import {ApiService} from './api.service';
import {ApiGetRequest} from '../requests/api-get.request';
import {SignedUrlModel} from '../../models/api/signed-url.model';
import {Toaster} from '../../classes/toaster.class';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable({providedIn: 'root'})
export class SignedUrlService extends ApiService<SignedUrlModel> {
    private destroyRef: DestroyRef;
    constructor() {
        super([], SignedUrlModel);
        this.destroyRef = inject(DestroyRef);
    }

    private refreshSignedUrl(signedUrl: string): Observable<SignedUrlModel> {
       const request = new ApiGetRequest(
           [ApiConstants.API_GROUP_SIGNED_URL, ApiConstants.API_METHOD_REFRESH], SignedUrlModel);
       request.additionalQueryParams.addParam('signed-url', signedUrl);
       return this.apiRequestService.get(request);
    }

    public async validateAndRefreshSignedUrl(url: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const params = new URL(url).searchParams;
            const expires = params.get('Expires');
            // 300ms margin
            if (((new Date().getTime() + 300) / 1000)  < parseInt(expires)) {
                resolve(url);
                return;
            }
            this.refreshSignedUrl(url)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        resolve(result.signedUrl);
                    },
                    error: error => {
                        Toaster.handleApiError(error);
                        reject(error);
                    }
                })
        })
    }

}
