<div class="properties">
    <ng-container *ngFor="let propertyInput of propertyInputs; index as index; first as first"
                  class="property-item" [class.first-item]="first">
        <div class="condition-field">
            <div [ngSwitch]="propertyInput?.type">
                <nuc-dropdown-badge *ngSwitchCase="PROPERTY_TYPE.FIELD"
                                    [items]="propertyInput.options"
                                    [minWidth]="minimalBadgeWidth"
                                    [value]="propertyInput.value"
                                    [disabled]="disabled"
                                    (dataChange)="onBadgeChange(index, $event)"
                                    [searchable]="true"
                                    [nullOption]="controlType === EControlType.CONDITION"
                                    (requestData)="getBadgeOptions(index, $event)"
                                    placeholder="Select a property field"
                                    icon="nucicon_grid" />
                <nuc-badge *ngSwitchCase="PROPERTY_TYPE.ARRAY"
                           icon="nucicon_progress"
                           cdkOverlayOrigin
                           [badgeType]="isOpenIndex[index] ? BUTTON_TYPE.PRIMARY : propertyInput ? BUTTON_TYPE.SELECTED : BUTTON_TYPE.UNSELECTED"
                           (click)="!disabled && showOperatorOverlay(index, isOpenIndex[index])"
                           #trigger="cdkOverlayOrigin">
                    {{ propertyInput.value !== null && propertyInput.value > 0 ? ('Item at ' + propertyInput.value) : 'Select an operator' }}
                    <ng-template
                        cdk-connected-overlay
                        [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="isOpenIndex[index]"
                        (overlayOutsideClick)="isOpenIndex[index] = false; $event.stopPropagation();">
                        <div class="operator">
                            <div class="title">
                                <nuc-label>Select an<span class="nucicon_progress"></span>operator</nuc-label>
                                <nuc-button-secondary class="end" nucTooltip="Remove operator"
                                                      icon="nucicon_close" (click)="removeOperator(index)"/>
                                <nuc-button-primary class="end" nucTooltip="Close"
                                                    icon="nucicon_check" (click)="isOpenIndex[index] = false;"/>
                            </div>
                            <nuc-radiobutton [data]="'Item at'" [dataItems]="operators" disabled>Item at</nuc-radiobutton>
                            <nuc-form-field label="Index">
                                <nuc-input type="number"
                                           min="1"
                                           [data]="propertyInput.value"
                                           placeholder="Select an array index"
                                           (dataChange)="onArrayIndexChange(index, $event)"/>
                            </nuc-form-field>
                        </div>
                    </ng-template>
                </nuc-badge>
                <nuc-dropdown-badge *ngSwitchCase="PROPERTY_TYPE.VARIANT"
                                    [items]="propertyInput.variantOptions"
                                    [minWidth]="minimalBadgeWidth"
                                    [value]="propertyInput.value"
                                    [disabled]="disabled"
                                    (dataChange)="onVariantChanged(index, $event)"
                                    [searchable]="true"
                                    [nullOption]="!variantRequired"
                                    (requestData)="getVariantOptions(index, $event)"
                                    placeholder="Select a variant"
                                    icon="nucicon_progress" />
            </div>
            <div class="connector" [ngSwitch]="getConnectionType(index)">
                <div *ngSwitchCase="EConnectionType.CONNECT" class="connector-image connector-connect"></div>
                <div *ngSwitchCase="EConnectionType.END" class="connector-image connector-end"></div>
            </div>
        </div>
    </ng-container>
</div>
