import {EColumnDataType, GetPropertyPipe, ITableItem, ITableSortOptions} from '@relayter/rubber-duck';
import {TableSortOptions} from './table-sort-options';

export class NewCursor {
    public _id: string;
    public values: string[] = [];
    public readonly tableSortOptions: TableSortOptions;

    public get hasDuplicates() {
        return this.tableSortOptions.hasDuplicates;
    }

    public get dataTypes(): EColumnDataType[] {
        return this.tableSortOptions.dataTypes;
    }

    constructor(tableSortOptions = new TableSortOptions(), _id?: string, values: string[] = []) {
        this.tableSortOptions = tableSortOptions;
        this._id = _id;
        this.values = values;
    }

    public createCursorForItem(item: ITableItem): NewCursor {
        // If the paginated field has only unique values, we can use its values as the id
        // Otherwise we use the item._id as the unique identifier together with the paginated field values
        // Data fields always can have duplicates so _id will be used for the cursor. No need to get property with variant key
        const paginatedFieldValues = this.tableSortOptions.sortProperties?.map(paginatedField => GetPropertyPipe.transform(item, paginatedField));
        const id = (this.hasDuplicates || !this.tableSortOptions.sortProperties?.length) ? item._id : paginatedFieldValues[0];
        const values = this.hasDuplicates ? paginatedFieldValues : undefined;

        return new NewCursor(this.tableSortOptions, id, values);
    }

    public clone(): NewCursor {
        return new NewCursor(new TableSortOptions(this.tableSortOptions.columns, this.tableSortOptions.sortOrder), this._id, this.values);
    }
}

export class NewCursorArray {
    private cursors: NewCursor[];
    private tableSortOptions: TableSortOptions;

    constructor(pageIndex: number = 1, sortColumns: ITableSortOptions = new TableSortOptions()) {
        this.reset(pageIndex, sortColumns);
    }

    /**
     * Initiate the array of cursors
     *
     * @param {number} pageIndex - Page index
     * @private
     */
    private initCursor(pageIndex: number = 1): void {
        this.cursors = [...Array(pageIndex)].map(() => new NewCursor(this.tableSortOptions));
    }

    /**
     * Resets the array of cursors
     *
     * @param {number} pageIndex - Page index
     * @param {TableSortOptions} sortColumns - Sorting columns
     * @returns {void}
     */
    public reset(pageIndex: number = 1, sortColumns?: ITableSortOptions): void {
        if (sortColumns) this.tableSortOptions = sortColumns as TableSortOptions;

        this.initCursor(pageIndex);
    }

    /**
     * Adds a new cursor for pageIndex
     *
     * @param {number} pageIndex - page index
     * @param {ITableItem} item - item object, containing the paginated field as a property
     */
    public setCursor(pageIndex: number, item: ITableItem): void {
        if (this.cursors?.length > (pageIndex - 1)) {
            const cursor = this.cursors[0].createCursorForItem(item);

            this.cursors.splice(pageIndex);
            this.cursors.push(cursor);
        }
    }

    /**
     * Get the cursor for page index
     * @param pageIndex
     */
    public getCursor(pageIndex: number = 1): NewCursor {
        if (this.cursors?.length > (pageIndex - 1)) {
            return this.cursors[pageIndex - 1];
        }
        return null;
    }
}
