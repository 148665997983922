import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {RLCountsModel} from '../../api/response/rl-counts.model';
import {RLCountModel} from '../../api/response/rl-count.model';
import {CustomWorkflowTransitionModel} from '../../models/api/custom-workflow-transition.model';
import {ETransitionType, IndicatorTransitionsModel} from './indicator/indicator-transitions.model';
import {Subject} from 'rxjs';

@Component({
    selector: 'rl-workflow-indicator',
    templateUrl: './workflow-indicator.component.html',
    styleUrls: ['./workflow-indicator.component.scss']
})
export class WorkflowIndicatorComponent implements OnChanges {
    @Input() public counts: RLCountsModel;
    @Input() public workflowConfiguration: WorkflowConfigurationModel;
    @Input() public activeStep: CustomWorkflowStepModel;
    @Output() public activeStepChange = new EventEmitter<CustomWorkflowStepModel>();

    protected readonly ETransitionType = ETransitionType;

    protected indicatorTransitions: IndicatorTransitionsModel[] = [];
    protected indicatorTransitionsSubject = new Subject<IndicatorTransitionsModel[]>();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.counts) {
            this.synchronizeIndicatorTransitions();
        }
    }

    /**
     * Returns the name of a backwards transition
     * Currently this only returns the name of the transition between step and previous step
     * Could be improved to handle multiple steps backwards (Not required for current workflow-configurations)
     * @param {CustomWorkflowStepModel} step
     * @returns {string}
     */
    public getNameForBackwardsTransitionFromStep(step: CustomWorkflowStepModel): string {
        const previousStep = this.getPreviousStep(step);
        const transitions = this.getTransitionsBetweenSteps(step, previousStep);
        return transitions.length > 0 ? transitions[0].title : null;
    }

    /**
     * Returns the previous step if it exists (index - 1)
     * @param {CustomWorkflowStepModel} currentStep
     * @returns {CustomWorkflowStepModel}
     */
    private getPreviousStep(currentStep: CustomWorkflowStepModel): CustomWorkflowStepModel {
        const index = this.workflowConfiguration.steps.indexOf(currentStep);

        if (index === 0 || index === -1) { // Step is the first step, or doesn't exist
            return null;
        }

        return this.workflowConfiguration.steps[index - 1];
    }

    /**
     * Returns the next step if it exists (index + 1)
     * @param {CustomWorkflowStepModel} currentStep
     * @returns {CustomWorkflowStepModel}
     */
    private getNextStep(currentStep: CustomWorkflowStepModel): CustomWorkflowStepModel {
        const index = this.workflowConfiguration.steps.indexOf(currentStep);

        if (index === this.workflowConfiguration.steps.length - 1 || index === -1) { // Step is the last step, or doesn't exist
            return null;
        }

        return this.workflowConfiguration.steps[index + 1];
    }

    /**
     * Returns the transitions between two steps
     * @param {CustomWorkflowStepModel} fromStep
     * @param {CustomWorkflowStepModel} toStep
     * @returns {CustomWorkflowTransitionModel[]}
     */
    private getTransitionsBetweenSteps(fromStep: CustomWorkflowStepModel, toStep: CustomWorkflowStepModel): CustomWorkflowTransitionModel[] {
        return this.workflowConfiguration.transitions.filter((transition) => transition.from === fromStep._id && transition.to === toStep._id);
    }

    /**
     * Checks if there is a transition from the previous step (index - 1) to this step
     * @param {CustomWorkflowStepModel} currentStep
     * @returns {boolean}
     */
    public hasPreviousStep(currentStep: CustomWorkflowStepModel): boolean {
        const previousStep = this.getPreviousStep(currentStep);
        return previousStep ? this.getTransitionsBetweenSteps(previousStep, currentStep).length > 0 : false;
    }

    /**
     * Checks if there is a transition from this step to the next step (index + 1)
     * @param {CustomWorkflowStepModel} currentStep
     * @returns {boolean}
     */
    public hasNextStep(currentStep: CustomWorkflowStepModel): boolean {
        const nextStep = this.getNextStep(currentStep);
        return nextStep ? this.getTransitionsBetweenSteps(currentStep, nextStep).length > 0 : false;
    }

    /**
     * Checks if there is a transition from this step to a step with a lower index
     * @param {CustomWorkflowStepModel} step
     * @returns {boolean}
     */
    public hasBackwardsTransition(step: CustomWorkflowStepModel): boolean {
        // First find the index of the step, so we can use it to exclude steps
        const index = this.workflowConfiguration.steps.indexOf(step);

        if (index === 0) { // If step is the initial step we can stop
            return false;
        }

        // Get all stepsIds with a lower step index
        const stepOptions = this.workflowConfiguration.steps.slice(0, index).map((stepOption) => stepOption._id);

        // Check if there exists a transition from step to stepOption
        return this.workflowConfiguration.transitions.some((transition) => transition.from === step._id && stepOptions.includes(transition.to));
    }

    /**
     * Checks if there is a transition from a step with a higher index to this step
     * @param {CustomWorkflowStepModel} step
     * @returns {boolean}
     */
    public hasIncomingBackwardsTransition(step: CustomWorkflowStepModel): boolean {
        // First find the index of the step, so we can use it to exclude steps
        const index = this.workflowConfiguration.steps.indexOf(step);

        if (index === this.workflowConfiguration.steps.length - 1) { // If step is the last step we can stop
            return false;
        }

        // Get all stepsIds with a higher step index
        const stepOptions = this.workflowConfiguration.steps.slice(index + 1).map((stepOption) => stepOption._id);

        // Check if there exists a transition from step to stepOption
        return this.workflowConfiguration.transitions.some((transition) => transition.to === step._id && stepOptions.includes(transition.from));
    }

    /**
     * Checks if there is a "flyby" transition
     * Checks for a transition from a step with a higher index to a step to a lower index
     * @param {CustomWorkflowStepModel} step
     * @returns {boolean}
     */
    public hasFlyByTransition(step: CustomWorkflowStepModel): boolean {
        // First find the index of the step, so we can use it to exclude steps
        const index = this.workflowConfiguration.steps.indexOf(step);

        if (index === 0 || index === this.workflowConfiguration.steps.length - 1) { // If step is the initial or last step we can stop
            return false;
        }

        // Get all stepsIds with a lower step index
        const stepOptionsBefore = this.workflowConfiguration.steps.slice(0, index).map((stepOption) => stepOption._id);

        // Get all stepsIds with a higher step index
        const stepOptionsAfter = this.workflowConfiguration.steps.slice(index + 1).map((stepOption) => stepOption._id);

        return this.workflowConfiguration.transitions
            .some((transition) => stepOptionsBefore.includes(transition.to) && stepOptionsAfter.includes(transition.from));
    }

    /**
     * Returns the number of items in a step if item counts are set
     * @param {CustomWorkflowStepModel} step
     * @return {number}
     */
    public getItemCountForStep(step: CustomWorkflowStepModel): number {
        if (this.counts) {
            const stepCountDetails = this.counts.steps.find((count: RLCountModel) => count._id === step._id);
            return stepCountDetails ? stepCountDetails.count : 0;
        }
        return 0;
    }

    /**
     * Returns transitions between 2 steps going forward or backward.
     * @param {CustomWorkflowStepModel} step
     * @param {ETransitionType} transitionType
     * @returns {CustomWorkflowTransitionModel[]}
     */
    private getTransitionsForStep(step: CustomWorkflowStepModel, transitionType: ETransitionType): CustomWorkflowTransitionModel[] {
        const previousStep = this.getPreviousStep(step);
        switch (transitionType) {
            case ETransitionType.FORWARD:
                return this.getTransitionsBetweenSteps(previousStep, step);
            case ETransitionType.BACKWARD:
                return this.getTransitionsBetweenSteps(step, previousStep);
            case ETransitionType.SELF:
                return this.getTransitionsBetweenSteps(step, step);
        }
    }

    private getTransitionItemCountForStep(step: CustomWorkflowStepModel, transitionType: ETransitionType): number {
        if (this.counts) {
            const previousStep = this.getPreviousStep(step);
            const transitions = [];
            switch (transitionType) {
                case ETransitionType.FORWARD:
                    transitions.push(...this.getTransitionsBetweenSteps(previousStep, step));
                    break;
                case ETransitionType.BACKWARD:
                    transitions.push(...this.getTransitionsBetweenSteps(step, previousStep));
                    break;
                case ETransitionType.SELF:
                    transitions.push(...this.getTransitionsBetweenSteps(step, step));
                    break;

            }
            return transitions.reduce((acc, transition) => {
                const transitionCountDetails = this.counts.transitions.find((count: RLCountModel) => count._id === transition._id);
                return acc + (transitionCountDetails?.count || 0);
            }, 0);
        }
        return 0;
    }

    public setActiveStep(step: CustomWorkflowStepModel, event: MouseEvent): void {
        event.stopPropagation();
        this.activeStepChange.emit(step);
    }

    private synchronizeIndicatorTransitions(): void {
        let changed = false;
        this.workflowConfiguration.steps.forEach((step, index) => {
            // Skip first step
            if (index === 0) return;

            Object.keys(ETransitionType).forEach((type: ETransitionType) => {
                const count = this.getTransitionItemCountForStep(step, type);

                let indicatorTransition = this.indicatorTransitions.find(indicator =>
                    indicator.stepId === step._id && indicator.transitionType === type);

                if (!indicatorTransition) {
                    changed = true;
                    indicatorTransition = new IndicatorTransitionsModel();
                    indicatorTransition.transitionType = type;
                    indicatorTransition.stepId = step._id;
                    indicatorTransition.transitions = this.getTransitionsForStep(step, type);
                    this.indicatorTransitions.push(indicatorTransition);
                }

                if (count !== indicatorTransition.count) {
                    changed = true;
                    indicatorTransition.count = count;
                }
            });
        });

        if (changed) {
            this.indicatorTransitionsSubject.next(Array.from(this.indicatorTransitions));
        }
    }
}
