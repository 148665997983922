import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {EToastType, ToastDataModel} from '@relayter/rubber-duck';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {ETransitionStatus, TransitionItemModel} from '../../../models/api/transition-item.model';
import {MonitoredTransitionsService} from '../../../api/services/monitored-transitions.service';
import {UserService} from '../../../api/services/users.service';
import {UserModel} from '../../../models/api/user.model';
import {ErrorConstants} from '../../../api/error.constants';
import {startWith} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'transition-notification',
    styleUrls: ['transition-notification.component.scss'],
    templateUrl: 'transition-notification.component.html',
})
export class TransitionNotificationComponent implements OnInit, OnDestroy {
    private destroyRef = inject(DestroyRef);
    public transitionItemsWithToastData: { transitionItemId: string, toastData: ToastDataModel }[] = [];

    private user: UserModel;

    /**
     * @param {MonitoredTransitionsService} monitoredTransitionsService
     * @param {UserService} userService
     */
    constructor(private monitoredTransitionsService: MonitoredTransitionsService,
                private userService: UserService) {
        this.user = this.userService.getLoggedInUser();
    }

    public ngOnDestroy(): void {
        this.transitionItemsWithToastData.forEach((transitionItemAndToastData) => {
            transitionItemAndToastData.toastData.loading = false;
        })
    }

    /**
     * Angular lifecycle method
     * Starts the monitoring of the transition
     */
    public ngOnInit(): void {
        this.monitoredTransitionsService.monitoredTransitionsSubject
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result: TransitionItemModel[]) => {
                    result.forEach((transitionItem) => {
                        const transitionItemInList = this.transitionItemsWithToastData
                            .some((transitionItemAndToastData) => transitionItemAndToastData.transitionItemId === transitionItem._id);

                        if (!transitionItemInList) {
                            const toastData = new ToastDataModel(EToastType.NEUTRAL, 'Transition', 'Queued', true);

                            this.transitionItemsWithToastData.push({ transitionItemId: transitionItem._id, toastData })

                            // Only show toasts for the current user
                            if (transitionItem.user === this.user._id) {
                                Toaster.openToast(toastData);
                            }

                            this.monitoredTransitionsService.getTransitionMonitor(transitionItem._id)
                                .pipe(
                                    startWith(transitionItem),
                                    takeUntilDestroyed(this.destroyRef)
                                )
                                .subscribe({
                                    next: (transitionItem) => this.updateToastData(transitionItem, toastData),
                                    error: (error) => ARLogger.error(`transitionNotification: ${error}`),
                                    complete: () => this.monitoredTransitionsService.removeMonitoredTransition(transitionItem._id)
                                });
                        }
                    })
                },
                error: ARLogger.error
            });
    }

    private updateToastData(transitionItem: TransitionItemModel, toastData: ToastDataModel): void {
        switch (transitionItem.status) {
            case ETransitionStatus.QUEUED:
                toastData.type = EToastType.NEUTRAL;
                toastData.message = 'Successfully scheduled transition of publication item(s)';
                toastData.loading = true;
                break;
            case ETransitionStatus.IN_PROGRESS:
                toastData.type = EToastType.NEUTRAL;
                toastData.message = 'Transition in progress';
                toastData.loading = true;
                break;
            case ETransitionStatus.FAILED:
                // TODO: We need to find a better way to distinguish user errors (404) and inter server errors (500)
                toastData.type = EToastType.ERROR;
                toastData.title = transitionItem.error?.title || toastData.title;
                toastData.message = transitionItem.error?.message || 'Transition failed';
                // Toaster will disappear when there is useful error message to show
                toastData.loading = !(toastData.message === 'Transition failed' ||
                    toastData.message === ErrorConstants.INTERNAL_SERVER_ERROR_MESSAGE);
                break;
            case ETransitionStatus.DONE:
                // When transition is DONE and started by the user, show notification
                // For all other users let them know there is a transition done, and they should refresh the publication items
                if (transitionItem.user === this.user._id) {
                    toastData.type = EToastType.SUCCESS;
                    toastData.loading = false;
                    toastData.message ='Transition completed';
                } else {
                    this.monitoredTransitionsService.workflowStepItemsChanged(transitionItem);
                }
                break;
            default:
                ARLogger.error(`TransitionNotification: Unhandled transition status ${transitionItem.status}`);
        }
    }
}
