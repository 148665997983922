import {Pipe, PipeTransform} from '@angular/core';
import {ChannelModel} from '../models/api/channel.model';

export enum EChannelDisplayProperties {
    TITLE,
    VALUE
}

@Pipe({
    name: 'channelDisplay'
})
export class ChannelDisplayPipe implements PipeTransform {

    public transform(channel: ChannelModel, property: EChannelDisplayProperties): string {
        switch (property) {
            case EChannelDisplayProperties.TITLE:
                return channel.getTitle();
            case EChannelDisplayProperties.VALUE:
                return channel.getValue();
            default:
                throw new Error(`ChannelDisplayPipe: Unhandled property ${property} on channel ${channel}`);
        }
    }

}
