import { Component } from '@angular/core';
import {NgClass} from '@angular/common';

@Component({
  selector: 'resizable-border',
  standalone: true,
    imports: [
        NgClass
    ],
  templateUrl: './resizable-border.component.html',
  styleUrl: './resizable-border.component.scss'
})
export class ResizableBorderComponent {
    public isHorizontal = true;
}
