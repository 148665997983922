<span matSnackBarLabel>
  Hold [space] to drag layout
</span>
<span matSnackBarActions>
    <nuc-icon-button mat-button
                     matSnackBarAction
                     icon="nucicon_close"
                     (click)="snackBarRef.dismiss()">
    </nuc-icon-button>
</span>
