import {Pipe, PipeTransform} from '@angular/core';
import {CampaignItemModel} from '../models/api/campaign-item.model';
import {AssetModel} from '../models/api/asset.model';
import {LayoutNoteModel} from '../models/api/layout-note.model';

@Pipe({name: 'isPlaced'})
export class IsPlacedPipe implements PipeTransform {

    public transform(value: CampaignItemModel | AssetModel | LayoutNoteModel, selection: string[]): boolean {
        return selection.some((id) => value._id === id);
    }
}
