import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {EEngineType, TemplateModel} from '../../models/api/template.model';
import {JobModel} from '../../models/api/job.model';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-jobs.service';
import {SortDirection} from '@angular/material/sort';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ARPagedResponseDataModel} from '@relayter/core';
import {EChannel} from '../../app.enums';

interface ITemplateJobData {
    templateId: string;
    templateIdToMigrate: string;
}

export enum ETemplateJobType {
    DELETE_TEMPLATE_JOB = 'DELETE_TEMPLATE_JOB'
}

@Injectable({providedIn: 'root'})
export class TemplatesApiService extends ApiService<TemplateModel> {

    constructor(private monitoredJobsService: MonitoredJobsService) {
        super([ApiConstants.API_GROUP_TEMPLATES], TemplateModel);
    }

    public postJob(jobType: ETemplateJobType, jobData: ITemplateJobData): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);

        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
            );
    }

    public getTemplates(channel: EChannel, engineTypes: EEngineType[],
                        limit?: number, offset?: number,
                        numberOfPages?: number, tags?: string[], search?: string,
                        templateTypeIds?: string[], sortProperty?: string,
                        sortOrder?: SortDirection, publicationId?: string,
                        campaignItemId?: string): Observable<ARPagedResponseDataModel<TemplateModel>> {
        const request = new ApiPagedRequest(
            this.groups,
            TemplateModel,
            limit,
            offset,
            null,
            null,
            search
        );
        request.additionalQueryParams.setSortAndSortDirectionParams(sortProperty, sortOrder);
        request.additionalQueryParams.addParam('engineTypes', engineTypes);
        request.additionalQueryParams.addParam('channel', channel);

        if (numberOfPages > 0) {
            request.additionalQueryParams.addParam('numberOfPages', numberOfPages);
        }
        if (publicationId) {
            request.additionalQueryParams.addParam('publicationId', publicationId);
        }
        if (campaignItemId) {
            request.additionalQueryParams.addParam('campaignItemId', campaignItemId);
        }
        if (tags?.length > 0) {
            request.additionalQueryParams.addParam('tags', tags);
        }
        if (templateTypeIds && templateTypeIds?.length > 0) {
            request.additionalQueryParams.addParam('templateTypeIds', templateTypeIds)
        }

        return this.apiRequestService.find(request);
    }
}
