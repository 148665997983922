import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {CampaignModel, CampaignPostModel, CampaignPutModel} from '../../models/api/campaign.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {EAssetExportFormat, EDataExportFormat, EPackageType} from '../../forms/package-export-form/package-export-form.component';
import {MonitoredJobsService} from './monitored-jobs.service';
import {PublicationItemModel} from '../../models/api/publication-item.model';
import {
    EMasterBriefingDataFieldCopyOption
} from '../../components/import-from-master-briefing/datafield-option-form/data-field-option-form.component';
import {IExportDataFieldCollectionJobData} from './data-fields.service';

export enum ECampaignJobTypes {
    CAMPAIGN_IMPORT_JOB = 'CAMPAIGN_IMPORT_JOB',
    COPY_MASTER_BRIEFING_TO_CAMPAIGN_JOB = 'COPY_MASTER_BRIEFING_TO_CAMPAIGN_JOB',
    CAMPAIGN_EXPORT_BRIEFING_JOB = 'CAMPAIGN_EXPORT_BRIEFING_JOB',
    CAMPAIGN_DELETE_JOB = 'CAMPAIGN_DELETE_JOB',
    CAMPAIGN_COPY_JOB = 'CAMPAIGN_COPY_JOB',
    CAMPAIGN_ADD_PACKAGES_JOB = 'CAMPAIGN_ADD_PACKAGES_JOB',
    CAMPAIGN_UPDATE_PACKAGE_JOB = 'CAMPAIGN_UPDATE_PACKAGE_JOB',
    CAMPAIGN_UPDATE_ALL_PACKAGES_JOB = 'CAMPAIGN_UPDATE_ALL_PACKAGES_JOB',
    CAMPAIGN_PACKAGE_EXPORT_JOB = 'CAMPAIGN_PACKAGE_EXPORT_JOB'
}

export interface ICopyMasterBriefingToCampaignJobData {
    campaignId: string;
    campaignItemIds: string[];
    dataFieldCopyOptions?: {dataFieldId: string, behavior: EMasterBriefingDataFieldCopyOption, value?: any}[];
}

export interface ICampaignItemImportJobData {
    campaignName: string;
    campaignStartDate: Date;
    campaignEndDate: Date;
    linkAllProductAssets?: boolean;
    productIdentifier?: string;
    assetIdentifier?: string;
    campaignItemIdentifier: string;
    s3Key: string;
    variant: string;
}

export interface IDeleteCampaignJobData {
    campaignId: string;
}

export interface ICopyCampaignJobData {
    campaignId: string;
}

export interface ICampaignAddPackagesJobData {
    campaignId: string;
    packageSetups: string[];
}

export interface ICampaignUpdatePackageJobData {
    campaignId: string;
    campaignPackageId: string;
}

export interface ICampaignUpdateAllPackagesJobData {
    campaignId: string;
}

export interface ICampaignPackageExportJobData {
    campaignId: string;
    packageType: EPackageType;
    dataExportFormat?: EDataExportFormat;
    assetExportFormat?: EAssetExportFormat;
    campaignPackageIds?: string[];
    variantId?: string;
}

export type ICampaignJobData =
    ICampaignItemImportJobData |
    ICopyMasterBriefingToCampaignJobData |
    IDeleteCampaignJobData |
    ICopyCampaignJobData |
    IExportDataFieldCollectionJobData |
    ICampaignAddPackagesJobData |
    ICampaignPackageExportJobData;

@Injectable({
    providedIn: 'root'
})
export class CampaignService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    public postCampaign(campaign: CampaignPostModel): Observable<CampaignModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = campaign;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, CampaignModel);
        });
    }

    /**
     * Update a campaign
     * @param {string} id
     * @param {CampaignModel} campaign
     * @returns {Observable<CampaignModel>}
     */
    public putCampaign(id: string, campaign: CampaignPutModel): Observable<CampaignModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            id]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = campaign;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, CampaignModel);
        });
    }

    /**
     * Get a single campaign
     * @param id
     * @returns {Observable<CampaignModel>}
     */
    public getCampaignById(id: string): Observable<CampaignModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS, `/${id}`]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, CampaignModel);
        });
    }

    public postJob(jobType: ECampaignJobTypes, jobData: ICampaignJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            ApiConstants.API_METHOD_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }

    public assignCampaignItemToPublicationItem(campaignId: string,
                                               campaignItemId: string,
                                               publicationId: string,
                                               publicationItemId: string): Observable<PublicationItemModel> {
        const queryParams = new QueryParams().addParam('publicationId', publicationId)
            .addParam('publicationItemId', publicationItemId);

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER, ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_CAMPAIGNS,
            campaignId,
            ApiConstants.API_METHOD_ITEMS,
            campaignItemId,
            ApiConstants.API_METHOD_ASSIGN_PUBLICATION_ITEM], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        return new Observable<PublicationItemModel>((obs) => {
            this.handleDetailResponse(options, obs, PublicationItemModel);
        });
    }
}
