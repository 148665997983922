import {Component, Inject, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA
} from '@relayter/rubber-duck';
import {UserIsAllowedToPipe} from '../../pipes/user-is-allowed-to.pipe';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../classes/toaster.class';
import {EPublicationJobType, INoteExportJobData, PublicationsService} from '../../api/services/publications.service';
import {IQueryParam, QueryParams} from '../../classes/query-params';
import {Cursor} from '../../api/api-cursor';
import {SortDirection} from '@angular/material/sort';
import {ErrorConstants} from '../../api/error.constants';

export interface INoteExportFormData {
    publicationId: string;
    stepId: string;
    filters: IQueryParam[];
    sortProperty: string;
    sortOrder: SortDirection;
    cursor: Cursor;
    search: string;
}

export enum ENoteExportFileType {
    CSV = 'CSV',
}

interface INoteExportOption {
    name: string;
    exportType: ENoteExportFileType;
    description?: string;
}

@Component({
    selector: 'note-export-form',
    templateUrl: './note-export-form.component.html',
    styleUrls: ['./note-export-form.component.scss']
})
export class NoteExportFormComponent implements OnInit {
    private exportButton: ButtonConfig;
    public selectedExportOption: INoteExportOption;

    public allNoteExportTypes: INoteExportOption[] = [
        {
            name: 'Data CSV',
            exportType: ENoteExportFileType.CSV,
            description: 'A CSV file that contains the publication (or a part) item notes.'
        }];

    constructor(@Inject(NUC_FULL_MODAL_DATA) private modalData: INoteExportFormData,
                private fullModalService: FullModalService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private publicationService: PublicationsService) {
    }

    public ngOnInit(): void {
        this.selectedExportOption = this.allNoteExportTypes[0];
        this.setupModal();
    }

    private setupModal(): void {
        this.exportButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Export', null, null, !this.selectedExportOption);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false));
        const save = new FullModalActionModel(this.exportButton);
        save.observable.subscribe(() => {
            this.exportButton.loading = true;
            this.exportButton.disabled = true;
            this.postJob();
        });
        this.fullModalService.setModalActions([cancel, save]);
    }

    public onNoteExportOptionClicked(type: INoteExportOption): void {
        this.selectedExportOption = type;

        this.exportButton.disabled = !this.selectedExportOption;
    }

    private postJob(): void {
        const queryParams = new QueryParams()
            .setSortAndSortDirectionParams(this.modalData.sortProperty, this.modalData.sortOrder)
            .addParam('workflowStepId', this.modalData.stepId)
            .setSearchParams(this.modalData.search)
            .setCursor(this.modalData.cursor);

        if (this.modalData.filters) {
            for (const filter of this.modalData.filters) {
                queryParams.addParam(filter.queryParam, filter.value);
            }
        }

        const jobData = {
            publicationId: this.modalData.publicationId,
            stepId: this.modalData.stepId,
            exportType: this.selectedExportOption.exportType,
            query: queryParams.getParams()
        } as INoteExportJobData;

        this.publicationService
            .postJob(EPublicationJobType.NOTE_EXPORT_JOB, jobData)
            .subscribe({
                next: (job) => {
                    this.fullModalService.close(job._id); // close with job id
                    ARLogger.debug('Note export job scheduled: ' + job._id);
                },
                error: (error) => {
                    if (error.code === ErrorConstants.API_ERROR_CODES.NOT_FOUND_EXPORT_NOTES ||
                        error.code !== ErrorConstants.API_ERROR_CODES.NOT_FOUND_DEFAULT) {
                        Toaster.handleApiWarning(error);
                    } else {
                        Toaster.handleApiError(error);
                    }
                    this.exportButton.disabled = false;
                    this.exportButton.loading = false;
                }
            });
    }
}
