import {inject, Injectable} from '@angular/core';
import {finalize, tap} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {CampaignItemModel} from '../../models/api/campaign-item.model';
import {Toaster} from '../../classes/toaster.class';
import {CampaignItemsApiService} from '../../api/services/campaign-items.api.service';
import {SelectionModel} from '@angular/cdk/collections';
import {RelayterTableDataProvider} from '../../classes/relayter-table-data-provider.service';
import {TableSortOptions} from '../../api/table-sort-options';

@Injectable()
export class MasterBriefingDataProvider extends RelayterTableDataProvider<CampaignItemModel> {
    private campaignItemsApiService = inject(CampaignItemsApiService)

    constructor(public sortOptions: TableSortOptions) {
        super(sortOptions);

        this.selection = new SelectionModel<CampaignItemModel>(true);
    }

    public retrieveData(all = false): void {
        this.loading = true;

        if (all) {
            this.campaignItemsApiService.getAllCampaignItems(undefined, undefined,
                this.searchValue, this.sortOptions, this.filterValues, this.apiCursor.getCursor(1))
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                    takeUntilDestroyed(this.destroyRef)

                )
                .subscribe({
                    next: (items: CampaignItemModel[]) => {
                        this.select(...items);
                    },
                    error: (err: ARApiError) => {
                        Toaster.handleApiError(err);
                    }
                });
        } else {
            this.campaignItemsApiService.getCampaignItems(undefined, undefined,
                this.pageSize, 0, this.searchValue, this.sortOptions, this.filterValues, this.apiCursor.getCursor(this.pageIndex))
                .pipe(
                    finalize(() => {
                        this.loading = false;
                    }),
                    tap(() => {
                        this.searching = !!this.searchValue;
                    }),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: (res: ARPagedResponseDataModel<CampaignItemModel>) => {
                        this.data = res.items;
                        this.disableNextPage = !res.hasNext;

                        if (res.items.length > 0) {
                            this.setCursor();
                        }
                    },
                    error: (err: ARApiError) => {
                        Toaster.handleApiError(err);
                    }
                });
        }

    }
}
