import {autoserialize, autoserializeAs} from 'cerialize';
import {ISpreadContentTypeBody} from '../interfaces/spread-content-type-body.interface';
import {UserModel} from './user.model';

export class LayoutNoteModel implements ISpreadContentTypeBody {
    @autoserialize public _id?: string; // _id is optional inside contentRevision
    @autoserialize public message: string;
    @autoserialize public color: string;
    @autoserializeAs(UserModel) public createdBy: UserModel;

    /**
     * @constructor
     * @param {string} message
     * @param {string} color
     * @param {string} [_id]
     */
    constructor(message: string, color: string, _id?: string, ) {
        if (_id) this._id = _id;
        this.message = message;
        this.color = color;
    }

    /**
     * Interface method to check if this Content Type Body has loadable content (meaning it has images that need to be loaded by PIXI.loader)
     * @returns {boolean}
     */
    public isLoadable(): boolean {
        return false;
    }

    /**
     * Interface / Convenience method to get an array of loadable image url for PIXI.loader
     * @param {string} [category]
     * @returns {string[]}
     */
    /* "@ts-expect-error" */

    // TODO check if this is actually used or a bug
    public getUniqueAssetUrls(): string[] {
        return null;
    }

    public getSpreadEditorTitle(): string {
        return `Note:\n${this.message}`;
    }

}
