import {ISerializable} from 'cerialize';
import {RLValidatorRegExConstants} from './validators/rl-validator-regex.constant';
import {ARUtils} from '@relayter/core';

export class ChangeLogValueSerializer implements ISerializable {
    public Deserialize(json: any): any {
        return this.getValue(json);
    }

    private getValue(value: any): any {
        if (value === null || value === undefined) return value;

        if (typeof value === 'string') {
            // Check for Date string fields
            if (value.match(RLValidatorRegExConstants.ISO8601)) {
                return new Date(value);
            }
        }

        // Variant values
        if (ARUtils.isObject(value)) {
            return Object.keys(value).reduce((object, key) => {
                object[key] = this.getValue(value[key]);
                return object;
            }, {});
        }

        return value;
    }
 }
