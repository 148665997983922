<div class="container" *ngIf="!loading; else loadingIndicator"  [ngClass]="{'time-line': showTimeLine}">
    <div *ngIf="changelogs?.length === 0" class="text-center">
        <p>No changes were made to the briefing item.</p>
    </div>

    <div class="changelogs" [ngClass]="{'time-line': showTimeLine, 'border': !showTimeLine}" *ngFor="let changelog of changelogs; let i = index">
        <div *ngIf="showTimeLine" class="time-line">
            <p class="body date">{{changelog.createdAt | RLDatePipe:dateFormats.STICKY_CHANGELOG}}</p>
            <i class="circle snow"></i>
            <div class="line" [class.last]="i === changelogs.length - 1"></div>
        </div>

        <div class="changelog" [ngClass]="{'border': showTimeLine}">
            <div class="row">
                <img class="header" *ngIf="!changelog.autoGenerated" src="/assets/images/icon_profile.svg"/>
                <div class="content-container">
                    <p class="body-strong black text-ellipsis">{{changelog.userFullName}}</p>
                    <p *ngIf="!showTimeLine || changelog.autoGenerated" class="body date">{{changelog.createdAt | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
                </div>
            </div>

            <div class="row" *ngFor="let change of changelog.changes; let j = index">
                <div class="content-container">
                    <div class="change-item">
                        <nuc-badge-dove [icon]="change.variantKey ? 'nucicon_variant' : null">{{change.name}}</nuc-badge-dove>

                        <div *ngIf="change.showValue">
                            <label class="body-strong">Current</label>
                            <p class="change-description">{{change.newValue | anyValueDisplay:dateFormats.DEFAULT | nullUndefinedFormatter}}</p>
                        </div>
                        <div *ngIf="change.showValue">
                            <label class="body-strong">Previous</label>
                            <p class="change-description">{{change.oldValue | anyValueDisplay:dateFormats.DEFAULT | nullUndefinedFormatter}}</p>
                        </div>

                        <div *ngIf="change.added?.length">
                            <label class="body-strong">Added</label>
                            <div class="assets-container">
                                <div class="asset-item" *ngFor="let addedItem of change.added">
                                    <div class="thumbnail" [ngClass]="{'no-time-line': !showTimeLine}">
                                        <img [src]="addedItem.asset?.getThumbnailMedia().url || 'assets/images/icon_image_main.svg'">
                                    </div>
                                    <p [nucTooltip]="addedItem.asset?.getTitle() | nullUndefinedFormatter:DELETED_ASSET"
                                       nucTooltipPosition="below">{{addedItem.rin}}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="change.removed?.length">
                            <label class="body-strong">Removed</label>
                            <div class="assets-container">
                                <div class="asset-item" *ngFor="let removedItem of change.removed">
                                    <div class="thumbnail" [ngClass]="{'no-time-line': !showTimeLine}">
                                        <img [src]="removedItem.asset?.getThumbnailMedia().url || 'assets/images/icon_image_main.svg'">
                                    </div>
                                    <p class="removed"
                                       [nucTooltip]="removedItem.asset?.getTitle() | nullUndefinedFormatter:DELETED_ASSET"
                                       nucTooltipPosition="below">{{removedItem.rin}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingIndicator>
    <rl-loading-indicator size="large"></rl-loading-indicator>
</ng-template>
