<nuc-button-bar>
    <nuc-button-primary text="Add data field"
                        *ngIf="permissions.POST_DATA_FIELD | userIsAllowedTo"
                        (click)="openDataFieldForm()">
    </nuc-button-primary>
</nuc-button-bar>

<nuc-table emptyStateTitle="Add the first data field."
           [loading]="!subscription?.closed"
           [columns]="columns"
           [items]="items"
           [actions]="actions"
           [tableId]="tableId"
           [prefixStorageKey]="storageKey"
           (actionClicked)="handleTableRowAction($event)">
    <paginator button-bar-right
               [loading]="!subscription?.closed"
               [viewId]="tableId"
               [disableNextPage]="disableNextPage">
    </paginator>
</nuc-table>
