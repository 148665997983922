import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {
    EColumnType,
    ESelectionMode,
    IActionClickEvent,
    ISortOptionEvent,
    ITableColumn,
    ITableItem
} from '@relayter/rubber-duck';
import {AppConstants} from '../../app.constants';
import {RLTableComponent} from '../rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../api/services/user-settings-storage.service';
import {SelectionModel} from '@angular/cdk/collections';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TableSortOptions} from '../../api/table-sort-options';

@Component({
    selector: 'multi-item-selection-component',
    templateUrl: './multi-item-selection.component.html',
    styleUrls: ['./multi-item-selection.component.scss']
})
export class MultiItemSelectionComponent extends RLTableComponent implements OnInit, OnChanges {
    @Input() public tableId: string;
    @Input() public viewId: string;
    @Input() columns: ITableColumn[];
    @Input() loading: boolean;
    @Input() public items: ITableItem[];
    @Input() public disableNextPage: boolean;
    @Input() public selectedItems: ITableItem[];
    @Input() public selectionTitle = 'items';
    @Input() public noSelectedItemsTitle: string;
    @Input() public noResultsTitle: string;
    @Input() public noTabBar = false;
    @Input() public readOnly = false;
    @Input() public disableSort = false;
    @Input() public disablePageSizeOptions = false;
    @Input() public actions = [];
    @Input() public tableSortOptions: TableSortOptions = new TableSortOptions();

    @Output() public selectedItemsChange = new EventEmitter<ITableItem[]>();
    @Output() public actionClickedEvent: EventEmitter<IActionClickEvent> = new EventEmitter<IActionClickEvent>();
    @Output() public onSortOptionChange = new EventEmitter<ISortOptionEvent>();

    public selection = new SelectionModel<ITableItem>(true);

    public iconColumn: ITableColumn = {
        title: 'Selected',
        key: 'selected',
        type: EColumnType.ICON,
        iconClass: () => 'nucicon_check_round_fill',
        color: AppConstants.FIRST_BRAND_COLOR
    };
    public selectedColumns: ITableColumn[];

    public selectionMode = ESelectionMode.MULTI;

    private defaultActions = [AppConstants.TABLE_ACTION_TYPES.DELETE];

    public tableActions = [];

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private changeDetectorRef: ChangeDetectorRef) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        if (this.readOnly) {
            this.tableActions = this.actions;
            this.selectionMode = ESelectionMode.EXPAND;
        } else {
            this.tableActions = this.actions.concat(this.defaultActions);
        }

        if (!this.viewId) this.viewId = this.tableId;

        if (this.selectedItems) {
            this.selection.setSelection(...this.selectedItems);
        }

        this.selection.changed
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.setSelectedItems());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.columns) {
            this.selectedColumns = [this.iconColumn].concat(this.columns).map(column => {
                return {...column, sortProperty: undefined} as ITableColumn;
            });
        }
    }

    public handleTableAction(actionClickEvent: IActionClickEvent): void {
        // Keep old behaviour and handle delete action, this actions is also always added
        if (actionClickEvent.action.title === AppConstants.TABLE_ACTION_TYPES.DELETE.title) {
            this.selection.deselect(actionClickEvent.item);
        } else {
            this.actionClickedEvent.emit(actionClickEvent);
        }
    }

    private setSelectedItems(): void {
        this.selectedItems = this.selection.selected;

        // Emit change
        this.selectedItemsChange.emit(this.selectedItems);
    }

    public onColumnsChanged(selectedColumnsChanged = false): void {
        if (selectedColumnsChanged) {
            // set column size, column visibility and order accordingly
            this.columns = this.selectedColumns
                .filter((selectedCol) => selectedCol.title !== this.iconColumn.title)
                .map((selectedCol) => {
                    const foundCol = this.columns.find((col) => col.title === selectedCol.title);
                    if (foundCol) {
                        foundCol.size = selectedCol.size;
                        foundCol.visible = selectedCol.visible;
                    }
                    return foundCol || selectedCol;
                });
        } else {
            const iconColumn = this.selectedColumns.find((col) => col.title === this.iconColumn.title);
            this.selectedColumns = this.columns.concat(iconColumn).map(column => {
                return {...column, sortProperty: undefined} as ITableColumn;
            });
        }
        this.changeDetectorRef.detectChanges();
    }
}
