import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {TabBarItemModel} from '../../../../../../../models/ui/tab-bar-item.model';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {UntypedFormGroup} from '@angular/forms';
import {AssignToPackageService} from './assign-to-package.service';
import {PackagesService} from '../../../../../../../api/services/packages.service';
import {AssignToPackageMaterialDataFormComponent} from './assign-to-package-material-data-form/assign-to-package-material-data-form.component';
import {PackageItemPostModel} from '../../../../../../../models/api/package-item.model';
import {distinctUntilChanged, map, takeUntil, finalize} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {EFormStatus} from '../../../../../../../app.enums';

export interface IAssignToPackageFormData {
    campaignId: string;
    publicationId: string;
    publicationItemId: string;
}

export enum ETabFormGroup {
    PACKAGES = 'packages'
}

@Component({
    selector: 'assign-to-package-form-component',
    templateUrl: 'assign-to-package-form.component.html',
    styleUrls: ['assign-to-package-form.component.scss'],
    providers: [AssignToPackageService]
})

export class AssignToPackageFormComponent implements OnInit, OnDestroy {
    public TAB_PACKAGES = 0;
    public TAB_MATERIAL_DATA = 1;

    public packagesTab = new TabBarItemModel('Packages', this.TAB_PACKAGES);
    public materialDataTab = new TabBarItemModel('Material data', this.TAB_MATERIAL_DATA);
    public tabbarItems: TabBarItemModel[] = [
        this.packagesTab,
        this.materialDataTab];

    private nextButton: ButtonConfig;
    private _selectedTab = this.tabbarItems[this.TAB_PACKAGES];
    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }
    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabbarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
            this.updateNextButtonState();
        }
    }

    private nextTabAction(): void {
        this.selectedTab = this.tabbarItems.find((tab) => tab.index === this.selectedTab.index + 1);
    }

    public form: UntypedFormGroup = new UntypedFormGroup({});

    private confirmAction: FullModalActionModel;
    private actions: FullModalActionModel[];

    public campaignId: string;
    public publicationId: string;
    public publicationItemId: string;

    /**
     * Responder to modal confirm button click. Initialized with 'go to next tab'
     */
    private onConfirmClicked = this.nextTabAction;

    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private packagesService: PackagesService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IAssignToPackageFormData) {
        if (this.modalData) {
            this.campaignId = this.modalData.campaignId;
            this.publicationId = this.modalData.publicationId;
            this.publicationItemId = this.modalData.publicationItemId;
        }
    }

    public ngOnInit(): void {
        this.initModalButtons();

        this.form.statusChanges.pipe(
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            takeUntil(this.onDestroySubject)
        ).subscribe(() => {
            this.updateNextButtonState();
        });

    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.nextButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next');

        const cancelAction = new FullModalActionModel(cancelButton);
        this.confirmAction = new FullModalActionModel(this.nextButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        this.confirmAction.observable.subscribe(() => this.onConfirmClicked());

        this.actions = [cancelAction, this.confirmAction];
        this.fullModalService.setModalActions(this.actions);
    }

    private updateNextButtonState(): void {
        this.nextButton.disabled = this._selectedTab.index === this.TAB_MATERIAL_DATA && !this.form.valid;
    }

    /**
     * If current tab is packages text is Next and action is 'go to next tab'
     * Otherwise text is Save and action is 'Save'
     */
    private setConfirmButton(): void {
        switch (this._selectedTab.index) {
            case this.TAB_PACKAGES:
                this.confirmAction.button.text = 'Next';
                this.onConfirmClicked = this.nextTabAction;
                return;
            case this.TAB_MATERIAL_DATA:
                this.confirmAction.button.text = 'Save';
                this.onConfirmClicked = this.assignItemToPackage;
                break;
        }
    }

    private assignItemToPackage(): void {
        // Assign item to selected package with material data
        const campaignPackageId = this.form.get(ETabFormGroup.PACKAGES).value;
        const packageItemBody = {
            publication: this.publicationId,
            publicationItem: this.publicationItemId,
            dataFields: this.form.get(`${AssignToPackageMaterialDataFormComponent.CONTROL_PREFIX}${campaignPackageId}`).value
        } as PackageItemPostModel;

        this.nextButton.loading = true;
        this.nextButton.disabled = true;
        this.packagesService.postPackageItem(this.campaignId, campaignPackageId, packageItemBody)
            .pipe(finalize(() => {
                this.nextButton.loading = false;
                this.updateNextButtonState();
            }))
            .subscribe(
            (result) => {
                Toaster.success('Item added to the package');
                this.fullModalService.close(result);
            },
            (err) => Toaster.handleApiError(err));
    }
}
