import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {
    public transform(text: string): string {
        return this.linkify(text ?? '');
    }

    private linkify(text) {
        const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlRegex, function(url) {
            return '<a href="' + url + '">link</a>';
        });
    }
}
