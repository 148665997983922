<div class="area top">
    <div class="label" [class.no-name]="!backwardsTransitionName && hasBackwardsTransition">
        <div class="transition-name">{{backwardsTransitionName}}</div>
    </div>

    <loading-counter
        *ngIf="typedIndicatorTransition[ETransitionType.BACKWARD]?.count"
        [nucTooltip]="transitionCountOverview[ETransitionType.BACKWARD]"
        [nucTooltipPosition]="'below'"
        [count]="typedIndicatorTransition[ETransitionType.BACKWARD]?.count"
        [progress]="transitionProgress[ETransitionType.BACKWARD]">
    </loading-counter>
</div>

<div class="area middle">
    <div class="line" [class.inactive]="!hasNext"></div>

    <loading-counter
        *ngIf="typedIndicatorTransition[ETransitionType.FORWARD]?.count"
        [nucTooltip]="transitionCountOverview[ETransitionType.FORWARD]"
        [nucTooltipPosition]="'below'"
        [count]="typedIndicatorTransition[ETransitionType.FORWARD]?.count"
        [progress]="transitionProgress[ETransitionType.FORWARD]">
    </loading-counter>
</div>

<div class="area bottom"></div>
