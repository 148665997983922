import {Component, inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {
    MatSnackBarAction,
    MatSnackBarActions,
    MatSnackBarLabel,
    MatSnackBarRef
} from '@angular/material/snack-bar';
import {NUCButtonsModule} from '@relayter/rubber-duck';

@Component({
    selector: 'rl-snackbar-component',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    standalone: true,
    imports: [MatButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, NUCButtonsModule]
})
export class SnackbarComponent {
    snackBarRef = inject(MatSnackBarRef);
}
