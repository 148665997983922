import escapeStringRegexp from 'escape-string-regexp';

export class StringUtil {
    /**
     * Escape regex characters from string
     * @param {string} stringToEscape
     * @returns {string} escaped string
     */
    public static escapeRegExp (stringToEscape: string): string {
        return escapeStringRegexp(stringToEscape);
    };
}
