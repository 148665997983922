import {autoserialize, autoserializeAs} from 'cerialize';
import {TaskErrorModel} from './task-error.model';
import {ITableItem} from '@relayter/rubber-duck';
import {UserModel} from './user.model';

export enum ETransitionStatus {
    QUEUED = 'QUEUED',
    IN_PROGRESS = 'IN_PROGRESS',
    DONE = 'DONE',
    FAILED = 'FAILED',
}

export class TransitionItemModel {
    @autoserialize public _id: string;
    @autoserialize public workflow: string;
    @autoserialize public transition: string;
    @autoserialize public status: ETransitionStatus;
    @autoserialize public items: string[];
    @autoserializeAs(TaskErrorModel) public error: TaskErrorModel;
    @autoserialize public user: string;
    @autoserialize public progress: object;
}

export class ItemDataModel {
    @autoserialize public _id: string;
    @autoserialize public publicationItemId: string;
}

export class ErrorDataModel {
    @autoserialize public message: string;
    @autoserialize public recipeTask: string;
    @autoserialize public type: string;
}

export class TransitionItemDataModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public status: ETransitionStatus;
    @autoserializeAs(ItemDataModel) public item: ItemDataModel;
    @autoserializeAs(UserModel) public user: UserModel;
    @autoserialize public recipe: string;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
    @autoserializeAs(ErrorDataModel) public error: ErrorDataModel;
}
