import {Pipe, PipeTransform} from '@angular/core';
import {BooleanDisplayPipe} from './boolean-display.pipe';
import {RLDatePipe} from './rl-date.pipe';

@Pipe({
    name: 'anyValueDisplay'
})
export class AnyValueDisplayPipe implements PipeTransform {
    public transform(value: any, dateFormat?: Record<string, any>): any {
        if (typeof value === 'boolean') return BooleanDisplayPipe.transform(value);
        if (RLDatePipe.isDate(value)) return RLDatePipe.format(value, dateFormat);
        return value;
    }
}
