import {EColumnDataType, GetPropertyPipe, ITableItem} from '@relayter/rubber-duck';

/**
 * @deprecated Use {@link NewCursor}
 */
export class Cursor {
    public _id: string;
    public value: string;
    public duplicatedValues: boolean;
    public dataType: EColumnDataType;

    constructor(_id?: string, value?: string, duplicatedValues: boolean = false, dataType?: EColumnDataType) {
        this._id = _id;
        this.value = value;
        this.duplicatedValues = duplicatedValues;
        this.dataType = dataType;
    }
}

/**
 * @deprecated Use {@link NewCursorArray}
 */
export class CursorArray {
    private cursors: Cursor[];
    private duplicates: boolean;
    private dataType: EColumnDataType;

    constructor(pageIndex: number = 1, duplicates: boolean = false, dataType?: EColumnDataType) {
        this.reset(pageIndex, duplicates, dataType);
    }

    /**
     * Initiate the array of cursors
     *
     * @param {number} pageIndex - Page index
     * @private
     */
    private initCursor(pageIndex: number = 1): void {
        this.cursors = Array(...Array(pageIndex)).map(() => {
            return new Cursor(null, null, this.duplicates, this.dataType);
        });
    }

    /**
     * Resets the array of cursors
     *
     * @param {number} pageIndex - Page index
     * @param {boolean} duplicates - Duplicated values
     * @param {EColumnDataType} dataType - Data type of this cursor values
     * @returns {void}
     */
    public reset(pageIndex: number = 1, duplicates: boolean = false, dataType?: EColumnDataType): void {
        this.duplicates = duplicates;
        this.dataType = dataType;

        this.initCursor(pageIndex);
    }

    /**
     * Adds a new cursor for pageIndex
     *
     * @param {number} pageIndex - page index
     * @param {string} paginatedField - field name for sorting the cursor
     * @param {ITableItem} item - item object, containing the paginated field as a property
     */
    public setCursor(pageIndex: number, paginatedField: string, item: ITableItem): void {
        if (this.cursors?.length > (pageIndex - 1)) {
            // If the paginated field has only unique values, we can use its value as the id
            // Otherwise we use the item._id as the unique identifier together with the paginated field value
            // Data fields always can have duplicateds so _id will be used for the cursor. No need to get property with variant key
            const paginatedFieldValue = paginatedField ? GetPropertyPipe.transform(item, paginatedField) : undefined;
            const id = (this.duplicates || !paginatedField) ? item._id : paginatedFieldValue;
            const value = this.duplicates ? paginatedFieldValue : undefined;

            const cursor = new Cursor(id, value, this.duplicates, this.dataType);

            this.cursors.splice(pageIndex);
            this.cursors.push(cursor);
        }
    }

    /**
     * Get the cursor for page index
     * @param pageIndex
     */
    public getCursor(pageIndex: number = 1): Cursor {
        if (this.cursors?.length > (pageIndex - 1)) {
            return this.cursors[pageIndex - 1];
        }
        return null;
    }
}
