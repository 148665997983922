<svg id="stepitem in generation counter"
     attr.width="{{ width() }}"
     height="24"
     attr.viewBox="0 0 {{ width() }} 24"
     fill="none"
     xmlns="http://www.w3.org/2000/svg"
     style="max-width: 100%; max-height: 100%;">
    <rect x="0.855"
          y="0.855"
          attr.width="{{ outerCircleWidth() }}"
          height="22.29"
          rx="11.145"
          fill="#3FBB87"
          stroke-width="1.71"
          attr.stroke-dasharray="0,0,0,{{ outerPathLength() }}">
    </rect>
    <text id="2833"
          fill="white"
          xml:space="preserve"
          style="white-space: pre"
          font-family="Source Sans Pro"
          font-size="12"
          font-weight="400"
          letter-spacing="0px">
    <tspan attr.x="{{ width() / 2 }}" text-anchor="middle" y="16.266">
      {{ count }}
    </tspan>
  </text>
</svg>
