import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {EJobStatus, JobModel} from '../../../models/api/job.model';
import {AppConstants} from '../../../app.constants';
import {BUTTON_TYPE, EToastType, ToastActionModel, ToastButtonConfig, ToastDataModel} from '@relayter/rubber-duck';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {MonitoredJobsService} from '../../../api/services/monitored-jobs.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-job-notification',
    styleUrls: ['job-notification.component.scss'],
    templateUrl: 'job-notification.component.html',
})
export class JobNotificationComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public jobItemIds: string[] = [];

    public DOWNLOAD_JOBS = Object.keys(AppConstants.JOBS)
        .filter(jobName => AppConstants.JOBS[jobName].download)
        .map(jobName => AppConstants.JOBS[jobName].name);

    /**
     * @param monitoredJobsService
     */
    constructor(protected monitoredJobsService: MonitoredJobsService) {}

    /**
     * Angular lifecycle method
     * Starts the monitoring of the job
     */
    public ngOnInit(): void {
        this.monitoredJobsService.getAllMonitoredJobs()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result: JobModel[]) => {
                    result.forEach((jobItem) => {
                        // make sure we only add toasters for new job items
                        if (!this.jobItemIds.includes(jobItem._id)) {
                            const toastData = new ToastDataModel(EToastType.NEUTRAL, jobItem.title, 'Queued', true);

                            this.jobItemIds.push(jobItem._id)

                            Toaster.openToast(toastData);

                            this.monitoredJobsService.getJobMonitor(jobItem._id)
                                .pipe(takeUntilDestroyed(this.destroyRef))
                                .subscribe({
                                    next: (job) => this.updateToastData(job, toastData),
                                    error: (error) => ARLogger.error(`jobNotification: ${error}`),
                                    complete: () => this.monitoredJobsService.removeMonitoredJob(jobItem._id)
                                });
                        }
                    })
                },
                error: ARLogger.error
            });
    }

    private updateToastData(job: JobModel, toastData: ToastDataModel): void {
        switch (job.status) {
            case EJobStatus.QUEUED:
                toastData.type = EToastType.NEUTRAL;
                toastData.title = job.title;
                toastData.message = 'Queued';
                toastData.loading = true;
                break;
            case EJobStatus.IN_PROGRESS:
                toastData.type = EToastType.NEUTRAL;
                toastData.title = job.title;
                toastData.message = 'In Progress';
                toastData.loading = true;
                break;
            case EJobStatus.FAILED:
                toastData.type = EToastType.ERROR;
                toastData.title = job.title;
                if (job.result) {
                    toastData.message = `Failed: ${job.result}`;
                } else {
                    toastData.message = 'Failed';
                    toastData.loading = false;
                }
                break;
            case EJobStatus.DONE:
                toastData.type = EToastType.SUCCESS;
                toastData.title = job.title;
                toastData.loading = false;
                if (this.DOWNLOAD_JOBS.includes(job.type)) {
                    toastData.message ='Ready to download';
                    this.setDownloadAction(job, toastData);
                } else {
                    toastData.message = 'Done';
                }
                break;
            default:
                ARLogger.error(`JobNotification: Unhandled Job status ${job.status}`);
        }
    }

    private setDownloadAction(job: JobModel, toastData: ToastDataModel): void {
        const downloadAction = new ToastActionModel(new ToastButtonConfig(BUTTON_TYPE.SECONDARY, 'nucicon_download'));
        downloadAction.observable.subscribe(() => window.open(job.result));

        toastData.addAction(downloadAction);
    }
}
