import {UntypedFormControl} from '@angular/forms';

/**
 * Checks if the field is an array and has no duplicate values
 * This works with formArray as array of formControls
 */
export function CustomUniqueFormArrayValidator(field: UntypedFormControl): {uniqueformarray: boolean} {
    if (field.value && Array.isArray(field.value)) {
        const selectedFields = field.value.filter((value) => !!value).map((value) => value._id);
        if (selectedFields.length !== new Set(selectedFields).size) {
            return {uniqueformarray: true};
        }
    }
    return null;
}
