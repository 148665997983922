import {autoserialize, autoserializeAs} from 'cerialize';
import {ITableItem} from '@relayter/rubber-duck';
import {TemplateModel} from './template.model';
import {RuleConditionModel} from './rule-condition.model';

export class WorkflowAutomationRuleModel implements ITableItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserializeAs(TemplateModel) public template: TemplateModel;
    @autoserializeAs(RuleConditionModel) public conditions: RuleConditionModel[] = [];
}

// this body is for post and put call
export class WorkflowAutomationRuleBodyModel {
    @autoserialize public name: string;
    @autoserialize public template: string;
    @autoserializeAs(RuleConditionModel) public conditions: RuleConditionModel[] = [];
    constructor(name: string, template: string, conditions: RuleConditionModel[]) {
        this.name = name;
        this.template = template;
        this.conditions = conditions;
    }
}

export class WorkflowAutomationModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public description: string;
    @autoserializeAs(WorkflowAutomationRuleModel) public rules: WorkflowAutomationRuleModel[];
}

export class WorkflowAutomationPostModel {
    @autoserialize public name: string;
    @autoserialize public description: string;

    constructor(name: string, description?: string) {
        this.name = name;
        if (description) {
            this.description = description;
        }
    }
}

export class WorkflowAutomationPatchModel {
    @autoserialize public name: string;
    @autoserialize public description: string;

    constructor(name: string, description: string) {
        this.name = name;
        this.description = description;
    }
}
