/**
 * Created by nandamhuis on 17/01/2017.
 */

export class RLValidatorRegExConstants {
    // eslint-disable-next-line max-len
    public static readonly EMAIL = (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    public static readonly EAN = (/^([0-9]{8})([0-9]{4,6})?$/);
    public static readonly EVEN_NUMBER = (/^[0-9]*[02468]$/);
    // eslint-disable-next-line max-len
    public static readonly URL = (/^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/);
    public static readonly ALPHANUMERIC = (/^[a-z\d\-_?\s]+$/i);
    public static readonly NUMBER = (/^([0-9]*)([,\.][0-9]+)?$/);
    public static readonly INTEGER = (/^[0-9]*$/);
    public static readonly DATA_FIELD_NAME = (/^[a-zA-Z\d-_ ]+$/i);
    public static readonly PUBLICATION_ITEM_FILE_NAME = (/[/\\?*:|"<>]/g);
    // eslint-disable-next-line max-len
    public static readonly ISO8601 = (/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/);
    public static readonly ALPHANUMERIC_STRICT = (/^[a-z\d]+$/i);
}
