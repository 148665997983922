import {Component, forwardRef} from '@angular/core';
import {DataFieldsApiService} from '../../../api/services/data-fields.api.service';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {DataFilterDropdownComponent} from '../data-filter-dropdown/data-filter-dropdown.component';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {DataFieldFilterModel} from '../../../models/ui/data-field-filter.model';
import {DropdownComponent} from '@relayter/rubber-duck';

@Component({
    selector: 'rl-data-field-dropdown',
    templateUrl: './data-field-dropdown.component.html',
    styleUrls: ['./data-field-dropdown.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DataFieldDropdownComponent),
        multi: true
    }]
})
export class DataFieldDropdownComponent extends DataFilterDropdownComponent implements ControlValueAccessor {
    private hasNext = true;
    private offset = 0;

    constructor(public dataFieldsApiService: DataFieldsApiService,
                dataCollectionService: DataCollectionService) {
        super(dataCollectionService);
    }

    public getDataValues(): void {
        if (!this.hasNext) return;

        const dropDown = this.dropDown as DropdownComponent;
        const dataFieldFilter = this.dataFilter as DataFieldFilterModel;
        this.dataFieldsApiService.getDataFieldValues(dataFieldFilter.dataFieldId, dataFieldFilter.requestOptions, dropDown.limit, this.offset)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result) => {
                this.offset += dropDown.limit;
                this.hasNext = result.hasNext;

                this.dropdownItems = this.dropdownItems.concat(result.items.map((value) => {
                    return new DropdownItem(value.displayName, value.key);
                }));
            });
    }
}
