import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA,} from '@relayter/rubber-duck';
import {DataFieldModel} from '../../../../models/api/data-field.model';
import {ARLogger} from '@relayter/core';
import {EDataFieldCollectionName, EDataFieldTypes, EFormStatus} from '../../../../app.enums';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormService} from '../../../../api/services/form.service';
import {
    EFormContext,
    FormDetailModel,
    FormFieldOptionsModel,
    FormFieldPostModel,
    FormPostModel,
    FormRowPostModel
} from '../../../../models/api/form.model';
import {Toaster} from '../../../../classes/toaster.class';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';

enum EDropActionType {
    NEW_TOP,
    NEW_BOTTOM,
    INSERT_LEFT,
    INSERT_RIGHT
}

export interface IFormRow {
    fields: DataFieldModel[];
}

interface IDropAction {
    type: EDropActionType;
    row?: IFormRow;
}

export interface IFormBuilderModalData {
    context: EFormContext;
    formId?: string;
}

@Component({
    selector: 'rl-form-builder',
    templateUrl: 'form-builder.component.html',
    styleUrls: ['form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit, OnDestroy {

    public EDropActionType = EDropActionType;
    public EDataFieldTypes = EDataFieldTypes;

    public dataFields: DataFieldModel[];
    public dragging: boolean;
    public dropAction: IDropAction;

    public rows: IFormRow[] = [];
    public form: UntypedFormGroup;

    public open: boolean;

    private saveButton: ButtonConfig;
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private dataFieldsService: DataFieldsApiService,
                private formService: FormService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: IFormBuilderModalData) {
    }

    public ngOnInit(): void {
        this.getDataFields();
        this.initForm();
    }

    private initForm(): void {
        if (this.modalData.formId) {
            this.formService.getForm(this.modalData.formId).subscribe(
                (result) => this.initFromFormDetails(result),
                (error) => Toaster.handleApiError(error)
            );
        } else {
            this.form = new UntypedFormGroup({});
            this.initButtons();
        }
    }

    private initFromFormDetails(editedForm: FormDetailModel): void {
        this.form = new UntypedFormGroup({});
        this.initButtons();
        const newRows = [];

        editedForm.rows.forEach((row) => {
            const fields = [];
            row.fields.forEach((field) => {
                fields.push(field.dataField);
                this.addFormGroup(field.dataField, field.options);
            });

            newRows.push({fields});
        });
        this.rows = newRows;
    }

    public initButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);

        const cancel = new FullModalActionModel(cancelButton);
        const save = new FullModalActionModel(this.saveButton);

        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        save.observable.subscribe(() => this.onSaveClicked());

        const actions = [cancel, save];
        this.fullModalService.setModalActions(actions);

        this.form.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntil(this.onDestroySubject)
        ).subscribe((status) => {
            this.saveButton.disabled = status !== EFormStatus.VALID; // disable the button based on the form status
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private getDataFields(): void {
        let observable;
        switch (this.modalData.context) {
            case EFormContext.PRODUCT:
                observable = this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.PRODUCT);
                break;
            case EFormContext.BRIEFING:
                observable = this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM);
                break;
            case EFormContext.ASSET:
                observable = this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.ASSET);
                break;
            default:
                ARLogger.error('Unsupported EFormContext: ', this.modalData.context);
                return;
        }
        observable.subscribe((result) => this.dataFields = result);
    }

    public onDrop(event: CdkDragDrop<any>, dataField: DataFieldModel): void {
        this.dragging = false;
        if (this.dropAction) {
            switch (this.dropAction.type) {
                case EDropActionType.NEW_TOP:
                    this.addFormGroup(dataField);
                    this.rows = [{fields: [dataField]} as IFormRow, ...this.rows];
                    break;
                case EDropActionType.INSERT_LEFT:
                    this.addFormGroup(dataField);
                    this.dropAction.row.fields = [dataField, ...this.dropAction.row.fields];
                    this.rows = [...this.rows];
                    break;
                case EDropActionType.INSERT_RIGHT:
                    this.addFormGroup(dataField);
                    this.dropAction.row.fields = [...this.dropAction.row.fields, dataField];
                    this.rows = [...this.rows];
                    break;
                case EDropActionType.NEW_BOTTOM:
                    this.addFormGroup(dataField);
                    this.rows = [...this.rows, {fields: [dataField]} as IFormRow];
                    break;
                default:
                    ARLogger.error('Unknown EDropActionType: ', this.dropAction.type);
                    break;
            }
        }
    }

    public onDeleteFieldClicked(deletedField: DataFieldModel, updatedRow: IFormRow): void {
        updatedRow.fields = updatedRow.fields.filter((field) => field._id !== deletedField._id);
        this.form.removeControl(deletedField.fieldName);
        if (!updatedRow.fields.length) {
            this.rows = this.rows.filter((row) => row !== updatedRow);
        } else {
            this.rows = [...this.rows];
        }
    }

    private onSaveClicked(): void {

        const rows = this.rows.map((row) => {
            const fields = row.fields.map((field) => {
                const formGroup = this.form.get(field.fieldName) as UntypedFormGroup;
                const options = FormFieldOptionsModel.fromFormGroup(formGroup);
                options.label = field.name; // always store the name of the data field as label for now
                return new FormFieldPostModel(field._id, options);
            });

            return new FormRowPostModel(fields);
        });

        const form = new FormPostModel(rows, this.modalData.context);
        const serviceSubscription = this.modalData.formId ?
            this.formService.putForm(this.modalData.formId, form) :
            this.formService.postForm(form);

        serviceSubscription.subscribe(
            (result) => {
                this.fullModalService.close(result);
                Toaster.success(`Form ${this.modalData.formId ? 'updated' : 'created'} successfully`);
            },
            (error) => Toaster.handleApiError(error));

    }

    private addFormGroup(dataField: DataFieldModel, options?: FormFieldOptionsModel): void {
        const formGroup = new UntypedFormGroup({}, Validators.required);

        formGroup.addControl('editable', new UntypedFormControl(options ? options.editable : true, Validators.required));

        switch (dataField.dataType.type) {
            case EDataFieldTypes.NUMBER:
            case EDataFieldTypes.ENUM:
            case EDataFieldTypes.LIST:
                formGroup.addControl('placeholder',
                    new UntypedFormControl(options ? options.placeholder : `Fill in the ${dataField.name}`, Validators.required));
                break;
            case EDataFieldTypes.STRING:
                formGroup.addControl('placeholder',
                    new UntypedFormControl(options ? options.placeholder : `Fill in the ${dataField.name}`, Validators.required));
                formGroup.addControl('multiline', new UntypedFormControl({
                        value: !!options?.multiline , disabled: dataField.enableAutocomplete && !options?.multiline},
                    Validators.required));
                break;
            case EDataFieldTypes.BOOLEAN:
                break;
            case EDataFieldTypes.DATE:
                formGroup.addControl('placeholder',
                    new UntypedFormControl(options ? options.placeholder : `Select the ${dataField.name}`, Validators.required));
                break;
            default:
                ARLogger.error('Incorrect EDataFieldType: ', dataField.dataType.type);
                break;
        }

        this.form.addControl(dataField.fieldName, formGroup);
    }

    public onRowDropped(event: CdkDragDrop<any>): void {
        moveItemInArray(this.rows, event.previousIndex, event.currentIndex);
    }
}
