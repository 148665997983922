import {Component, forwardRef, OnDestroy} from '@angular/core';
import {DataFieldsApiService} from '../../../api/services/data-fields.api.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {takeUntil} from 'rxjs/operators';
import {DataFieldDropdownComponent} from '../data-field-dropdown/data-field-dropdown.component';
import {DataCollectionService} from '../../../api/services/data-collection.service';
import {DataFieldFilterModel} from '../../../models/ui/data-field-filter.model';
import {AppConstants} from '../../../app.constants';

@Component({
    selector: 'rl-data-field-multiselect',
    templateUrl: './data-field-multiselect.component.html',
    styleUrls: ['./data-field-multiselect.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DataFieldMultiselectComponent),
        multi: true
    }]
})
export class DataFieldMultiselectComponent extends DataFieldDropdownComponent implements ControlValueAccessor, OnDestroy {
    constructor(dataFieldsApiService: DataFieldsApiService,
                dataCollectionService: DataCollectionService) {
        super(dataFieldsApiService, dataCollectionService);
    }

    public getDataValues(): void {
        const dataFieldFilter = this.dataFilter as DataFieldFilterModel;

        // Does not get all values. To prevent ddos 'ing the API we only support 100 items. Also the browser will
        // have issues with adding to much checkboxes.
        // TODO: use suggestive input which support searching
        this.dataFieldsApiService.getDataFieldValues(dataFieldFilter.dataFieldId, dataFieldFilter.requestOptions,  AppConstants.PAGE_SIZE_DEFAULT, 0)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((results) => {
                this.dropdownItems = results.items.map((value) => {
                    return new DropdownItem(value.displayName, value.key);
                });
            });
    }
}
