import {Pipe, PipeTransform} from '@angular/core';
import {ICardMediaType} from '@relayter/rubber-duck';
import {ICollectionViewItem} from '../models/interfaces/collection-view-item.interface';

@Pipe({
    name: 'collectionViewMediaPipe'
})
export class CollectionViewMediaPipe implements PipeTransform {

    public transform(item: ICollectionViewItem): ICardMediaType {
       return item.getThumbnailMedia();
    }
}
