import {autoserialize, autoserializeAs} from 'cerialize';
import {AssetModel} from './asset.model';
import {ChangeLogValueSerializer} from '../../classes/changelog-value-serializer';

export class ChangedEntryModel {
    @autoserialize public rin: string;
    @autoserializeAs(AssetModel) public asset: AssetModel;
}

export class ChangeModel {
    @autoserialize public name: string;
    @autoserializeAs(new ChangeLogValueSerializer()) public oldValue: any;
    @autoserializeAs(new ChangeLogValueSerializer()) public newValue: any;

    @autoserialize public variantKey: string;
    @autoserializeAs(ChangedEntryModel) public added: ChangedEntryModel[];
    @autoserializeAs(ChangedEntryModel) public removed: ChangedEntryModel[];

    get showValue(): boolean {
        return typeof this.oldValue !== 'undefined' || typeof this.newValue !== 'undefined';
    }
}

export class ChangelogModel {
    @autoserialize public item: string;
    @autoserializeAs(ChangeModel) public changes: ChangeModel[];
    @autoserialize public userFullName: string;
    @autoserialize public createdAt: string;
    public autoGenerated: boolean;
}
