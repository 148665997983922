import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {ChangelogModel} from '../../models/api/changelog.model';
import {ChangelogService} from '../../api/services/changelog.service';
import {Toaster} from '../../classes/toaster.class';
import {ARPagedResponseDataModel} from '@relayter/core';
import {RLDatePipe} from '../../pipes/rl-date.pipe';
import {finalize, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {EChangeLogCollectionName} from '../../app.enums';
import {NullUndefinedPipe} from '../../pipes/null-undefined.pipe';
import {CampaignItemModel} from '../../models/api/campaign-item.model';

@Component({
    selector: 'campaign-item-changelog',
    templateUrl: './campaign-item-changelog.component.html',
    styleUrls: ['./campaign-item-changelog.component.scss']
})
export class CampaignItemChangelogComponent implements OnChanges, OnDestroy {

    @Input() public campaignItem: CampaignItemModel;
    @Input() public showTimeLine: boolean = false;

    public changelogs: ChangelogModel[];
    public loading = false;
    public dateFormats = RLDatePipe.dateFormats;
    private onDestroySubject = new Subject<void>();
    public readonly DELETED_ASSET = NullUndefinedPipe.defaultValues.DELETED_ASSET;

    /**
     * @constructor
     * @param {ChangelogService} changelogService
     */
    constructor(private changelogService: ChangelogService) {
    }

    /**
     * Lifecycle method called on changes
     * Gets the changelog for the campaignItemId
     */
    public ngOnChanges(): void {
        if (!this.campaignItem?._id) {
            this.changelogs = [];
            return;
        }

        this.loading = true;
        this.changelogService.getChangelog(this.campaignItem._id, EChangeLogCollectionName.CAMPAIGN_ITEM, 10)
            .pipe(
                finalize(() => this.loading = false),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                next: (result: ARPagedResponseDataModel<ChangelogModel>) => {
                    this.changelogs = result.items;
                    if (this.campaignItem) {
                        const createdChangLog = new ChangelogModel();
                        createdChangLog.changes = [];
                        createdChangLog.userFullName = 'Briefing item created';
                        createdChangLog.createdAt = this.campaignItem.createdAt;
                        createdChangLog.autoGenerated = true;
                        this.changelogs.push(createdChangLog);
                    }
                },
                error: Toaster.handleApiWarning
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }
}
