import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PackageModel} from '../../../../../../../models/api/package.model';

@Injectable()
export class AssignToPackageService implements OnDestroy {
    private campaignPackage = new BehaviorSubject<PackageModel>(null);
    public campaignPackage$ = this.campaignPackage.asObservable();

    private subjects = [
        this.campaignPackage
    ];

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
    }

    public setCampaignPackage(campaignPackage: PackageModel): void {
        this.campaignPackage.next(campaignPackage);
    }

}
