import {Injectable} from '@angular/core';
import {ITableItem, TableDataProvider} from '@relayter/rubber-duck';
import {NewCursorArray} from '../api/new-api-cursor';
import {TableSortOptions} from '../api/table-sort-options';

@Injectable()
export class RelayterTableDataProvider<T extends ITableItem> extends TableDataProvider<T> {
    protected apiCursor: NewCursorArray;

    constructor(public sortOptions: TableSortOptions) {
        super();

        // Init api cursor
        this.apiCursor = new NewCursorArray(1, sortOptions);
    }
    public resetCursorArray(pageIndex = this.pageIndex): void {
        this.apiCursor.reset(pageIndex, this.sortOptions);
    }

    protected setCursor(): void {
        this.apiCursor.setCursor(this.pageIndex, this.data[this.data.length - 1]);
    }
}
