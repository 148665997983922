import {autoserialize, autoserializeAs} from 'cerialize';
import {AttachmentModel} from './attachment.model';
import {UserModel} from './user.model';

export class StickyNoteCommentModel {
    @autoserialize public _id: string;
    @autoserializeAs(UserModel) public createdBy: UserModel;
    @autoserialize public message: string;
    @autoserialize public createdAt: string;
    @autoserialize public updatedAt: string;
    @autoserialize public attachments: AttachmentModel[];
}
