import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {DataFieldModel, DataFieldPatchModel} from '../../models/api/data-field.model';
import {tap} from 'rxjs/operators';
import {Serialize} from 'cerialize';
import {DataFieldUsageModel} from '../../models/api/data-field-usage.model';
import {JobModel} from '../../models/api/job.model';
import {MonitoredJobsService} from './monitored-jobs.service';
import {EExportType} from '../../app.enums';
import {SortDirection} from '@angular/material/sort';
import {EColumnDataType} from '@relayter/rubber-duck';

export interface IDataFieldFilterOptions {
    campaignId?: string;
}

export enum EDataFieldJobTypes {
    DELETE_DATA_FIELD_JOB = 'DELETE_DATA_FIELD_JOB',
}

export interface IDeleteDataFieldJobData {
    dataFieldId: string;
}

export interface IRenameDataFieldNameJobData {
    dataFieldId: string;
    newDataFieldName: string;
}

export interface IExportDataFieldCollectionJobData {
    exportType: EExportType,
    variantKey: string,
    sort: string[],
    sortType: SortDirection,
    cursorDuplicates: boolean,
    dataTypes: EColumnDataType[],
    contextId?: string
}

export type IDataFieldJobData = IDeleteDataFieldJobData | IRenameDataFieldNameJobData;

@Injectable({
    providedIn: 'root'
})
export class DataFieldsService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    public postDataField(dataField: DataFieldModel): Observable<DataFieldModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_DATA_FIELDS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = Serialize(dataField, DataFieldModel);
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, DataFieldModel);
        });
    }

    public patchDataField(dataFieldId: string, dataField: DataFieldPatchModel): Observable<DataFieldModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_DATA_FIELDS,
            dataFieldId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = Serialize(dataField, DataFieldPatchModel);
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, DataFieldModel);
        });
    }

    public getDataField(dataFieldId: string): Observable<DataFieldModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_DATA_FIELDS,
            dataFieldId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, DataFieldModel);
        });
    }

    public getDataFieldUsage(dataFieldId: string): Observable<DataFieldUsageModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_DATA_FIELDS, dataFieldId, ApiConstants.API_METHOD_USAGE]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable<DataFieldUsageModel>((obs) => {
            this.handleDetailResponse(options, obs, DataFieldUsageModel);
        });
    }

    public postJob(jobType: string, jobData: IDataFieldJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_DATA_FIELDS,
            ApiConstants.API_METHOD_JOBS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addJobToMonitor(job))
        );
    }

    public updateOrder(dataFieldId: string, direction: string): Observable<DataFieldModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_DATA_FIELDS,
            dataFieldId,
            ApiConstants.API_METHOD_ORDER
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = {direction};
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, DataFieldModel);
        });
    }

}
