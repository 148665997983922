<nuc-tab-bar [tabs]="tabs" [(activeTab)]="activeTab" (activeTabChange)="$event"/>
<hr class="full-width-tab-hr"/>
<ng-container [ngSwitch]="activeTab.index">
    <ng-container *ngSwitchCase="0">
        <multi-item-selection-new-component *ngIf="columns"
                                            [tableDataProvider]="masterBriefingDataProvider"
                                            [selectionMode]="ESelectionMode.MULTI_ALL"
                                            [tableId]="tableId"
                                            [columns]="columns"
                                            (tableSortOptionsChange)="onTableSortOptionsChange()"
                                            [noResultsTitle]="(masterBriefingDataProvider.searching) ? 'No item matches your search criteria' : 'There are no items available to import'"
                                            [actions]="[]"
                                            selectionTitle="items">

            <ng-container button-bar-left>
                <nuc-suggestive-input
                    [options]="autocompleteValues"
                    prefix="nucicon_search"
                    [enableNewValue]="false"
                    (searchTermChange)="onSearchTermChanged($event)"
                    (selectionChanged)="onSearchSelectionChanged($event)"
                    placeholder="Search briefing">
                </nuc-suggestive-input>
                <rl-data-filter [addDataFieldsContext]="filterContext" [requestOptions]="requestOptions"
                                [variantKey]="selectedVariant?.getValue()">
                </rl-data-filter>
            </ng-container>

            <ng-container button-bar-left-after>
                <nuc-dropdown *ngIf="variantEnabled"
                              icon="nucicon_variant"
                              class="variant variant-highlight"
                              placeholder="Select variant"
                              [nullOption]=false
                              [data]="selectedVariant"
                              (dataChange)="selectVariant($event)"
                              [items]="variants">
                </nuc-dropdown>
            </ng-container>

        </multi-item-selection-new-component>
    </ng-container>
    <div *ngSwitchCase="1" class="import-option-container">

        <div class="section-header">Data field options</div>
        <p>Add specific data fields with custom values to either replace or merge existing values in your campaign
            briefing</p>

        <div class="options-forms">
            <data-field-option-form *ngFor="let optionForm of dataFieldCopyOptionsForm.controls; let i = index"
                                    [optionForm]="optionForm"
                                    [allDataFields]="dataFields"
                                    (onDeleteOptionClicked)="deleteOption($event)"
                                    [index]="i"></data-field-option-form>
            <nuc-button-bar>
                <nuc-button-secondary text="Add field" icon="nucicon_add" (click)="addOption()"
                                      iconPosition="end"></nuc-button-secondary>
            </nuc-button-bar>
        </div>
        <nuc-empty-state-component *ngIf="!dataFieldCopyOptionsForm.value.length" small="true"
                                   imageUrl="assets/images/empty_states/default.svg"
                                   title="This section is empty."
                                   subtitle="Add the first field."></nuc-empty-state-component>
    </div>
</ng-container>
