import {autoserialize, autoserializeAs, deserializeAs, serialize, serializeAs} from 'cerialize';
import {SizeModel} from './size.model';
import {PointModel} from './point.model';
import {TemplateMarginsModel} from './template-margins.model';
import {TemplateTypeModel} from './template-type.model';
import {IdSerializer} from '../../classes/id-serializer';
import {TemplateAreaModel} from './template-area.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {MasterPageModel} from './master-page.model';
import {ITableItem} from '@relayter/rubber-duck';
import {TemplateVariantPresetModel} from './template-variant-preset.model';
import {DesignLibraryModel} from '../../modules/static-content-rulesets/models/api/design-library.model';
import {StaticContentRulesetModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {EChannel} from '../../app.enums';
import {FormGroup} from '@angular/forms';
import {ITemplateForm} from '../../pages/relayter/templates/template-detail/template-detail.component';

export enum EEngineType {
    INDESIGN = 'INDESIGN',
    SVG = 'SVG'
}

export enum EPageType {
    Single = 'Single',
    Spread = 'Spread'
}

export class TemplateConstants {
    static readonly DEFAULT_WIDTH = 210;
    static readonly DEFAULT_HEIGHT = 297;
    static readonly DEFAULT_MARGIN = 10;
}

export class TemplateModel implements ITableItem, IDropdownItem {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @deserializeAs(TemplateTypeModel)
    @serializeAs(new IdSerializer())
    public templateType: TemplateTypeModel;
    @autoserialize public tags: string[];
    @autoserialize public engineType: EEngineType;
    @autoserialize public channel: EChannel;

    @autoserialize public numberOfPages: number;
    @autoserializeAs(SizeModel) public pageSize: SizeModel = new SizeModel();
    @autoserializeAs(TemplateAreaModel) public areas: TemplateAreaModel[] = [];
    @autoserializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;

    // not populated values
    @autoserialize public masterPage: string;
    @autoserialize public designLibrary: string;
    @autoserialize public ruleset: string;

    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserialize public itemsGenerated: number;

    getTitle(): string {
        return this.name;
    }

    getValue(): string {
        return this._id;
    }

    static defaultPageSize(): SizeModel {
        return new SizeModel(TemplateConstants.DEFAULT_WIDTH, TemplateConstants.DEFAULT_HEIGHT);
    }
}

export class TemplateDetailModel {
    @autoserialize public _id: string;
    @autoserialize public name: string;
    @autoserialize public tags: string[];
    @deserializeAs(TemplateTypeModel)
    @serializeAs(new IdSerializer())
    public templateType: TemplateTypeModel;
    @autoserialize public engineType: EEngineType;
    @autoserialize public channel: EChannel;

    @autoserialize public numberOfPages: number;
    @autoserializeAs(SizeModel) public pageSize: SizeModel = new SizeModel();
    @autoserializeAs(TemplateAreaModel) public areas: TemplateAreaModel[] = [];
    @autoserializeAs(TemplateMarginsModel) public margins: TemplateMarginsModel;

    @autoserializeAs(Date) public createdAt: Date;
    @autoserializeAs(Date) public updatedAt: Date;
    @autoserialize public itemsGenerated: number;

    // difference between TemplateDetailModel and TemplateModel
    @autoserializeAs(MasterPageModel) public masterPage: MasterPageModel;
    @autoserializeAs(DesignLibraryModel) public designLibrary: DesignLibraryModel;
    @autoserializeAs(StaticContentRulesetModel) public ruleset: StaticContentRulesetModel;
    @autoserializeAs(TemplateVariantPresetModel) variantPresets: TemplateVariantPresetModel[];

    public static defaultTemplate(): TemplateDetailModel {
        const template = new TemplateDetailModel();
        template.engineType = EEngineType.INDESIGN;
        template.channel = EChannel.PRINT;
        template.pageSize = new SizeModel(TemplateConstants.DEFAULT_WIDTH, TemplateConstants.DEFAULT_HEIGHT);
        template.margins = new TemplateMarginsModel(TemplateConstants.DEFAULT_MARGIN, TemplateConstants.DEFAULT_MARGIN,
            TemplateConstants.DEFAULT_MARGIN, TemplateConstants.DEFAULT_MARGIN);
        template.numberOfPages = 1;
        template.areas = [new TemplateAreaModel(
            new PointModel(TemplateConstants.DEFAULT_MARGIN, TemplateConstants.DEFAULT_MARGIN),
            new SizeModel(
                TemplateConstants.DEFAULT_WIDTH - 2 * TemplateConstants.DEFAULT_MARGIN,
                TemplateConstants.DEFAULT_HEIGHT - 2 * TemplateConstants.DEFAULT_MARGIN),
            1,
            1,
            0,
            0,
            undefined
        )];
        template.variantPresets = [];
        return template;
    }
}

export class TemplateVariantPresetBodyModel {
    @serialize public variant: string;
    @serialize public masterPage: string;
    @serialize public designLibrary: string;
    @serialize public ruleset: string;
}

export class TemplateBodyModel {
    @serialize public name: string;
    @serialize public tags: string[];
    @serialize public templateType: string;
    @serialize public engineType: EEngineType;
    @serialize public channel: EChannel;

    @serialize public numberOfPages: number;
    @serialize public pageSize: SizeModel;
    @serialize public areas: TemplateAreaModel[];
    @serialize public margins: TemplateMarginsModel;

    @serialize public masterPage: string;
    @serialize public designLibrary: string;
    @serialize public ruleset: string;

    @serialize public variantPresets: TemplateVariantPresetBodyModel[];

    public static fromTemplateFormGroup(formGroup: FormGroup<ITemplateForm>): TemplateBodyModel {
        const formValue = formGroup.getRawValue();
        const body = new TemplateBodyModel();
        const preset = formValue.preset;

        body.name = preset.name;
        body.tags = preset.tags?.map((v) => v.getValue());
        body.templateType = preset.templateType?.length > 0 ? preset.templateType[0].getValue() : null;
        body.engineType = preset.engineType.getValue() as EEngineType;
        body.channel = preset.channel.getValue() as EChannel;
        body.numberOfPages = preset.numberOfPages.getValue();
        if (body.engineType === EEngineType.INDESIGN) {
            body.masterPage = preset.masterPage?.getValue();
        }
        body.designLibrary = preset.designLibrary.getValue();
        body.ruleset = preset.ruleset.getValue();

        const size = formValue.size;
        body.pageSize = new SizeModel(size.width, size.height);
        body.margins = new TemplateMarginsModel(size.margins.marginTop, size.margins.marginBottom, size.margins.marginStart, size.margins.marginEnd);

        body.areas = formValue.areas.map((area) => {
            if (!area._id) delete area._id;
            return area as TemplateAreaModel;
        });

        body.variantPresets = formValue.variantPresets.map((variantPreset) => {
            const variantPresetBody = new TemplateVariantPresetBodyModel();
            variantPresetBody.variant = variantPreset.variant._id;
            if (body.engineType === EEngineType.INDESIGN) { // only allow master page for indesign
                variantPresetBody.masterPage = variantPreset.masterPage?.getValue();
            }
            variantPresetBody.designLibrary = variantPreset.designLibrary.getValue();
            variantPresetBody.ruleset = variantPreset.ruleset.getValue();
            return variantPresetBody;
        });

        return body;
    }

}
