import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {EBooleanText, EDataFieldCollectionName, EDataFieldTypes} from '../../app.enums';
import {FormControl, FormGroup} from '@angular/forms';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Toaster} from '../../classes/toaster.class';
import {IDataFieldFilterOptions} from '../../api/services/data-fields.service';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {OverlayButtonComponent} from '../overlay-button/overlay-button.component';
import {ActivatedRoute} from '@angular/router';
import {DataFieldModel} from '../../models/api/data-field.model';
import {DataFilterModel, EDataFilterModels} from '../../models/ui/data-filter.model';
import {DataFieldFilterModel} from '../../models/ui/data-field-filter.model';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';

@Component({
    selector: 'rl-data-filter',
    templateUrl: './data-filter.component.html',
    styleUrls: ['./data-filter.component.scss']
})
export class DataFilterComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild(OverlayButtonComponent) public overlayButton: OverlayButtonComponent;

    public activeFilterCount: number = 0;
    public FILTER_DATA_TYPES = {...EDataFieldTypes};
    public EDataFilterModels = {...EDataFilterModels};
    public filtersForm: FormGroup = new FormGroup({});
    public booleanDropdownItems = [
        new DropdownItem(EBooleanText.TRUE, 'true'),
        new DropdownItem(EBooleanText.FALSE, 'false'),
        new DropdownItem(EBooleanText.EMPTY, 'undefined')
    ];

    @Input() public filters: DataFilterModel[] = [];
    @Input() public addDataFieldsContext: EDataFieldCollectionName;
    @Input() public requestOptions: IDataFieldFilterOptions = {};
    @Input() public variantKey: string;

    protected onDestroySubject = new Subject<void>();
    private filterValues: IDataFieldFilterOptions = {};

    private dataFields: DataFieldModel[];
    public dataFieldData: Record<string, DropdownItem<string>[]> = {};

    constructor(public dataFieldsService: DataFieldsApiService,
                public advancedFiltersDataService: AdvancedFiltersDataService,
                protected route: ActivatedRoute) {
    }

    /**
     * Checks if value is null, undefined, empty string or an empty array.
     * @private
     */
    private valueIsEmpty(value: any) {
        return (value === undefined || value === null ||
            (typeof value === 'string' && value.trim().length === 0) || (Array.isArray(value) && value.length === 0));
    }

    public ngOnInit(): void {
        // Create inputs
        if (this.addDataFieldsContext) {
            // get dataFields to create inputs
            this.dataFieldsService.getAllDataFields(this.addDataFieldsContext)
                .pipe(take(1), takeUntil(this.onDestroySubject))
                .subscribe({
                    next: dataFields => {
                        this.dataFields = dataFields;
                        this.createFilterInputs();
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.createFilterInputs();
        }
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.variantKey && !changes.variantKey.firstChange && this.dataFields) {
            this.createFilterInputs();
        }
    }

    public createFilterInputs(): void {
        this.activeFilterCount = 0;
        this.filterValues = {};

        // add data fields
        if (this.dataFields?.length > 0) {
            const filters = this.dataFields.filter(dataField => dataField.showInFilter).map((dataField) => {
                const items = dataField.dataType.enumeration?.items.map(item => new DropdownItem(item, item));
                const propertyPath = dataField.enableVariants && this.variantKey ? `dataFields.${dataField.fieldName}.${this.variantKey}` :
                    `dataFields.${dataField.fieldName}`;
                return new DataFieldFilterModel(dataField.name, propertyPath, dataField.dataType.type,
                    items, dataField._id, dataField.dataType.enumeration, this.requestOptions, dataField.enableAutocomplete);
            });

            // Preserve existing filters, add new, remove deleted
            this.filters = this.filters.filter(filter => {
                return filter.modelName === EDataFilterModels.DATA_FILTER ||
                    filters.find(newFilter => newFilter.property === filter.property);
            });
            this.filters.push(...filters.filter(filter => !this.filters.find(oldFilter => oldFilter.property === filter.property)));
        }

        const oldFormValue = this.filtersForm?.value || {};

        // add form and form controls, convert and set value
        this.filtersForm = new FormGroup({});
        for (const dataFilter of this.filters) {

            // get the value from the url params if present
            const value = this.route.snapshot.params[dataFilter.property] || oldFormValue[dataFilter.property];

            if (!this.valueIsEmpty(value)) {
                this.activeFilterCount += 1;

                // assign value to the filter
                this.filterValues[dataFilter.property] = value;
                // value used in the formControl
                let formControlValue = value;

                if (dataFilter.type === this.FILTER_DATA_TYPES.LIST ||
                    (dataFilter.type === this.FILTER_DATA_TYPES.ENUM && dataFilter.enumeration?.multiSelect)
                ) {
                    // handling array
                    this.filterValues[dataFilter.property] = value.split(',');
                    formControlValue = value.split(',').map(v => new DropdownItem(v, v));

                } else if (dataFilter.type === this.FILTER_DATA_TYPES.ENUM) {
                    formControlValue = new DropdownItem(value, value);

                } else if (dataFilter.type === this.FILTER_DATA_TYPES.BOOLEAN) {
                    formControlValue = this.booleanDropdownItems.find((item) => item.getValue() === value);
                }
                this.filtersForm.addControl(dataFilter.property, new FormControl(formControlValue));
            } else {
                this.filtersForm.addControl(dataFilter.property, new FormControl());
            }
        }

        // when initialized and values are retrieved from url params always call setFilterValues
        // so listeners can get data.
        this.advancedFiltersDataService.setFilterValues(this.filterValues);
    }

    public onApply(): void {
        this.filtersForm.markAsPristine();

        const formValues = this.filtersForm.value;

        this.activeFilterCount = 0;
        const filterValues = {};
        Object.keys(formValues).forEach((key) => {
            // Skip the null, undefined, empty (trimmed) values & empty arrays
            if (this.valueIsEmpty(formValues[key])) {
                return;
            }

            filterValues[key] = formValues[key];

            // trim string values
            if (typeof formValues[key] === 'string') {
                filterValues[key] = formValues[key].trim();
            }

            // convert DropdownItem to string
            if (formValues[key] instanceof DropdownItem) {
                filterValues[key] = formValues[key].getValue();
            }

            // convert array of DropDownItem to string array (dropdown multiselect)
            // and update filter count
            if (Array.isArray(formValues[key])) {
                this.activeFilterCount += formValues[key].length;
                // Flatten all values of the array values, for example filter on assigned pages (filter is an array of campaign items values)
                filterValues[key] = formValues[key].reduce((flatValues, value) => {
                    value = (value instanceof DropdownItem) ? value.getValue() : value;
                    Array.isArray(value) ? flatValues.push(...value) : flatValues.push(value);
                    return flatValues;
                }, []);
            } else {
                this.activeFilterCount++;
            }

            // Convert Date to ISO format
            if (formValues[key] instanceof Date) {
                filterValues[key] = formValues[key].toISOString();
            }
        });

        // update the values in AdvancedFiltersDataService so listeners get updates
        this.advancedFiltersDataService.setFilterValues(filterValues);

        this.overlayButton.detachOverlay();
    }

    // On reset, also call onApply() to trigger the emission of the empty form
    public onReset(): void {
        this.filtersForm.reset();
        this.onApply();
    }

    public search(searchValue: string, dataFilter: DataFilterModel): void {
        const dataFieldId = (dataFilter as DataFieldFilterModel).dataFieldId;
        if (!searchValue || searchValue.trim() === '') {
            this.dataFieldData[dataFieldId] = [];
            return;
        }

        // Get dataField values
        this.dataFieldsService.getDataFieldValues(dataFieldId, this.requestOptions, 100, 0, searchValue,
            this.variantKey ? this.variantKey : null)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => {
                    this.dataFieldData[dataFieldId] = result.items.map((item) => {
                        return new DropdownItem<string>(item.displayName, item.displayName);
                    })
                },
                error: (error) => {
                    Toaster.error(error);
                }
            })
    }
}
