import {Component, computed, DestroyRef, ElementRef, inject, OnInit, signal, ViewChild, WritableSignal} from '@angular/core';
import {PublicationModel} from '../../../../../../models/api/publication.model';
import {CustomWorkflowStepModel} from '../../../../../../models/api/custom-workflow-step.model';
import {PublicationsService} from '../../../../../../api/services/publications.service';
import {Toaster} from '../../../../../../classes/toaster.class';
import {PublicationItemModel} from '../../../../../../models/api/publication-item.model';
import {BehaviorSubject, combineLatest, forkJoin, interval, of, Subscription} from 'rxjs';
import {CustomWorkflowService} from '../custom-workflow.service';
import {EPublicationType} from '../../../../templates/template-detail/publication-type.enum';
import {delayWhen, distinctUntilChanged, filter, finalize, switchMap} from 'rxjs/operators';
import {EPublicationDisplayProperties, PublicationItemDisplayPipe} from '../../../../../../pipes/publication-item-display.pipe';
import {EPublicationItemSortProperty, PublicationItemSelectionService, UpdatedWorkflowLayoutItemData} from './publication-item-selection.service';
import {CustomWorkflowFilterModel} from '../../../../../../models/api/custom-workflow-filter.model';
import {CustomWorkflowFilterOptionModel} from '../../../../../../models/api/custom-workflow-filter-option.model';
import {AppConstants} from '../../../../../../app.constants';
import {ScrollItemIntoViewDirective} from '../../../../../../directives/scroll-item-into-view.directive';
import {DropdownItem} from '../../../../../../models/ui/dropdown-item.model';
import {Cursor} from '../../../../../../api/api-cursor';
import {ETransitionStatus} from '../../../../../../models/api/transition-item.model';
import {WorkflowConfigurationModel} from '../../../../../../models/api/workflow-configuration.model';
import {LayoutModel} from '../../../../../../models/api/layout.model';
import {WorkflowLayoutItem} from '../../../../../../models/api/custom-workflow/workflow-layout-item.model';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {EPublicationMediaProperties, PublicationItemMediaPipe} from '../../../../../../pipes/publication-item-media.pipe';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SortDirection} from '@angular/material/sort';
import {EKeyCodes} from '../../../../../../app.enums';

export enum EItemSelectionType {
    ITEM_SELECTION,
    LAYOUT_SELECTION
}

enum EIndicatorStatus {
    LOADING,
    DONE
}

export class ItemSelectionState {
    constructor(public publicationItems: PublicationItemModel[] = [],
                public itemSelectionType: EItemSelectionType = EItemSelectionType.ITEM_SELECTION,
                public hasNext = false,
                public cursor: Cursor = null,
                public filters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]> = null,
                public selectedItem: PublicationItemModel = null) {
    }
}

@Component({
    selector: 'rl-custom-workflow-item-selection-component',
    templateUrl: './custom-workflow-item-selection.component.html',
    styleUrls: ['./custom-workflow-item-selection.component.scss'],
    providers: [PublicationItemDisplayPipe, PublicationItemMediaPipe]
})
export class CustomWorkflowItemSelectionComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    public EIndicatorStatus = EIndicatorStatus;
    public itemSelectionType: EItemSelectionType = EItemSelectionType.ITEM_SELECTION;

    private redirectPublicationItemId: string;

    private publicationItemSubscription: Subscription;
    private publicationItems: PublicationItemModel[] = [];
    public workflowLayoutItems: WorkflowLayoutItem[] = [];
    private workflowLayout: LayoutModel;
    private readonly defaultWorkflowLayout = LayoutModel.getDefaultLayout();

    public sortOptions: DropdownItem<string>[] = [];
    public hasNext = false;

    public sortValue: WritableSignal<DropdownItem<string>> = signal(null);
    public sortOrder: WritableSignal<SortDirection> = signal(null);

    public sortProperty = computed(() => this.sortValue()?.getValue());
    public sortAsc = computed(() => this.sortOrder() === 'asc');

    private activeVariant: VariantModel;

    private indicatorStatusSubject = new BehaviorSubject<EIndicatorStatus>(EIndicatorStatus.DONE);
    // WebStorm thinks this delayWhen is deprecated, but its mistaken here, so suppress inspection for next line
    // noinspection JSDeprecatedSymbols
    public indicatorStatus$ = this.indicatorStatusSubject.pipe(
        delayWhen((value) => value === EIndicatorStatus.DONE ? interval(500) : interval(0)));

    private step: CustomWorkflowStepModel;
    private publication: PublicationModel;
    private workflow: WorkflowConfigurationModel;
    private filters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>;
    private cursor: Cursor;
    private selectItemAfterFilterChange = false;

    @ViewChild(ScrollItemIntoViewDirective) private scrollContainer: ScrollItemIntoViewDirective;
    @ViewChild('sidebar') private sidebarContainer: ElementRef;

    private selectNext = false;

    constructor(protected publicationService: PublicationsService,
                protected customWorkflowService: CustomWorkflowService,
                public publicationItemSelectionService: PublicationItemSelectionService,
                protected publicationItemDisplayPipe: PublicationItemDisplayPipe,
                private publicationItemMediaPipe: PublicationItemMediaPipe) {
    }

    public ngOnInit(): void {
        this.redirectPublicationItemId = this.publicationItemSelectionService.getRedirectPublicationItemId();

        combineLatest([
            this.customWorkflowService.publication$,
            this.customWorkflowService.workflow$
        ]).pipe(
            switchMap(([publication, workflow]) => {
                this.publication = publication;
                this.workflow = workflow;

                this.sortOptions = (this.publication.channel.name === EPublicationType.PRINT_MAGAZINE
                    ? [new DropdownItem('Page number', EPublicationItemSortProperty.FIRST_PAGE_NUMBER)]
                    : [new DropdownItem('Filename', EPublicationItemSortProperty.PUBLICATION_ITEM_ID)]
                ).concat([new DropdownItem('Date created', EPublicationItemSortProperty.ID)]);

                return combineLatest([
                    this.customWorkflowService.activeStep$,
                    this.customWorkflowService.activeFilters$,
                ]).pipe(takeUntilDestroyed(this.destroyRef));
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(([step, filters]) => {
            // Restore items when loaded for first time, or the same step. Rebuild workflow layouts and create api cursor
            this.restoreItemSelectionState();
            if (!this.sortOrder() || !this.sortValue()) { // for the first time loading
                const storedSort = this.publicationItemSelectionService.getStoredSort(this.publication.channel.name);
                this.sortOrder.set(storedSort.sortOrder);
                const sortValue = this.sortOptions.find((option) => option.getValue() === storedSort.sortProperty)
                this.sortValue.set(sortValue);
            }

            this.step = step;

            if (this.filters !== filters) {
                this.filters = filters;
                this.selectItemAfterFilterChange = true;
                this.resetWorkflowLayoutItems(this.publicationItemSelectionService.getSelectedWorkflowLayoutItem());
            } else if (this.publicationItems?.length > 0) {
                this.scrollSelectedItemToCenter();
            } else {
                this.getPublicationItems();
            }
        });

        this.publicationItemSelectionService.updatedWorkflowLayoutItem$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((updatedWorkflowLayoutItemData: UpdatedWorkflowLayoutItemData) => {
            const oldIndex = updatedWorkflowLayoutItemData.workflowLayoutItem?.index || -1;
            if (updatedWorkflowLayoutItemData.transitionItemId) {
                updatedWorkflowLayoutItemData.workflowLayoutItem?.publicationItems.forEach(item => {
                    item.transitionItemId = updatedWorkflowLayoutItemData.transitionItemId
                });
            }

            // Items changed, so recreate workflow layout and select next or last item
            this.createPublicationItemsInLayout(this.workflowLayout);
            const lastIndex = this.getLastWorkflowLayoutItemIndex();
            const newIndex = lastIndex > oldIndex ? oldIndex : lastIndex;
            this.setSelectedWorkflowLayoutItem(this.workflowLayoutItems[newIndex !== -1 ? newIndex : 0]);
        });

        this.publicationItemSelectionService.onNextClicked$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => {
            const selectedItem = this.workflowLayoutItems[this.getIndexSelectedItem()];
            const itemsLength = !!selectedItem ? selectedItem.publicationItems.length : 0;
            if (!!selectedItem && itemsLength > 0 &&
                selectedItem.publicationItems[itemsLength - 1][this.sortProperty()] === this.cursor._id && this.hasNext) {
                this.selectNext = true;
                this.getPublicationItems();
            } else { // keep the existing behavior
                // Find first publication item after selected one, not in transition
                const currentIndex = this.getIndexSelectedItem();
                const nextIndex = this.getNextWorkflowLayoutIndex(currentIndex);
                this.scrollIndexToCenter(nextIndex);
                this.setSelectedWorkflowLayoutItem(this.workflowLayoutItems[nextIndex]);
            }
        });

        this.publicationItemSelectionService.onBackClicked$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => {
            // Find first publication item before selected one, not in transition
            const currentIndex = this.getIndexSelectedItem();
            const previousIndex = this.getPreviousWorkflowLayoutItemIndex(currentIndex);
            this.scrollIndexToCenter(previousIndex);
            this.setSelectedWorkflowLayoutItem(this.workflowLayoutItems[previousIndex]);
        });

        this.publicationItemSelectionService.onWorkflowLayoutItemsChanged$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.resetWorkflowLayoutItems());

        this.publicationItemSelectionService.onNavigateToPublicationItem$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((publicationItemId: string) => {
            // Check if item already loaded in a workflow layout item
            const workflowLayoutItem = this.workflowLayoutItems.find(item =>
                item.publicationItems.map(pubItem => pubItem._id).includes(publicationItemId));

            // When available, scroll workflow layout item to the center
            if (workflowLayoutItem) {
                this.setSelectedWorkflowLayoutItem(workflowLayoutItem, true);
            } else if (this.hasNext) { // If there are more items, get more publication items
                this.setSelectedWorkflowLayoutItem(null);
                this.redirectPublicationItemId = publicationItemId;
                this.getPublicationItems();
            }
        });

        this.customWorkflowService.transitionItemUpdate$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((transitionItem) => {
                // Failed items in current step no longer in transition
                const transition = this.workflow.transitions.find(item => item._id === transitionItem.transition);
                if (this.step._id === transition?.from) {
                    // For self transitions, like remove files, update publication items after transition finished successfully
                    const isSelfTransition = transition.from === transition.to;
                    if (transitionItem.status === ETransitionStatus.FAILED ||
                        (isSelfTransition && transitionItem.status === ETransitionStatus.DONE)) {
                        if (this.workflowLayoutItems.length === 0) {
                            // After a transition of all items, refresh items because currently no items available
                            this.resetWorkflowLayoutItems();
                        } else {
                            // Failed/Done (self transition) items no longer in transition
                            this.publicationItems.filter(item => item.transitionItemId === transitionItem._id)
                                .forEach(pubItem => delete pubItem.transitionItemId);
                            this.createPublicationItemsInLayout(this.workflowLayout);

                            // Update publication items after a DONE self transition
                            if (isSelfTransition && transitionItem.status === ETransitionStatus.DONE) {
                                this.updatePublicationItems(transitionItem.items);
                            }

                            // Set selected workflow layout item to current selected one
                            const selectedWorkflowLayoutItem = this.publicationItemSelectionService.getSelectedWorkflowLayoutItem();
                            if (selectedWorkflowLayoutItem) {
                                // Find first recreated workflow layout item with one of current selected publication items
                                const selectedPubItems = selectedWorkflowLayoutItem.publicationItems.map(item => item._id);
                                const newSelectedWorkflowItem = this.workflowLayoutItems.find(item =>
                                    item.publicationItems.find(pubItem => selectedPubItems.includes(pubItem._id)));
                                this.setSelectedWorkflowLayoutItem(newSelectedWorkflowItem);
                            }
                        }
                    }
                }
            });

        // Remove item from selection, when no longer apply to filters
        this.customWorkflowService.itemActionUpdate$
            .pipe(
                filter((items) => !!items?.length && !!this.filters.size),
                switchMap((items) => {
                    // Clone the filters and do request with selected item ids
                    const activeFilters = new Map(this.filters);
                    activeFilters.set(new CustomWorkflowFilterModel('', '_id'),
                        items.map(item => new CustomWorkflowFilterOptionModel('', item)));
                    // Return results and original items
                    return forkJoin([
                        this.publicationService.getItemsForPublication(this.publication.getItemModel(), this.publication._id,
                            this.step._id, activeFilters),
                        of(items)
                    ]);
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe(([response, items]) => {
                if (this.workflowLayoutItems.length === 0) {
                    this.resetWorkflowLayoutItems();
                } else {
                    const itemIds = response.items.map(item => item._id);

                    // Filter out items no longer in the set
                    const itemsLength = this.publicationItems.length;
                    this.publicationItems = this.publicationItems.filter(item => !items.includes(item._id) || itemIds.includes(item._id));

                    // Items changed, so recreate workflow layout and select next or last item
                    if (itemsLength !== this.publicationItems.length) {
                        const oldIndex = this.publicationItemSelectionService.getSelectedWorkflowLayoutItem()?.index || 0;
                        this.createPublicationItemsInLayout(this.workflowLayout);
                        const lastIndex = this.getLastWorkflowLayoutItemIndex();
                        const newIndex = lastIndex > oldIndex ? oldIndex : lastIndex;
                        this.setSelectedWorkflowLayoutItem(this.workflowLayoutItems[newIndex !== -1 ? newIndex : 0]);
                    }
                }
            });

        // Only the first time, scroll selected workflow layout to center
        this.publicationItemSelectionService.selectedWorkflowLayoutItem$.pipe(
            distinctUntilChanged((prev) => !!prev),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => this.scrollSelectedItemToCenter());

        this.publicationItemSelectionService.itemSelectionType$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((itemSelectionType) => {
                this.itemSelectionType = itemSelectionType;
                this.saveItemSelectionState();
                this.restoreItemSelectionState();
                this.scrollSelectedItemToCenter();
            });

        this.customWorkflowService.activeVariant$.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((variant) => {
            this.activeVariant = variant;
            this.setWorkflowLayout();
        });
    }

    public resetWorkflowLayoutItems(selectedWorkflowLayoutItem?: WorkflowLayoutItem): void {
        this.publicationItems = [];
        this.workflowLayoutItems = [];
        this.setSelectedWorkflowLayoutItem(selectedWorkflowLayoutItem);
        this.cursor = null;
        this.getPublicationItems();
    }

    public getPublicationItems(): void {
        this.indicatorStatusSubject.next(EIndicatorStatus.LOADING);
        if (this.publicationItemSubscription && !this.publicationItemSubscription.closed) {
            // Only send new request, if the previous request is completed.
            return;
        }
        this.publicationItemSubscription = this.publicationService.getItemsForPublication(
            this.publication.getItemModel(),
            this.publication._id,
            this.step._id,
            this.filters,
            AppConstants.PAGE_SIZE_MAX,
            null,
            this.cursor,
            this.sortProperty(),
            this.sortOrder()
        ).pipe(
            finalize(() => {
                this.selectItemAfterFilterChange = false;
                this.indicatorStatusSubject.next(EIndicatorStatus.DONE);
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: (result) => {
                this.hasNext = result.hasNext;
                this.publicationItems = this.publicationItems.concat(result.items);

                this.prepareItems();
                this.saveItemSelectionState();

                const selectedIndex = this.getIndexSelectedItem();
                if (this.selectNext && selectedIndex > -1 && this.workflowLayoutItems[selectedIndex + 1]) {
                    // User click for next page
                    this.setSelectedWorkflowLayoutItem(this.workflowLayoutItems[selectedIndex + 1]);
                    this.saveItemSelectionState();
                    // setTimeout to wait for the next render cycle, if we do not wait the list is not drawn yet,
                    // so you can't scroll
                    this.scrollIndexToCenter(selectedIndex + 1);
                } else if (!this.selectItemAfterFilterChange && this.publicationItemSelectionService.getSelectedWorkflowLayoutItem() &&
                    selectedIndex === -1 && this.hasNext && result.items.length > 0) {
                    // Load next page of items, when the selected item isn't a part of the current list
                    // (only when we have more items and this call is not after a filter change, because item could be filtered out)
                    this.publicationItemSubscription.unsubscribe();
                    this.getPublicationItems();
                    return;
                } else if (this.redirectPublicationItemId && selectedIndex === -1) {
                    // We need to go the redirect publication item, so find, reset and select it
                    const redirectWorkflowLayoutItem = this.workflowLayoutItems.find(item =>
                        item.publicationItems.map(pubItem => pubItem._id).includes(this.redirectPublicationItemId));
                    if (redirectWorkflowLayoutItem) {
                        this.redirectPublicationItemId = null;
                        this.setSelectedWorkflowLayoutItem(redirectWorkflowLayoutItem);
                        this.scrollIndexToCenter(this.getIndexSelectedItem());
                    } else if (this.hasNext && result.items.length > 0) {
                        this.publicationItemSubscription.unsubscribe();
                        this.getPublicationItems();
                    }
                    return;
                }

                this.setSelectedWorkflowLayoutItem(this.workflowLayoutItems[selectedIndex !== -1 ? selectedIndex : 0],
                    this.selectItemAfterFilterChange);
            },
            error: Toaster.handleApiError
        });
    }

    public updatePublicationItems(items: string[]): void {
        this.indicatorStatusSubject.next(EIndicatorStatus.LOADING);

        const filters = new Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>();
        filters.set(new CustomWorkflowFilterModel('_id', '_id', true), items.map(item => new CustomWorkflowFilterOptionModel(item, item)));

        this.publicationItemSubscription = this.publicationService.getItemsForPublication(
            this.publication.getItemModel(),
            this.publication._id,
            this.step._id,
            filters
        ).pipe(
            finalize(() => {
                this.indicatorStatusSubject.next(EIndicatorStatus.DONE);
            }),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: (result) => {
                // replace fetched publication items with fresh data
                for (const pubItem of result.items) {
                    const index = this.publicationItems.findIndex(item => item._id === pubItem._id);
                    if (index === -1) continue;

                    this.publicationItems[index] = pubItem;
                }

                this.prepareItems();
                this.saveItemSelectionState();
            },
            error: Toaster.handleApiError
        });
    }

    private scrollSelectedItemToCenter(): void {
        this.scrollIndexToCenter(this.getIndexSelectedItem());
    }

    private getIndexSelectedItem(): number {
        const selectedWorkflowLayoutItem = this.publicationItemSelectionService.getSelectedWorkflowLayoutItem();
        return this.workflowLayoutItems.findIndex((item) => item.title === selectedWorkflowLayoutItem?.title);
    }

    private scrollIndexToCenter(index: number): void {
        // We can only scroll to center after items are drawn
        setTimeout(() => {
            if (this.scrollContainer) {
                this.scrollContainer.scrollToActiveItem(index);
            }
        });
    }

    public setSelectedWorkflowLayoutItem(workflowLayoutItem: WorkflowLayoutItem, scrollToCenter = false): void {
        this.publicationItemSelectionService.setSelectedWorkflowLayoutItem(workflowLayoutItem, this.workflowLayoutItems, this.hasNext);
        if (scrollToCenter) this.scrollSelectedItemToCenter();
        this.saveItemSelectionState();
    }

    public onSortChanged(sortOption: DropdownItem<string>, sortAsc: boolean): void {
        if (this.sortValue() !== sortOption || this.sortAsc() !== sortAsc) {
            this.sortValue.set(sortOption);
            this.sortOrder.set(sortAsc ? 'asc' : 'desc');

            this.publicationItemSelectionService.storeSort(this.publication.channel.name,
                this.sortValue().getValue() as EPublicationItemSortProperty,
                this.sortOrder()
            );
            this.resetWorkflowLayoutItems();
        }
    }

    /**
     * Get next publication item, not in transition, after the current index
     */
    private getNextWorkflowLayoutIndex(currentIndex: number = -1): number {
        const lastIndex = this.getLastWorkflowLayoutItemIndex();
        return lastIndex > currentIndex ? currentIndex + 1 : lastIndex;
    }

    /**
     * Get previous workflow layout item index, before the current index
     */
    private getPreviousWorkflowLayoutItemIndex(currentIndex: number = -1): number {
        return currentIndex > 0 ? currentIndex - 1 : 0;
    }

    /**
     * Get last workflow layout item index
     */
    private getLastWorkflowLayoutItemIndex(): number {
        return this.workflowLayoutItems.length - 1;
    }

    private createPublicationItemsInLayout(layout: LayoutModel) {
        // Reset workflowLayoutItems
        const workflowLayoutItems = [];
        const availablePubItems = this.publicationItems.filter(item => !item.transitionItemId);

        if (availablePubItems.length === 0) {
            this.workflowLayoutItems.splice(0);
            return;
        }

        let itemIndex = 0;
        let rowIndex = 0;
        for (const [index, layoutItem] of layout.items.entries()) {
            const nextLayout = layout.items[index + 1];
            let repeat = 1; // Default adds 1 page for this layout item
            if (layoutItem.repeat) {
                repeat = 0;
                // Get final layout
                const pagesNeededForFinalLayout = nextLayout ? nextLayout.numberOfHorizontalItems : 0;
                // There could be only 1 repeatable layout, and it will take all the pages,
                // except the pages needed for the final layout item after this repeatable layout item
                let totalPages = 0;
                // Remove files at the end, needed for the final layout, but don't get too many pages
                const pubItemsForLayoutItem = availablePubItems.slice(itemIndex).reverse().filter(file => {
                    totalPages += file.numberOfPages;
                    return (totalPages > pagesNeededForFinalLayout || this.hasNext);
                }).reverse();
                // For the files in this layout item, calculated how many times we need to create a page
                // (we could have a mix of single and spread items)
                let itemsInPage = 0;
                for (const file of pubItemsForLayoutItem) {
                    if (itemsInPage < layoutItem.numberOfHorizontalItems) {
                        itemsInPage += file.numberOfPages;
                    }
                    if (itemsInPage >= layoutItem.numberOfHorizontalItems) {
                        repeat++;
                        itemsInPage = 0;
                    }
                }
                // Add 1, for items left in the last page of this layout item
                repeat += itemsInPage > 0 ? 1 : 0;
            }

            const pubItems = availablePubItems.slice(itemIndex);
            if (pubItems.length > 0) for (let r = 0; r < repeat; r++) {
                const items: PublicationItemModel[] = workflowLayoutItems[rowIndex]?.publicationItems || [];
                // add pages to the right for # of numberOfHorizontalItems, skip item when no pages available or needed for final layout
                for (let i = 0; i < layoutItem.numberOfHorizontalItems; i++) {
                    const numberOfPagesLeft = pubItems.reduce((totalPages, file) => totalPages + file.numberOfPages, 0);
                    const skipItem = (
                        pubItems.length === 0 || // No items left, but still fill up the numberOfHorizontalItems
                        (layoutItem.repeat && // we are repeating a layout
                            !!nextLayout && // there is another layout available
                            numberOfPagesLeft <= nextLayout.numberOfHorizontalItems) // not enough files left to do final layout
                    );
                    // Skip item when needed for next layout item, except when we have more items to come from the API
                    if (!skipItem || this.hasNext) {
                        const pubItem = pubItems.shift();
                        // Add item to the list
                        if (pubItem) {
                            items.push(pubItem);
                        }
                        i += pubItem ? (pubItem.numberOfPages - 1) : 0;
                        itemIndex++;
                    }
                }

                workflowLayoutItems[rowIndex] = {
                    index: rowIndex,
                    title: items.map(item => this.publicationItemDisplayPipe.transform(item, EPublicationDisplayProperties.TITLE))
                        .join('/').replace(new RegExp('\/Page\\s+', 'g'), '/'),
                    media: items.map(item => this.publicationItemMediaPipe.transform(item,
                        EPublicationMediaProperties.THUMBNAIL, this.activeVariant?._id)),
                    images: items.map(item => this.publicationItemDisplayPipe.transform(item,
                        EPublicationDisplayProperties.THUMBNAIL_URL, this.activeVariant?._id)),
                    publicationItems: items
                };
                rowIndex++;
            }
        }

        // Merge items into this.workflowLayoutItems
        let layoutItemIndex = 0;
        for (const item of workflowLayoutItems) {
            // Title is unique for print items (firstPageNumber), or publicationItemId (pos/web)
            if (item.title !== this.workflowLayoutItems[layoutItemIndex]?.title) {
                // Replace/Add item to the current workflow layout items
                this.workflowLayoutItems.splice(layoutItemIndex, 1, item);
            }
            layoutItemIndex++;
        }
        // Delete all after i-th item
        this.workflowLayoutItems.splice(layoutItemIndex);
    }

    private setWorkflowLayout(): void {
        this.workflowLayout = this.defaultWorkflowLayout;
        if (this.itemSelectionType === EItemSelectionType.LAYOUT_SELECTION) {
            this.workflowLayout = this.workflow.layout || this.workflowLayout;
        }

        this.workflowLayoutItems = [];
        this.createPublicationItemsInLayout(this.workflowLayout);
    }

    private saveItemSelectionState(): void {
        const state = new ItemSelectionState(this.publicationItems, this.itemSelectionType, this.hasNext, this.cursor, this.filters);
        const selectedWorkflowLayoutItem = this.publicationItemSelectionService.getSelectedWorkflowLayoutItem();
        state.selectedItem = selectedWorkflowLayoutItem?.publicationItems[0];
        this.publicationItemSelectionService.setItemSelectionState(state);
    }

    private restoreItemSelectionState(): void {
        const state = this.publicationItemSelectionService.getItemSelectionState();
        if (state) {
            this.publicationItems = state.publicationItems;
            this.itemSelectionType = state.itemSelectionType;
            this.hasNext = state.hasNext;
            this.cursor = state.cursor;
            this.filters = state.filters;

            this.prepareItems();

            if (state.selectedItem) {
                const selectedWorkflowLayout = this.workflowLayoutItems.find(item => !!item.publicationItems.find(pubItem =>
                    pubItem._id === state.selectedItem._id));
                this.setSelectedWorkflowLayoutItem(selectedWorkflowLayout);
            }
        }
    }

    private prepareItems(): void {
        this.setWorkflowLayout();

        const lastWorkflowLayoutItem = this.workflowLayoutItems[this.workflowLayoutItems.length - 1];
        const lastPublicationItem = lastWorkflowLayoutItem ?
            lastWorkflowLayoutItem.publicationItems[lastWorkflowLayoutItem.publicationItems.length - 1] : null;
        this.cursor = new Cursor(lastPublicationItem ? lastPublicationItem[this.sortProperty()] : null);
    }

    /**
     * Need to set focus on the items container to receive (keyboard) events
     */
    public focus(): void {
        this.sidebarContainer.nativeElement.focus();
    }

    public onKeyDownEvent(event: KeyboardEvent): void {
        switch (event.code) {
            case EKeyCodes.ARROW_UP:
                this.publicationItemSelectionService.onBackClick();
                break;
            case EKeyCodes.ARROW_DOWN:
                this.publicationItemSelectionService.onNextClick();
                break;
        }
    }
}
