import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {SignupModel} from '../../models/api/signup.model';
import {TeamAccountSignupModel} from '../../models/api/team-account-signup.model';
import {TeamAccountSignupConfirmModel} from '../../models/ui/team-account-signup-confirm.model';
import {TeamModel} from '../../models/api/team.model';

@Injectable({
    providedIn: 'root'
})
export class TeamAccountService extends BaseApiRequestService {

    /**
     * Post a new signup for a team account
     * @param signup
     * @returns { Observable<boolean>}
     */

    public postTeamAccountSignup(signup: SignupModel): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEAM_ACCOUNTS, ApiConstants.API_METHOD_SIGNUP]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {
            firstName: signup.firstName,
            lastName: signup.lastName,
            country: signup.country,
            functionTitle: signup.job,
            email: signup.email,
            teamName: signup.teamName,
            key: signup.key
        };

        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Get team account signup model
     * @param {string} token
     * @returns {Observable<TeamAccountSignupModel>}
     */
    public getTeamAccountSignup(token: string): Observable<TeamAccountSignupModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEAM_ACCOUNTS, ApiConstants.API_METHOD_SIGNUP, token]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, TeamAccountSignupModel);
        });
    }

    /**
     * Get team account signup model
     * @param {TeamAccountSignupConfirmModel} signup
     * @returns {Observable<boolean>}
     */
    public postTeamAccount(signup: TeamAccountSignupConfirmModel): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH, ApiConstants.API_GROUP_TEAM_ACCOUNTS]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {
            token: signup.token,
            password: signup.password
        };
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Change team owner and assign new roles to the former owner
     * @param {string} newOwnerId
     * @param {string[]} newRoleIds
     * @returns {Observable<boolean>}
     */
    public transferTeamOwnership(newOwnerId: string, newRoleIds: string[]): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_TEAM_ACCOUNTS,
            ApiConstants.API_METHOD_TRANSFER_OWNERSHIP]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = {newOwnerId, newRoleIds};
        return new Observable((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Get team details
     * @returns {Observable<TeamModel>}
     */
    public getTeamDetails(): Observable<TeamModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_TEAM_ACCOUNTS
        ]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) =>
            this.handleDetailResponse(options, obs, TeamModel)
        );
    }
}
